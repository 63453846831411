import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import { classNames } from "../utils/classNames";

interface IModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  maxWidth?: string;
  children?: React.ReactNode;
  allowCloseOnBody?: boolean;
}

interface IModalHeader {
  children: React.ReactNode;
}

interface IModalBody {
  children: React.ReactNode;
}

interface IModalFooter {
  children: React.ReactNode;
}

const Header: React.FC<IModalHeader> = (props) => {
  return (
    <div className="px-12 py-6 border-b border-gray-300">{props.children}</div>
  );
};

const Body: React.FC<IModalBody> = (props) => {
  return <div className="mt-2 w-full px-8 py-6">{props.children}</div>;
};

const Footer: React.FC<IModalFooter> = (props) => {
  return (
    <div className="mt-4 bg-gray-150 w-full px-8 py-8 flex flex-wrap justify-between">
      {props.children}
    </div>
  );
};

const Modal: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  maxWidth,
  children,
  allowCloseOnBody,
}: IModalProps) => {
  let hiddenButtonRef = useRef(null);
  if (!maxWidth) maxWidth = "max-w-3xl";
  if (allowCloseOnBody === undefined) allowCloseOnBody = true;

  const closeModal = () => {
    if (!allowCloseOnBody) return;
    setIsOpen(false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto p-16"
          onClose={() => closeModal()}
          initialFocus={hiddenButtonRef}
        >
          <div className="h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-8 backdrop-filter backdrop-blur-md" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            {/*<span*/}
            {/*  className="inline-block h-screen align-middle"*/}
            {/*  aria-hidden="true"*/}
            {/*>*/}
            {/*  &#8203;*/}
            {/*</span>*/}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={classNames(
                  "inline-block my-8 mx-auto text-left align-middle transition-all transform bg-white rounded-6px shadow md:min-w-160",
                  maxWidth
                )}
              >
                {children}
                <button ref={hiddenButtonRef} className="hidden"></button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Object.assign(Modal, {
  Header,
  Body,
  Footer,
});
