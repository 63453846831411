import React, { createContext, useContext, useState } from "react";
import i18n, { loadLocales } from "../plugins/i18n";
import { OrganisationContext } from "./OrganisationProvider";

interface ILanguageContextProps {
  language: string;
  localeReady: boolean;
  loadLocaleResources: (language: string) => void;
  changeLanguage: (language: string) => void;
}

export const LanguageContext = createContext({} as ILanguageContextProps);

const LanguageProvider: React.FunctionComponent = (props) => {
  let [language, setLanguage] = useState<string>("");
  let [localeReady, setLocaleReady] = useState(false);

  const organisationContext = useContext(OrganisationContext);
  const organisationUserInfo = organisationContext.organisationUserInfo;

  const loadLocaleResources = async (language: string) => {
    loadLocales(language)
      .then(() => {
        setLocaleReady(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
    setLanguage(language);

    if (organisationUserInfo) {
      await organisationContext.updateUserLanguageSetting(
        organisationUserInfo.id,
        language
      );
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        localeReady,
        loadLocaleResources,
        changeLanguage,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
