import loginBackground from "../../assets/img/background-login.svg";
import logo from "../../assets/img/logo-harmony.png";
import React from "react";
import LanguageSwitcher from "../../components/LanguageSwitcher";

const LoginLayout: React.FC = (props) => {
  return (
    <div>
      <div className="flex flex-col justify-center min-h-screen bg-purple-800">
        <div className="absolute inset-0 overflow-hidden">
          <img
            className="object-cover w-full h-full transform scale-150"
            src={loginBackground}
            alt="background"
          />
        </div>

        <div className="z-10">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto w-auto h-8" src={logo} alt="Harmony" />
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="p-8 bg-white rounded-lg shadow">{props.children}</div>
          </div>
        </div>
      </div>

      <div className="absolute w-48 bottom-2 right-2">
        <LanguageSwitcher textColor="text-white"/>
      </div>
    </div>
  );
};

export default LoginLayout;
