import React, { useState, useReducer, useEffect } from "react";
import { CustomerDataOptions } from "./CustomerDetail";
import Modal from "../../components/Modal";
import Button, { Variant } from "../../components/Button";
import Input from "../../components/Form/Input";
import { addressService, middleware } from "../../plugins/axios";
import { toastError, toastSuccess } from "../../plugins/toast";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";
import Select from "../../components/Form/Select";
import ValidationError from "../../components/Form/ValidationError";
import { useValidationContext } from "../../providers/ValidationProvider";

interface CustomerPayload {
  id: string;
  email: string;
  streetName: string;
  houseNo: string;
  houseNoAddition: string;
  postCode: string;
  city: string;
  phoneNo: string;
  organisation: string;
  organisationEmail: string;
  birthDate: string;
  locale_code: string;
}

type CustomerModalProps = {
  isOpen: boolean;
  organisation: any;
  organisationEmail: string;
  setIsOpen: any;
  customer: CustomerDataOptions;
  loggedInUser: any;
};

interface CustomerModalStateType {
  customer: CustomerDataOptions | null;
  payload: CustomerPayload | null;
}

enum CustomerModalActionType {
  UPDATE_CUSTOMER,
  UPDATE_PAYLOAD,
}

interface CustomerModalAction {
  type: CustomerModalActionType;
  payload: any;
}

const languageOptions: Array<{ label: any; value: string | number }> = [
  {
    value: "nl_NL",
    label: "Nederlands (Nederland)",
  },
  {
    value: "nl_BE",
    label: "Nederlands (België)",
  },
  {
    value: "en_US",
    label: "English (United States)",
  },
  {
    value: "fr_BE",
    label: "Français (Belgique)",
  },
];

const getLocaleName = (locale: string) => {
  const localeName = languageOptions.find((option) => option.value === locale);
  return localeName ? localeName.label : locale;
};

function customerModalReducer(
  state: CustomerModalStateType,
  action: CustomerModalAction
) {
  const { type, payload } = action;

  switch (type) {
    case CustomerModalActionType.UPDATE_CUSTOMER:
      return { ...state, customer: payload };
    case CustomerModalActionType.UPDATE_PAYLOAD:
      return { ...state, payload };
  }
}

//export language options for use in other components
export { languageOptions, getLocaleName };

export const CustomerModal: React.FC<CustomerModalProps> = (props) => {
  const initialState = {
    customer: props.customer,
    payload: {
      id: props.customer.id,
      email: props.customer.email,
      birthDate: props.customer.birth_date,
      streetName: props.customer.streetName,
      houseNo: props.customer.houseNo,
      houseNoAddition: props.customer.houseNoAddition,
      postCode: props.customer.postCode,
      city: props.customer.city,
      phoneNo: props.customer.phoneNo,
      organisation: props.organisation?.name,
      organisationEmail: props.loggedInUser.email,
      locale_code: props.customer.locale_code,
    },
  };
  const [emailValidationError, setEmailValidationError] = useState("");
  const validationContext = useValidationContext();
  const [state, dispatch] = useReducer(customerModalReducer, initialState);
  const { payload } = state;
  const {
    email,
    birthDate,
    streetName,
    houseNo,
    houseNoAddition,
    postCode,
    city,
    phoneNo,
  } = payload;

  useEffect(() => {
    if (!props.isOpen) return;

    if (props.customer.locale_code === "nl_NL") {
      onAddressDetailsChange();
    }
  }, [houseNo, postCode]);

  const saveCustomer = () => {
    middleware
      .post(`customer/${props.customer.id}`, payload)
      .then(() => {
        toastSuccess(i18n.t("detail.customer_edit.success"));
        props.setIsOpen(false, true);
      })
      .catch((e) => {
        toastError(i18n.t("detail.customer_edit.error"), e.message)
      });
  };

  const updateCustomer = (e: any) => {
    const data = { ...payload };
    data[e.target.name] = e.target.value;
    data.organisation = props.organisation.name;
    data.organisationEmail = props.loggedInUser.email;

    dispatch({
      type: CustomerModalActionType.UPDATE_PAYLOAD,
      payload: data,
    });
  };

  const getCurrentLanguageOption = () => {
    return (
      languageOptions.find(
        (option) => option.value === state.payload.locale_code
      ) ?? { value: "nl", label: "Nederlands" }
    );
  };

  const onEmailChange = (e: any) => {
    updateCustomer(e);
    if (!validationContext.validateEmail(e.target.value)) {
      setEmailValidationError(i18n.t("login.errors.email_not_valid"));
    } else {
      setEmailValidationError("");
    }
  };

  const onAddressDetailsChange = () => {
    const requestPayload = {
      zipCode: postCode,
      houseNo: houseNo.toString(),
    };

    if (!validationContext.validateZipCode(postCode)) {
      // TODO: show validation error
      return;
    }
    if (!validationContext.validateHouseNumber(houseNo.toString())) {
      // TODO: show validation error
      return;
    }

    // TODO: use useQuery, so that loading state can be implemented
    addressService
      .post(`SearchAddress`, requestPayload)
      .then((res) => {
        if (
          res.data.street === streetName &&
          res.data.city === city
          // res.data.streetAddition === houseNoAddition
        ) {
          return;
        }

        dispatch({
          type: CustomerModalActionType.UPDATE_PAYLOAD,
          payload: {
            ...payload,
            streetName: res.data.street,
            // houseNoAddition: res.data.streetAddition,
            city: res.data.city,
          },
        });
      })
      .catch((e) => {
        console.log(e);

        dispatch({
          type: CustomerModalActionType.UPDATE_PAYLOAD,
          payload: {
            ...payload,
            streetName: "",
            // houseNoAddition: "",
            city: "",
          },
        });
      });
  };

  const canProceed = () => {
    return validationContext.validateEmail(email);
  };

  return (
    <>
      <Modal isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
        <Modal.Header>
          <div className="text-2xl text-gray-800 font-semibold">
            <Translate name={"detail.customer_edit.title"} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={() => {}}>
            <div className={"grid gap-y-3"}>
              <Input
                label=""
                translationKey="detail.customer_edit.email_label"
                value={email}
                name="email"
                onChange={onEmailChange}
              />
              {emailValidationError && (
                <ValidationError classList={"mt-1"}>
                  {emailValidationError}
                </ValidationError>
              )}
              <Input
                label=""
                translationKey="detail.customer_edit.zip_label"
                value={state.payload.postCode}
                name="postCode"
                onChange={(e: any) => {
                  updateCustomer(e);
                }}
              />
              <Input
                label=""
                translationKey="detail.customer_edit.house_no_label"
                value={state.payload.houseNo}
                name="houseNo"
                onChange={(e: any) => {
                  updateCustomer(e);
                }}
              />
              <Input
                label=""
                translationKey="detail.customer_edit.house_no_addition_label"
                value={state.payload.houseNoAddition}
                name="houseNoAddition"
                onChange={updateCustomer.bind(this)}
              />
              <Input
                label=""
                translationKey="detail.customer_edit.street_label"
                value={state.payload.streetName}
                name="streetName"
                onChange={updateCustomer.bind(this)}
                disabled={props.customer.locale_code === "nl_NL"}
              />
              <Input
                label=""
                translationKey="detail.customer_edit.city_label"
                value={state.payload.city}
                name="city"
                onChange={updateCustomer.bind(this)}
                disabled={props.customer.locale_code === "nl_NL"}
              />
              <Input
                label=""
                translationKey="detail.customer_edit.phone_label"
                value={state.payload.phoneNo}
                name="phoneNo"
                onChange={updateCustomer.bind(this)}
              />
              <Select
                id="locale_code"
                label="detail.customer_edit.language_label"
                translationKey="detail.customer_edit.language_label"
                name="locale_code"
                setSelected={(language: { value: string; label: string }) => {
                  updateCustomer({
                    target: { name: "locale_code", value: language.value },
                  });
                }}
                selected={getCurrentLanguageOption()}
                options={languageOptions}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              onClick={() => props.setIsOpen(false)}
              variant={Variant.TRANSPARENT}
            >
              <Translate name={"detail.common.back_to_detail"} />
            </Button>
          </div>
          <div>
            <Button disabled={!canProceed()} onClick={saveCustomer}>
              <Translate name={"detail.common.save"} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
