import React, { useContext, useState } from "react";
import { UserContext } from "../../providers/UserProvider";

const Link: React.FC = () => {
  const userContext = useContext(UserContext);
  let [invalidLogin, setInvalidLogin] = useState(false);

  React.useEffect(() => {
    const handleEffect = async () => {
      const isValid = await userContext.loginAndLinkWithOobCode(
        window.location.href
      );
      setInvalidLogin(!isValid);
    };
    handleEffect().then();
  }, []);

  return (
    <div>
      <div className="mb-8 text-3xl font-medium">Customer Service</div>
      <div className="mb-8">
        {!invalidLogin ? (
          <span>Processing your Azure account link, please wait...</span>
        ) : (
          <span>
            Something went wrong trying to log you in, please return to login.
          </span>
        )}
      </div>
    </div>
  );
};

export default Link;
