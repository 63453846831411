import React from "react";
import Translate from "./Translate";

interface IDataListProps {
  data: Array<any>;
  perRow?: number
}

const DataList: React.FC<IDataListProps> = ({data, perRow}) => {
  let chunkBy = perRow ? perRow : 2;

  data = data.map((e, i) => {
    return i % chunkBy === 0 ? data.slice(i, i + chunkBy) : null;
  }).filter(e => {
    return e;
  });

  return (
    <table className="w-full">
      <tbody className="bg-white divide-y divide-gray-300 border-t border-b border-gray-300">
      {
        data.map((groups, index) => {
              return (
                  <tr key={index}>
                    {
                      groups.map((item: any, index: number) => {
                        return (
                            <td key={index} className="pr-6 py-3">
                              <div className="flex flex-col justify-center">
                                {(item.label && item.label !== "") && <div className={`text-base font-bold pb-1 ${(!item.value) && item.required ? 'text-red-500' : ''}`}>
                                  <Translate name={item.label} />
                                </div>}

                                <div className="text-base font-base">
                                  {item.value}
                                </div>
                              </div>
                            </td>
                        )
                      })
                    }
                  </tr>
              )
            }
        )
      }
      </tbody>
    </table>
  )
}

export default DataList;
