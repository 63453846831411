import FileSaver from "file-saver"

export function createFile(base64Data: string) {
	let base64str = "";
	if (base64Data.includes(";base64,")) {
		base64str = base64Data.split(";base64,")[1];
	} else {
		base64str = base64Data;
	}
	let binary = window.atob(base64str.replace(/\s/g, ""));
	let len = binary.length;
	let buffer = new ArrayBuffer(len);
	let array = new Uint8Array(buffer);
	for (let i = 0; i < len; i++) {
		array[i] = binary.charCodeAt(i);
	}

	return new Blob([array], {
		type: "application/pdf"
	});
}

export function downloadFile(base64Data: string, filename: string) {
	let file = createFile(base64Data);

	let mobileSafariAgent =
		navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
		navigator.userAgent.match(/AppleWebKit/);

	if (mobileSafariAgent == null) {
		FileSaver.saveAs(file, filename);
	} else {
		let fileURL = URL.createObjectURL(file);
		let link = document.createElement("a");
		link.href = fileURL;
		link.download = filename;
		link.click();
	}
}

export function openFileInTab(base64Data: any) {
	let file = createFile(base64Data);
	let fileUrl = URL.createObjectURL(file);
	window.open(fileUrl);
}