import React from 'react';
import {classNames} from "../../utils/classNames";

interface ValidationErrorProps extends React.PropsWithChildren<any> {
    classList?: string;
}

const ValidationError: React.FC<ValidationErrorProps> = ({children, classList = ""}) => {
    return (
        <p className={classNames(
            "text-sm text-red-600",
            classList
        )}>{children}</p>
    );
};

export default ValidationError;
