import React, { useContext, useState } from "react";
import Heading, { Level } from "../../components/Typography/Heading";
import Button, { Variant } from "../../components/Button";
import Card from "../../components/Card";
import Input from "../../components/Form/Input";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import AppHeader from "../../components/Layout/AppHeader";
import { OrganisationContext } from "../../providers/OrganisationProvider";
import { middleware, organisationService } from "../../plugins/axios";
import { toastSuccess } from "../../plugins/toast";
import { NavLink } from "react-router-dom";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

const Team: React.FC = () => {
  const organisationContext = useContext(OrganisationContext);
  const organisationUsers = organisationContext.organisationUsers;
  const [newUserFirstName, setNewUserFirstName] = useState("");
  const [newUserLastName, setNewUserLastName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  // const [organisationUsers, setOrganisationUsers] = useState([]);
  const cells = [
    { label: "first_name" },
    { label: "last_name" },
    { label: "email" },
    { label: "role" },
  ];

  const submitNewUser = () => {
    const payload = {
      firebase_uid: '',
      first_name: newUserFirstName,
      last_name: newUserLastName,
      email: newUserEmail,
      role: "service_user",
      organisation: organisationContext.organisation
        ? organisationContext.organisation.id
        : "",
    };
    middleware.post("service/organisation_user/uid", payload).then((res) => {
      payload.firebase_uid = res.data['uid']
      organisationService.post("organisation_users", payload).then(() => {
        toastSuccess(i18n.t("team.add_user.success"));
        const organisationId = organisationContext.organisation
          ? organisationContext.organisation.id
          : "";
        organisationContext.getOrganisationUsers(organisationId);
      });
    });
  };

  return (
    <div>
      <AppHeader>
        <Heading tag="h1" level={Level.HEADING_2}>
          <Translate name={"team.header"} />
        </Heading>

        <div>
          <Button
            onClick={() =>
              setShowNewUserModal((showNewUserModal) => !showNewUserModal)
            }
          >
            <Translate name={"team.add_user_label"} />
          </Button>
        </div>
      </AppHeader>

      <div className="p-8">
        <Card>
          <div className="w-full">
            <Table data={[]}>
              <Table.Header>
                <Table.HeaderColumn
                  label="" translationKey="team.first_name"
                  sortable={true}
                />
                <Table.HeaderColumn
                  label="" translationKey="team.last_name"
                  sortable={true}
                />
                <Table.HeaderColumn
                  label="" translationKey="team.email"
                  sortable={true}
                />
                <Table.HeaderColumn
                  label="" translationKey="team.role"
                  sortable={true}
                />
                <Table.HeaderColumn
                  label="" translationKey="team.actions"
                  sortable={false}
                />
              </Table.Header>
              {organisationUsers ? (
                <Table.Body>
                  {organisationUsers.map((row: any, index: any) => {
                    return (
                      <Table.Row key={index} index={index}>
                        {cells.map((cell, i) => {
                          return <Table.Cell key={i} label={row[cell.label]} />;
                        })}

                        <NavLink to={`/team/${row["id"]}`}>
                          <Table.Cell label="" translationKey="team.edit" />
                        </NavLink>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              ) : null}
            </Table>
          </div>
        </Card>
      </div>

      <Modal isOpen={showNewUserModal} setIsOpen={setShowNewUserModal}>
        <Modal.Header>
          <h2><Translate name={"team.add_user.header"} /></h2>
        </Modal.Header>

        <Modal.Body>
          <div className="px-4">
            <h3 className="border-b border-gray-300 pb-4 mb-4">
              <Translate name={"team.add_user.help_text"} />
            </h3>

            <form>
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 md:col-span-6">
                  <Input
                    value={newUserFirstName}
                    onChange={(e) => setNewUserFirstName(e.target.value)}
                    label="" translationKey="team.add_user.first_name_label"
                    id="name"
                    name="name"
                    placeholder="John"
                  />
                </div>

                <div className="col-span-12 md:col-span-6">
                  <Input
                    value={newUserLastName}
                    onChange={(e) => setNewUserLastName(e.target.value)}
                    label="" translationKey="team.add_user.last_name_label"
                    id="lastName"
                    name="lastName"
                    placeholder="Doe"
                  />
                </div>

                <div className="col-span-12">
                  <Input
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    label="" translationKey="team.add_user.email_label"
                    id="email"
                    name="email"
                    placeholder="john.doe@harmony.nl"
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Button
              onClick={() =>
                setShowNewUserModal((showNewUserModal) => !showNewUserModal)
              }
              variant={Variant.LIGHT_PURPLE}
            >
              <Translate name={"team.add_user.close"} />
            </Button>
          </div>

          <div>
            <Button onClick={() => submitNewUser()}>
              <Translate name={"team.add_user.save"} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Team;
