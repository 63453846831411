import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import { UserContext } from "../../providers/UserProvider";
import { OrganisationContext } from "../../providers/OrganisationProvider";
import Translate from "../Translate";
import i18n from "../../plugins/i18n";

interface EnrollPhoneMFAProps {
  email: string;
  password: string;
}

export interface PhonePrefix {
  key: string;
  value: string;
}

const EnrollPhoneMFA: React.FC<EnrollPhoneMFAProps> = ({ email, password }) => {
  let [phoneNumber, setPhoneNumber] = useState("");
  let [verificationCode, setVerificationCode] = useState<string>("");
  let [recaptchaWidgetId, setRecaptchaWidgetId] = useState<number | null>(null);
  let [verificationId, setVerificationId] = useState<string>("");
  let [verificationNeeded, setVerificationNeeded] = useState(false);
  let [invalidVerification, setInvalidVerification] = useState(false);
  let [verificationError, setVerificationError] = useState<string>("");
  const [phoneValidationError, setPhoneValidationError] = useState<
    string | null
  >(null);
  const [recaptchaSolved, setRecaptchaSolved] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const [phonePrefix, setPhonePrefix] = useState<PhonePrefix | null>(null);

  const organisationContext = useContext(OrganisationContext);

  const phonePrefixes: PhonePrefix[] = [
    {
      key: "+31",
      value: "+31",
    },
    {
      key: "+32",
      value: "+32",
    },
  ];

  useEffect(() => {
    const org = organisationContext.organisation;

    if (!org) {
      return;
    }

    if (org.default_phone_prefix) {
      setPhonePrefix((f): PhonePrefix | null => {
        let phonePrefixObj = phonePrefixes.find(
          (phonePrefix) => phonePrefix.value === org.default_phone_prefix
        );
        return phonePrefixObj ? phonePrefixObj : f;
      });
    }
  }, [organisationContext.organisation]);

  useEffect(() => {
    if (verificationNeeded) {
      const verifier = new firebase.auth.RecaptchaVerifier(
        "multi-factor-button-2",
        {
          size: "invisible",
          callback: () => {
            setRecaptchaSolved(true);
          },
        }
      );

      verifier.render().then((widgetId) => {
        setRecaptchaWidgetId(widgetId);
      });

      if (!phonePrefix) {
        setPhonePrefix(phonePrefixes[0]);
      }

      let phone = `${phonePrefix?.value}${phoneNumber}`;

      userContext
        .getRegisterPhoneVerificationId(email, password, phone, verifier)
        .then((id) => {
          console.log(id);
          setVerificationId(id);
        });
    }
  }, [verificationNeeded]);

  useEffect(() => {
    if (!email || !password) {
      userContext.doLogout();
    }
  });

  useEffect(() => {
    setPhonePrefix(phonePrefixes[0])
  }, [])

  const handlePhoneSubmit = async () => {
    if (phoneNumber.startsWith("+")) {
      setPhoneValidationError(
        i18n.t("errors.invalid-phone-format")
      );
      return;
    }

    setPhoneValidationError(null);
    setVerificationNeeded(true);
  };

  const handleVerificationCodeSubmit = async () => {
    const cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    userContext.registerUser?.multiFactor
      .enroll(multiFactorAssertion, "Service portal login")
      .then(() => {
        if (userContext.registerUser) {
          userContext.forceLogin(userContext.registerUser);
        }
        setInvalidVerification(false);
        setVerificationError("");
      })
      .catch((error) => {
        console.log(error);
        setInvalidVerification(true);
        setVerificationError(error["message"]);
        window.grecaptcha.reset(recaptchaWidgetId);
      });
  };

  const setPhonePrefixFromChangeEvent = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let phonePrefixObj = phonePrefixes.find(
      (phonePrefix) => phonePrefix.value === e.target.value
    );

    if (phonePrefixObj) {
      setPhonePrefix(phonePrefixObj);
    } else {
      setPhoneValidationError(
        i18n.t("errors.invalid-phone-format")
      );
    }
  };

  return (
    <>
      <div className="mb-8">
        <Translate name={"login.set_phone_number"} />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block font-medium text-gray-700">
          <Translate name={"login.input.phone_number"} />
        </label>

        {/* Phone prefix */}
        <div className="mt-2 relative">
          <div className="absolute inset-y-0 left-0 flex items-center">
            <label htmlFor="currency" className="sr-only">
              <Translate name={"login.input.phone_prefix"} />
            </label>

            <select
              value={phonePrefix?.value}
              onChange={(e) => setPhonePrefixFromChangeEvent(e)}
              className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
            >
              {phonePrefixes.map((pre) => {
                return <option value={pre.value}>{pre.key}</option>;
              })}
            </select>
          </div>

          <input
            disabled={verificationNeeded}
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            id="phone_number"
            name="phone_number"
            required
            placeholder={i18n.t(
              "login.phone_number_placeholder"
            )}
            className="block py-3 pl-16 pr-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800"
          />
        </div>
      </div>

      {phoneValidationError && (
        <div className="text-red-500 mb-4">{phoneValidationError}</div>
      )}

      {!verificationNeeded && (
        <button
          onClick={handlePhoneSubmit}
          className="p-4 mb-4 w-full text-center text-white bg-purple-700 rounded cursor-pointer"
        >
          <Translate name={"login.button_send_verification"} />
        </button>
      )}

      {verificationNeeded && (
        <div className="mb-4">
          <label className="block font-medium text-gray-700">
            <Translate name={"login.input.verification_code"} />
          </label>
          <div className="mt-2">
            <input
              onChange={(e) => setVerificationCode(e.target.value)}
              value={verificationCode}
              id="verification_code"
              name="verification_code"
              required
              placeholder={i18n.t(
                "login.input.verification_code"
              )}
              className="block py-3 px-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800"
            />
          </div>
        </div>
      )}

      {invalidVerification && (
        <div className="text-red-500 mb-4">{verificationError}</div>
      )}

      {verificationNeeded && (
        <div>
          <button
            id="multi-factor-button-2"
            disabled={!recaptchaSolved}
            onClick={handleVerificationCodeSubmit}
            className="p-4 mb-4 w-full text-center text-white bg-purple-700 rounded cursor-pointer"
          >
            <Translate name={"login.button_finish_verification"} />
          </button>
        </div>
      )}
    </>
  );
};

export default EnrollPhoneMFA;
