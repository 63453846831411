import React, { useState } from "react";
import { ContractDataOptions } from "../CustomerDetail";
import DataList from "../../../components/DataList";
import Heading, { Level } from "../../../components/Typography/Heading";
import i18n from "../../../plugins/i18n";
import Button, { Variant } from "../../../components/Button";
import Select from "../../../components/Form/Select";

interface NewClaimModalPersonalInformationTabProps {
  contract: ContractDataOptions;
  setIsOpen: any;
  openContractEditModal: () => void;
  onProductChange: (product: string) => void;
}

const NewClaimModalPersonalInformationTab: React.FC<NewClaimModalPersonalInformationTabProps> =
  ({ contract, setIsOpen, openContractEditModal, onProductChange }) => {
    const [selectedProduct, setSelectedProduct] = useState<any>({
      label: "",
      value: "",
    });

    const data = [];

    if (contract.description !== contract.item_description) {
      data.push({
        label: "",
        value: contract.description,
      });
    }

    if (contract.iMEI) {
      data.push({
        label: "detail.contracts.imei",
        value: contract.iMEI,
      });
    }

    if (contract.serialNo) {
      data.push({
        label: "detail.contracts.imei",
        value: contract.serialNo,
      });
    }

    const products = contract.products.map((product, index) => {
      return {
        label: product.description,
        value: product.id,
        disabled: product.claim_exists,
      };
    });

    return (
      <div className="space-y-6">
        <div className="space-y-4">
          <Heading tag="h2" level={Level.HEADING_3}>
            {contract.contract_description}
          </Heading>
          {!contract.multidevice && (
            <Heading tag="h3" level={Level.HEADING_4}>
              {contract.item_description}
            </Heading>
          )}
          {!contract.multidevice ? (
            <DataList perRow={1} data={[...data]} />
          ) : (
            <Select
              id="product-select"
              options={products}
              selected={selectedProduct}
              setSelected={(productOption) => {
                setSelectedProduct(productOption);
                onProductChange(productOption.value);
              }}
            />
          )}
          {!contract.multidevice && (
            <div className="flex flex-row items-end">
              <Button
                variant={Variant.TRANSPARENT}
                onClick={() => {
                  setIsOpen(false);
                  setTimeout(() => {
                    openContractEditModal();
                  }, 500);
                }}
                classList="ml-auto"
              >
                {i18n.t("detail.contracts.edit")}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

export default NewClaimModalPersonalInformationTab;
