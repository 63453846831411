import React, { FormEvent, useState } from "react";
import firebase from "firebase";
import { Redirect } from "react-router-dom";
import { Trans } from "react-i18next";
import Checkbox from "../../components/Form/Checkbox";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

const SetPassword: React.FC = () => {
  let [passwordRepeat, setPasswordRepeat] = useState("");
  let [password, setPassword] = useState("");
  let [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState<boolean>(false);
  let [invalidPassword, setInvalidPassword] = useState(false);
  let [passwordDoesNotMatch, setPasswordDoesNotMatch] = useState(false);
  let [needAcceptTermsAndConditions, setNeedsAcceptTermsAndConditions] = useState(false);
  let [completed, setCompleted] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInvalidPassword(false);
    const query = new URLSearchParams(window.location.search);
    const code = query.get("oobCode") || "";

    // if(!acceptedTermsAndConditions) {
    //   setNeedsAcceptTermsAndConditions(true);
    //   return;
    // }

    if (password === passwordRepeat) {
      try {
        await firebase.auth().confirmPasswordReset(code, password);
        setCompleted(true);
      } catch (e) {
        setInvalidPassword(true);
      }
    } else {
      setPasswordDoesNotMatch(true);
    }
  };

  if (completed) return <Redirect to={"/login"} />;

  return (
    <div>
      <div className="mb-8 text-3xl font-medium">Customer Service</div>
      <div>
        <div className="mb-8">
          <Translate name={"login.set_password"} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block font-medium text-gray-700">
              <Translate name={"login.input.password"} />
            </label>
            <div className="mt-2">
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                id="password"
                name="password"
                type="password"
                required
                placeholder={i18n.t(
                  "login.input.password_placeholder"
                )}
                className="block py-3 px-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800"
              />
            </div>
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block font-medium text-gray-700">
              <Translate name={"login.input.password_repeat"} />
            </label>
            {passwordDoesNotMatch && (
              <div className="text-red-500 mb-4">
                <Translate name={"login.password_no_match"} />
              </div>
            )}
            <div className="mt-2">
              <input
                onChange={(e) => setPasswordRepeat(e.target.value)}
                value={passwordRepeat}
                id="repeat_password"
                name="repeat_password"
                type="password"
                required
                placeholder={i18n.t(
                  "login.input.password_repeat_placeholder"
                )}
                className="block py-3 px-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800"
              />
            </div>
          </div>
        {/*  <div className="mt-6">*/}
        {/*  <label htmlFor="terms-and-conditions" className="block font-medium text-gray-700">*/}
        {/*    <Trans */}
        {/*      i18nKey="login.terms_and_conditions"*/}
        {/*      components={[*/}
        {/*        <a */}
        {/*          target="_blank" */}
        {/*          href="" translationKey='login.terms_and_conditions_href'*/}
        {/*          className="text-purple-700 underline">*/}
        {/*          Terms & Conditions*/}
        {/*        </a>*/}
        {/*      ]}*/}
        {/*    >*/}
        {/*      /!* Translation format: 'text <0>url text</0>' *!/*/}
        {/*      /!* Can add a default in here by uncommenting the next line *!/*/}
        {/*      /!* {"This is a default <0>terms and conditions</0> placeholder"} *!/*/}
        {/*    </Trans>*/}
        {/*  </label>*/}
        {/*  <div className="mt-2 my-4">*/}
        {/*    <Checkbox */}
        {/*      label="" translationKey='login.input.agree_terms_and_conditions'*/}
        {/*      onChange={(e) => {*/}
        {/*        setNeedsAcceptTermsAndConditions(!e.target.checked)*/}
        {/*        setAcceptedTermsAndConditions(e.target.checked)*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*  {needAcceptTermsAndConditions &&*/}
        {/*    <div className="text-red-500 mb-4">*/}
        {/*      <Translate name={"login.terms-and-conditions-not-accepted"} />*/}
        {/*    </div>*/}
        {/*  }*/}
          {invalidPassword && (
            <div className="text-red-500 mb-4">
              <Translate name={"login.password_not_valid"} />
            </div>
          )}
          <button
            type="submit"
            className="p-4 mb-4 w-full text-center text-white bg-purple-700 rounded cursor-pointer"
          >
            <Translate name={"login.button_save_password"} />
          </button>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
