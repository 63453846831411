import React from "react";
import { CustomerDataOptions } from "./CustomerDetail";
import Modal from "../../components/Modal";
import Button, { Variant } from "../../components/Button";
import { middleware } from "../../plugins/axios";
import { toastError, toastSuccess } from "../../plugins/toast";
import Checkbox from "../../components/Form/Checkbox";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

interface CommunicationPayload {
  noNewsletter: boolean;
  rightToObject: boolean;
}

type CommunicationModalProps = {
  isOpen: boolean;
  organisation: any;
  organisationEmail: string;
  setIsOpen: any;
  customer: CustomerDataOptions;
  loggedInUser: any;
};

type CommunicationModalState = {
  customer: CustomerDataOptions;
  payload: CommunicationPayload;
};

export class CommunicationModal extends React.Component<
  CommunicationModalProps,
  CommunicationModalState
> {
  state = {
    customer: this.props.customer,
    payload: {
      noNewsletter: this.props.customer.noNewsletter,
      rightToObject: this.props.customer.rightToObject,
    },
  };

  saveCustomer() {
    const payload = {
      no_newsletter: this.state.payload.noNewsletter,
      right_to_object: this.state.payload.rightToObject,
    };
    middleware
      .post(`customer/service/${this.props.customer.id}/communication`, payload)
      .then(() => {
        toastSuccess(i18n.t("detail.customer_edit.success"));
        this.props.setIsOpen(false, true);
      })
      .catch((e) =>
        toastError(i18n.t("detail.customer_edit.error"), e.message)
      );
  }

  updateCustomer(e: any) {
    var data: any = { ...this.state.payload };

    // does not work for text fields or anything other then checkbox
    data[e.target.name] = e.target.checked;

    this.setState({
      payload: data,
    });
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} setIsOpen={this.props.setIsOpen}>
        <Modal.Header>
          <div className="text-2xl text-gray-800 font-semibold">
            <Translate name={"detail.customer_edit.title"} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={() => {}}>
            <div className="mb-2">
              <Checkbox
                name="noNewsletter"
                label=""
                translationKey="detail.communication.receive_newsletter_label"
                onChange={this.updateCustomer.bind(this)}
                checked={this.state.payload.noNewsletter}
              />
            </div>
            <div>
              <Checkbox
                name="rightToObject"
                label=""
                translationKey="detail.communication.right_to_object_label"
                onChange={this.updateCustomer.bind(this)}
                checked={this.state.payload.rightToObject}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              onClick={() => this.props.setIsOpen(false)}
              variant={Variant.TRANSPARENT}
            >
              <Translate name={"detail.common.back_to_detail"} />
            </Button>
          </div>
          <div>
            <Button onClick={this.saveCustomer.bind(this)}>
              <Translate name={"detail.common.save"} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
