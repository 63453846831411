import React from "react";
import Modal from "../../components/Modal";
import Translate from "../../components/Translate";
import {ContractDataOptions, ContractProductOptions, CustomerDataOptions} from "./CustomerDetail";
import Button, {Variant} from "../../components/Button";
import ContractBanner from "../../components/ContractBanner";
import {middleware} from "../../plugins/axios";
import {toastError, toastSuccess} from "../../plugins/toast";

interface ContractDeleteProductModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  contract: ContractDataOptions
  product: ContractProductOptions
  customer: CustomerDataOptions
}


const ContractDeleteProductModal: React.FC<ContractDeleteProductModalProps> = (props) => {

  const sendCloseContractRequest = () => {

    const data = {
      "contractProductID": props.product.ext_contract_product_id,
      "lineNo": props.product.ext_line_number,
    }

    middleware
      .post(
        `contracts/service/delete_product/${props.customer.id}/${props.contract.contract_id}`,
        {data: data}
      )
      .then((res) => {
        if (res.status === 200) {
          toastSuccess("Product has been removed from the contract");
          props.setIsOpen(false)
        }
      })
      .catch((e) => toastError("Something went wrong", e.message));


  }

  return (
    <Modal isOpen={props.isOpen} setIsOpen={() => props.setIsOpen(false)}>
      <Modal.Header>
        <div className="text-2xl text-gray-800 font-semibold">
          <Translate name={"detail.delete_product.title"}/>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-8">
        <Translate name={"detail.delete_product.body"}/>
        </p>
        <ContractBanner itemDescription={props.product.description} contractDescription={props.contract.contract_no} category={props.product.category_code} />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            onClick={() => props.setIsOpen(false)}
            variant={Variant.TRANSPARENT}
          >
            <Translate name={"detail.common.back_to_detail"}/>
          </Button>
        </div>
        <div>
            <Button
              onClick={() => sendCloseContractRequest()}
            >
              <Translate name={"detail.delete_product.confirm"}/>
            </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ContractDeleteProductModal;
