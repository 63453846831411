import React from "react";
import { PaymentDataOptions } from "./CustomerDetail";
import Modal from "../../components/Modal";
import Input from "../../components/Form/Input";
import Button, { Variant } from "../../components/Button";
import { middleware } from "../../plugins/axios";
import { toastSuccess, toastError } from "../../plugins/toast";
import Select from "../../components/Form/Select";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

interface updateIbanRequest {
  iban: string;
  organisation: string;
  organisationEmail: string;
  contractNo: string;
}

interface SelectedContract {
  label: string;
  value: string;
}

type PaymentModalProps = {
  paymentData: PaymentDataOptions;
  isOpen: boolean;
  setIsOpen: any;
  organisation: any;
  organisationEmail: string;
  customerId: string;
  contracts: any;
  loggedInUser: any;
};

type PaymentModalState = {
  paymentData: PaymentDataOptions;
  selectedContract: SelectedContract | null;
};

export class PaymentModal extends React.Component<
  PaymentModalProps,
  PaymentModalState
> {
  state = {
    paymentData: this.props.paymentData,
    selectedContract: {
      label: i18n.t("detail.payment_edit.all_contracts"),
      value: "all",
    },
  };

  updatePaymentData(label: string, value: string) {
    var paymentDataCopy: PaymentDataOptions = { ...this.state.paymentData };
    var i = this.state.paymentData.data.findIndex((e) => e.label === label);
    if (i && i > -1) {
      paymentDataCopy.data[i].value = value;
    }
    this.setState((_previousState, _props) => ({
      paymentData: paymentDataCopy,
    }));
  }

  savePaymentData() {
    const payloads: Array<updateIbanRequest> = [];
    const requests: Array<any> = [];

    const ibanIndex: number = this.state.paymentData.data.findIndex(
      (e) => e.key === "iban"
    );

    if (this.state.selectedContract.value === "all") {
      this.props.contracts.forEach((contract: any) => {
        payloads.push({
          organisation: this.props.organisation.name,
          organisationEmail: this.props.loggedInUser.email,
          iban: this.state.paymentData.data[ibanIndex].value,
          contractNo: contract.contract_no,
        });
      });
    } else {
      payloads.push({
        organisation: this.props.organisation.name,
        organisationEmail: this.props.loggedInUser.email,
        iban: this.state.paymentData.data[ibanIndex].value,
        contractNo: this.state.selectedContract.value,
      });
    }

    if (ibanIndex === -1) return;

    payloads.forEach((payload) => {
      requests.push(
        middleware.post(
          `customer/service/ibans/${this.props.customerId}`,
          payload
        )
      );
    });

    Promise.all(requests)
      .then((res) => {
        this.props.setIsOpen(false, this.state.selectedContract, true);
        toastSuccess(i18n.t("detail.payment_edit.success"));
      })
      .catch((e) => {
        toastError(i18n.t("detail.payment_edit.error"), e.message);
      });
  }

  setContractNO(key: string, value: string) {
    this.setState((_previousState, _props) => ({
      selectedContract: {
        label: key,
        value: value,
      },
    }));
  }

  render() {
    const selectOptions = [];
    selectOptions.push({
      label: i18n.t("detail.payment_edit.all_contracts"),
      value: "all",
    });

    this.props.contracts.forEach((contract: any) => {
      selectOptions.push({
        label: contract.contract_no,
        value: contract.contract_no,
      });
    });

    return (
      <Modal isOpen={this.props.isOpen} setIsOpen={this.props.setIsOpen}>
        <Modal.Header>
          <div className="text-2xl text-gray-800 font-semibold">
            <Translate name={"detail.payment_edit.title"} />
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-4">
            <Select
              placeholder=""
              selected={this.state.selectedContract}
              setSelected={(e) => this.setContractNO(e.label, e.value)}
              loading={false}
              disabled={false}
              id="contractNO"
              name="contractNO"
              options={selectOptions}
              label="" translationKey="detail.payment_edit.contract_label"
            />
          </div>

          {(this.state.paymentData as PaymentDataOptions).data
            .filter((e) => e.key.toLowerCase().startsWith("iban"))
            .map((option, index) => (
              <div key={index} className={"mb-3"}>
                <Input
                  label={i18n.t(
                    `detail.payment_edit.${option.key
                      .toLowerCase()
                      .replace(" ", "_")}_label`
                  )}
                  onChange={(e) =>
                    this.updatePaymentData(option.label, e.target.value)
                  }
                  value={option.value}
                />
              </div>
            ))}
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Button
              onClick={() => this.props.setIsOpen(false)}
              variant={Variant.TRANSPARENT}
            >
              <Translate name={"detail.common.back_to_detail"} />
            </Button>
          </div>
          <div>
            <Button onClick={this.savePaymentData.bind(this)}>
              <Translate name={"detail.common.save"} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
