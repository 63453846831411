import React from "react";
import i18n from "../plugins/i18n";
import DOMPurify from "dompurify";

interface IHtmlBlockProps {
  html: string,
}

const HtmlBlock: React.FC<IHtmlBlockProps> = ({html}, ...rest) => {

  const sanitizeConfig = {
    ALLOWED_TAGS: [
      'a',
      'b', 
      'q', 
      'span', 
      'strong',
      'br'
    ], 
    ALLOWED_ATTR: [
      'style',
      'target',
      'href'
    ]}

  return (
    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(html, sanitizeConfig)}} />
  )
}

export default HtmlBlock;