import React, { FormEvent, useState } from "react";
import { middleware } from "../../plugins/axios";
import { toastSuccess } from "../../plugins/toast";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

const ResetPassword: React.FC = () => {
  let [username, setUsername] = useState("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = {
      email: username,
    };
    middleware.post("service/reset_password", payload).then(() => {
      toastSuccess("An email with instructions is send to you!");
    });
  };

  return (
    <div>
      <div className="mb-8 text-3xl font-medium"><Translate name={"login.title"} /></div>
      <div className="mb-8">
        <Translate name={"login.forgot_password"} />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block font-medium text-gray-700">
            <Translate name={"login.input.email"} />
          </label>
          <div className="mt-2">
            <input
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              placeholder={i18n.t("login.input.email_placeholder")}
              className="block py-3 px-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800"
            />
          </div>
        </div>
        <button
          type="submit"
          className="p-4 mb-4 w-full text-center text-white bg-purple-700 rounded cursor-pointer"
        >
          <Translate name={"login.button_reset_password"} />
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
