import React from "react";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";
import Button, {Variant} from "../../components/Button";
import Select from "../../components/Form/Select";
import {middleware} from "../../plugins/axios";
import {ContractDataOptions} from "./CustomerDetail";
import ContractBanner from "../../components/ContractBanner";
import DataList from "../../components/DataList";
import Checkbox from "../../components/Form/Checkbox";
import {toastError, toastSuccess} from "../../plugins/toast";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

interface ContractRetainRequestData {
  requestVia: string;
  reasonCode: string;
  organisation: string;
  organisationEmail: string;
}

type ContractRetainModalProps = {
  isOpen: boolean;
  setIsOpen: any;
  organisationEmail: string;
  organisation: any;
  contractToRetain: ContractDataOptions;
  customerId: string;
};

type ContractRetainModalState = {
  step: Number;
  lastStep: Number;
  description: string;
  loadingReasonCodes: boolean;
  reasonCodesEnabled: boolean;
  loadingCloseCodes: boolean;
  closeCodesEnabled: boolean;
  selectedReasonCode: {
    label: string;
    value: any;
  };
  reasonCodeOptions: Array<{
    value: any;
    label: string;
  }>;
  selectedCloseCode: {
    label: string;
    value: any;
  };
  closeCodeOptions: Array<{
    value: any;
    label: string;
  }>;
  selectedRequestVia: {
    label: string;
    value: any;
  };
  requestViaOptions: Array<{
    value: any;
    label: string;
  }>;
};

const Step0 = ({contract}: any) => (
  <div>
    <div className="text-lg py-3">
      <Translate name={"detail.retain.step_0.title"} />
    </div>
    <ContractBanner
      category={contract.category}
      itemDescription={contract.item_description}
      contractDescription={contract.contract_description}
    />
    <div className="block w-100 h-8"/>
    <Alert
      heading=""
      translationKey="detail.retain.step_0.alert_heading"
    >
      <p><Translate name={"detail.retain.step_0.alert_message"} /></p>
    </Alert>
  </div>
);

const Step1 = (
  {
    selectedReasonCode,
    setSelectedReasonCode,
    reasonCodeOptions,
    contract,
    loadingReasonCodes,
    reasonCodesEnabled,
    requestViaOptions,
    setSelectedRequestVia,
    selectedRequestVia,
  }: any
) => (
  <div>
    <ContractBanner
      category={contract.category}
      itemDescription={contract.item_description}
      contractDescription={contract.contract_description}
    />
    <div className="text-purple-700 text-lg mt-8 mb-3">
      <Translate name={"detail.retain.step_1.title"} />
    </div>
    <hr className="mb-3 border-gray-400"/>
    <div className="space-y-6">
      <Select
        label=""
        translationKey="detail.retain.step_1.reason_code_label"
        selected={selectedReasonCode}
        setSelected={setSelectedReasonCode}
        disabled={!reasonCodesEnabled}
        loading={loadingReasonCodes}
        id="reasonCode"
        name="reasonCode"
        options={reasonCodeOptions}
      />
      <Select
        label=""
        translationKey="detail.retain.step_1.closed_via_label"
        selected={selectedRequestVia}
        setSelected={setSelectedRequestVia}
        disabled={!reasonCodesEnabled}
        loading={loadingReasonCodes}
        id="requestVia"
        name="requestVia"
        options={requestViaOptions}
      />
    </div>
  </div>
);

const Step2 = ({details, contract, stepBack}: any) => (
  <div>
    <ContractBanner
      category={contract.category}
      itemDescription={contract.item_description}
      contractDescription={contract.contract_description}
    />
    <div className="flex justify-between mt-8 mb-3">
      <div className="text-purple-700 text-lg ">
        <Translate name={"detail.retain.step_2.title"} />
      </div>
      <div
        className="text-sm text-right text-purple-700 underline cursor-pointer"
        onClick={stepBack}
      >
        <Translate name={"detail.retain.step_2.step_back"} />
      </div>
    </div>
    <hr className="my-5 border-gray-400"/>
    <DataList perRow={1} data={details}/>
    <div className="block w-100 h-8"/>
    <Checkbox
      label=""
      translationKey="detail.retain.step_1.customer_confirmation_label"
    />
  </div>
);

export class ContractRetainModal extends React.Component<ContractRetainModalProps,
  ContractRetainModalState> {
  state = {
    step: 0,
    lastStep: 2,
    description: "",
    selectedReasonCode: {
      value: "default",
      label: "",
      description: "",
    },
    selectedCloseCode: {
      value: "default",
      label: "",
    },
    reasonCodeOptions: [],
    closeCodeOptions: [],
    requestViaOptions: [
      {
        value: "3",
        label: i18n.t(`detail.common.request_via_code.phone`),
      },
      {
        value: "1",
        label: i18n.t(`detail.common.request_via_code.email`),
      },
      {
        value: "2",
        label: i18n.t(`detail.common.request_via_code.letter`),
      },
    ],
    loadingReasonCodes: true,
    reasonCodesEnabled: false,
    loadingCloseCodes: true,
    closeCodesEnabled: false,
    selectedRequestVia: {
      value: "default",
      label: "",
    },
  };

  async componentDidMount() {
    this.setState({
      loadingReasonCodes: true,
      reasonCodesEnabled: false,
    });
    middleware
      .get(`contracts/close_reason/${this.props.customerId}/PER%20VERVALDATUM`)
      .then((res) => {
        if (res.data) {
          const reasonCodes = res.data.value.map((el: any) => {
            return {
              label: i18n.t(
                `service.detail.common.reason_codes.${el.reasonCode
                  .toLowerCase()
                  .replace(" ", "_")}`
              ),
              value: el.reasonCode,
              description: el.description,
            };
          });
          this.setState({
            reasonCodeOptions: reasonCodes,
            reasonCodesEnabled: true,
            loadingReasonCodes: false,
          });
        }
      });
    // middleware
    //   .get(
    //     `contracts/service/${this.props.customerId}/${this.props.contractToRetain.contract_no}/close_codes/`
    //   )
    //   .then((res) => {
    //     if (res.data) {
    //       const closeCodes = res.data.map((el: any) => {
    //         return {
    //           value: el.closeCode,
    //           label: i18n.t(
    //             `service.detail.common.close_codes.${el.closeCode
    //               .toLowerCase()
    //               .replace(" ", "_")}`
    //           ),
    //         };
    //       });
    //       this.setState({
    //         closeCodeOptions: closeCodes,
    //         closeCodesEnabled: true,
    //         loadingCloseCodes: false,
    //       });
    //     }
    //   });
  }

  getClosingReasons() {

  }

  nextStep() {
    this.setState({
      step: this.state.step + 1,
    });
  }

  changeReasonCode(e: any) {
    this.setState({
      selectedReasonCode: e,
    });
  }

  changeRequestVia(e: any) {
    this.setState({
      selectedRequestVia: e,
    });
  }

  changeDescription(e: any) {
    this.setState({
      description: e.target.value,
    });
  }

  setPreviousStep() {
    if (this.state.step <= 0) return;
    this.setState({
      step: this.state.step - 1,
    });
  }

  closeModal() {
    console.log(this)
    console.log(this.props)
    this.setState({
      step: 0,
    });
    this.props.setIsOpen(false);
  }

  sendCloseContractRequest() {
    const contractId: string = this.props.contractToRetain.contract_id;
    const data: ContractRetainRequestData = {
      reasonCode: this.state.selectedReasonCode.value,
      requestVia: this.state.selectedRequestVia.value,
      organisation: this.props.organisation.name,
      organisationEmail: this.props.organisationEmail,
    };
    middleware
      .post(
        `contracts/service/retain/${this.props.customerId}/${contractId}`,
        data
      )
      .then(() => {
        toastSuccess(i18n.t("detail.retain.submit_success"));
        this.closeModal();
      })
      .catch((e) => {
        toastError(i18n.t("detail.retain.submit_error"), e.message);
        console.error(e);
      });
  }

  getDatalist() {
    return [
      {
        label: "detail.retain.contract_description",
        value: this.props.contractToRetain.contract_description,
      },
      {
        label: "detail.retain.item_description",
        value: this.props.contractToRetain.item_description,
      },
      {
        label: "detail.retain.reason_code",
        value: this.state.selectedReasonCode.label,
      },
    ];
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} setIsOpen={() => this.closeModal()}>
        <Modal.Header>
          <div className="text-2xl text-gray-800 font-semibold">
            <Translate name={"detail.retain.title"} />
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.step === 0 ? (
            <Step0 contract={this.props.contractToRetain}/>
          ) : null}
          {this.state.step === 1 ? (
            <Step1
              contract={this.props.contractToRetain}
              reasonCodeOptions={this.state.reasonCodeOptions}
              reasonCodesEnabled={this.state.reasonCodesEnabled}
              reasonCodesLoading={this.state.loadingReasonCodes}
              selectedReasonCode={this.state.selectedReasonCode}
              setSelectedReasonCode={(e: any) => this.changeReasonCode(e)}
              setSelectedRequestVia={(e: any) => this.changeRequestVia(e)}
              selectedRequestVia={this.state.selectedRequestVia}
              closeCodeOptions={this.state.closeCodeOptions}
              requestViaOptions={this.state.requestViaOptions}
              closeCodesEnabled={this.state.closeCodesEnabled}
              closeCodesLoading={this.state.loadingCloseCodes}
              selectedCloseCode={this.state.selectedCloseCode}
              description={this.state.description}
              setDescription={(e: any) => this.changeDescription(e)}
            />
          ) : null}
          {this.state.step === 2 ? (
            <Step2
              contract={this.props.contractToRetain}
              details={this.getDatalist()}
              stepBack={() => this.setPreviousStep()}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              onClick={() => this.closeModal()}
              variant={Variant.TRANSPARENT}
            >
              <Translate name={"detail.common.back_to_detail"} />
            </Button>
          </div>
          {this.state.step > 0 ? (
            <div>
              <Button
                onClick={() => this.setPreviousStep()}
                variant={Variant.LIGHT_PURPLE}
              >
                <Translate name={"detail.common.previous"} />
              </Button>
            </div>
          ) : null}
          <div>
            {this.state.step < this.state.lastStep ? (
              <Button onClick={() => this.nextStep()}>
                <Translate name={"detail.common.next"} />
              </Button>
            ) : (
              <Button onClick={() => this.sendCloseContractRequest()}>
                <Translate name={"detail.retain.complete"} />
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
