import React from "react";
import Translate from "../Translate";
import Label from "./Label";
import {classNames} from "../../utils/classNames";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  translationKey?: string;
  error?: boolean;
  disabled?: boolean
}

const Input: React.FC<TextInputProps> = ({ label, translationKey, id, error = false, disabled=false, ...rest }) => {
  return (
    <div>
      <Label id={id}>
        {!translationKey ? label : <Translate name={translationKey} />}
      </Label>
      <div className="mt-2">
        <input
          id={id}
          disabled={disabled}
          {...rest}
          className={classNames(
            `block py-3 px-4 w-full rounded-3px border appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 placeholder-gray-700 placeholder-opacity-40`,
            error ? "border-red-800" : "border-purple-800",
            disabled ? "bg-gray-200" : ""
          )}
        />
      </div>
    </div>
  );
};

export default Input;
