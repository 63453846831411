import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LanguageProvider from "./providers/LanguageProvider";
import UserProvider from "./providers/UserProvider";
import OrganisationProvider from "./providers/OrganisationProvider";
import ValidationProvider from "./providers/ValidationProvider";

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <OrganisationProvider>
        <ValidationProvider>
          <LanguageProvider>
            <App/>
          </LanguageProvider>
        </ValidationProvider>
      </OrganisationProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
