import React from "react";
import {classNames} from "../../utils/classNames";

export enum Level {
  HEADING_1,
  HEADING_2,
  HEADING_3,
  HEADING_4,
  HEADING_5,
  HEADING_6
}

interface IHeadingProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  tag?: keyof JSX.IntrinsicElements;
  level?: Level;
  children?: React.ReactNode;
  color?: string;
  classList?: string
}

const HEADING_TYPE_MAPS: Record<Level, string> = {
  [Level.HEADING_1]: "text-2.5xl font-bold",
  [Level.HEADING_2]: "text-2xl font-bold",
  [Level.HEADING_3]: "text-xl font-medium",
  [Level.HEADING_4]: "text-lg font-medium",
  [Level.HEADING_5]: "text-base font-medium",
  [Level.HEADING_6]: "text-sm font-medium",
};


const Heading: React.FC<IHeadingProps> = ({level, children, tag, color, classList}) => {
  if (!level) level = Level.HEADING_1;
  if (!color) color = "text-gray-800"

  return (
    <span className={
      classNames(
        HEADING_TYPE_MAPS[level],
        classList,
        color
      )
    }>
      {children}
    </span>
  )
}

export default Heading;

