import React from "react";
import {classNames} from "../utils/classNames";

interface ICardHeaderProps {
  children?: React.ReactNode;
  cols?: string;
  colSpan?: string;
}

interface ICardProps {
  children?: React.ReactNode;
  classList?: string,
  cols?: string;
  colSpan?: string;
}

const Header: React.FC<ICardHeaderProps> = ({children, cols, colSpan}) => {
  return (
    <div className={
      classNames(
        'pb-4 grid',
        cols ? cols : 'grid-cols-2',
        colSpan ? colSpan : 'col-span-full'
      )
    }>
      {children}
    </div>
  )
}

const Card: React.FC<ICardProps> = ({children, classList, cols, colSpan}) => {
  return (
    <div className={
      classNames(
        'p-8 rounded-md border shadow-lg border-gray-200 grid auto-rows-min',
        classList,
        cols ? cols : 'grid-cols-1',
        colSpan ? colSpan : 'col-span-full'
      )
    }
    >
      {children}
    </div>
  );
};

export default Object.assign(Card, {
  Header
});
