import React, {createContext, useContext} from 'react';

interface ValidationContextProps {
  validateEmail(email: string): boolean;
  validatePassword(password: string): boolean;
  validateZipCode(zip: string): boolean;
  validateHouseNumber(houseNumber: string): boolean;
  validateNotEmptyString(str: string): boolean;
  validateNotSame(str1: string, str2: string): boolean;
}

const ValidationContext = createContext({} as ValidationContextProps);
export const useValidationContext = () => useContext(ValidationContext);

interface ValidationProviderProps extends React.PropsWithChildren<any> {};
const ValidationProvider: React.FC<ValidationProviderProps> = ({children}) => {
  const ZIP_CODE_LENGTH = 6;
  
  const validateEmail = (email: string):boolean => {
    const regex = /[a-zA-Z0-9!#$%&''*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&''*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
    return regex.test(email);
  }
  const validatePassword = (password: string):boolean => {
    // TODO: implement password validation
    return true;
  }
  const validateNotEmptyString = (str: string) => {
    return str.replace(/ /g, '').length > 0;
  };
  const validateZipCode = (zip: string) => {
    return zip.replace(/\s/g,'').length === ZIP_CODE_LENGTH;
  };
  const validateHouseNumber = (houseNumber: string) => {
    return validateNotEmptyString(houseNumber);
  };
  const validateNotSame = (str1: string, str2: string) => {
    return str1 != str2
  }

  return (
    <>
      <ValidationContext.Provider value={{
        validateEmail,
        validatePassword,
        validateZipCode,
        validateHouseNumber,
        validateNotEmptyString,
        validateNotSame
      }}>
        {children}
      </ValidationContext.Provider>
    </>
  );
};

export default ValidationProvider;