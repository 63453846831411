/* tslint:disable */
/* eslint-disable */
/**
 * Harmony Contract Service
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Account } from '../models';
import { ContractRequest } from '../models';
import { Item } from '../models';
import { ItemCategory } from '../models';
import { Manufacturer } from '../models';
import { Template } from '../models';
import {ContractResponse} from "../models/contract-response";
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {Account} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (body: Account, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAccount.');
            }
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ContractRequest} body Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract: async (body: ContractRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createContract.');
            }
            const localVarPath = `/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAccount.');
            }
            const localVarPath = `/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemCategories: async (template: string, contractAmount: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'template' is not null or undefined
            if (template === null || template === undefined) {
                throw new RequiredError('template','Required parameter template was null or undefined when calling getItemCategories.');
            }
            // verify required parameter 'contractAmount' is not null or undefined
            if (contractAmount === null || contractAmount === undefined) {
                throw new RequiredError('contractAmount','Required parameter contractAmount was null or undefined when calling getItemCategories.');
            }
            const localVarPath = `/item-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (template !== undefined) {
                localVarQueryParameter['template'] = template;
            }

            if (contractAmount !== undefined) {
                localVarQueryParameter['contract_amount'] = contractAmount;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} itemCategory
         * @param {string} manufacturer
         * @param {string} [search]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems: async (template: string, contractAmount: number, itemCategory: string, manufacturer: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'template' is not null or undefined
            if (template === null || template === undefined) {
                throw new RequiredError('template','Required parameter template was null or undefined when calling getItems.');
            }
            // verify required parameter 'contractAmount' is not null or undefined
            if (contractAmount === null || contractAmount === undefined) {
                throw new RequiredError('contractAmount','Required parameter contractAmount was null or undefined when calling getItems.');
            }
            // verify required parameter 'itemCategory' is not null or undefined
            if (itemCategory === null || itemCategory === undefined) {
                throw new RequiredError('itemCategory','Required parameter itemCategory was null or undefined when calling getItems.');
            }
            // verify required parameter 'manufacturer' is not null or undefined
            if (manufacturer === null || manufacturer === undefined) {
                throw new RequiredError('manufacturer','Required parameter manufacturer was null or undefined when calling getItems.');
            }
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (template !== undefined) {
                localVarQueryParameter['template'] = template;
            }

            if (contractAmount !== undefined) {
                localVarQueryParameter['contract_amount'] = contractAmount;
            }

            if (itemCategory !== undefined) {
                localVarQueryParameter['itemCategory'] = itemCategory;
            }

            if (manufacturer !== undefined) {
                localVarQueryParameter['manufacturer'] = manufacturer;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} itemCategory
         * @param {string} [search]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturers: async (template: string, contractAmount: number, itemCategory: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'template' is not null or undefined
            if (template === null || template === undefined) {
                throw new RequiredError('template','Required parameter template was null or undefined when calling getManufacturers.');
            }
            // verify required parameter 'contractAmount' is not null or undefined
            if (contractAmount === null || contractAmount === undefined) {
                throw new RequiredError('contractAmount','Required parameter contractAmount was null or undefined when calling getManufacturers.');
            }
            // verify required parameter 'itemCategory' is not null or undefined
            if (itemCategory === null || itemCategory === undefined) {
                throw new RequiredError('itemCategory','Required parameter itemCategory was null or undefined when calling getManufacturers.');
            }
            const localVarPath = `/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (template !== undefined) {
                localVarQueryParameter['template'] = template;
            }

            if (contractAmount !== undefined) {
                localVarQueryParameter['contract_amount'] = contractAmount;
            }

            if (itemCategory !== undefined) {
                localVarQueryParameter['itemCategory'] = itemCategory;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} item
         * @param {number} retailPrice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates: async (template: string, contractAmount: number, item: string, retailPrice: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'template' is not null or undefined
            if (template === null || template === undefined) {
                throw new RequiredError('template','Required parameter template was null or undefined when calling getTemplates.');
            }
            // verify required parameter 'contractAmount' is not null or undefined
            if (contractAmount === null || contractAmount === undefined) {
                throw new RequiredError('contractAmount','Required parameter contractAmount was null or undefined when calling getTemplates.');
            }
            // verify required parameter 'item' is not null or undefined
            if (item === null || item === undefined) {
                throw new RequiredError('item','Required parameter item was null or undefined when calling getTemplates.');
            }
            // verify required parameter 'retailPrice' is not null or undefined
            if (retailPrice === null || retailPrice === undefined) {
                throw new RequiredError('retailPrice','Required parameter retailPrice was null or undefined when calling getTemplates.');
            }
            const localVarPath = `/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            if (template !== undefined) {
                localVarQueryParameter['template'] = template;
            }

            if (contractAmount !== undefined) {
                localVarQueryParameter['contract_amount'] = contractAmount;
            }

            if (item !== undefined) {
                localVarQueryParameter['item'] = item;
            }

            if (retailPrice !== undefined) {
                localVarQueryParameter['retail_price'] = retailPrice;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Account} body
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (body: Account, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccount.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAccount.');
            }
            const localVarPath = `/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {Account} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(body: Account, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createAccount(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {ContractRequest} body Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContract(body: ContractRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createContract(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAccount(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAccounts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemCategories(template: string, contractAmount: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItemCategory>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getItemCategories(template, contractAmount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} itemCategory
         * @param {string} manufacturer
         * @param {string} [search]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItems(template: string, contractAmount: number, itemCategory: string, manufacturer: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Item>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getItems(template, contractAmount, itemCategory, manufacturer, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} itemCategory
         * @param {string} [search]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturers(template: string, contractAmount: number, itemCategory: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Manufacturer>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getManufacturers(template, contractAmount, itemCategory, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} item
         * @param {number} retailPrice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplates(template: string, contractAmount: number, item: string, retailPrice: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Template>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTemplates(template, contractAmount, item, retailPrice, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {Account} body
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(body: Account, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateAccount(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @param {Account} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: Account, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).createAccount(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ContractRequest} body Optional description in *Markdown*
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContract(body: ContractRequest, options?: any): AxiosPromise<ContractResponse> {
            return DefaultApiFp(configuration).createContract(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(id: string, options?: any): AxiosPromise<Account> {
            return DefaultApiFp(configuration).getAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(options?: any): AxiosPromise<Array<Account>> {
            return DefaultApiFp(configuration).getAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemCategories(template: string, contractAmount: number, options?: any): AxiosPromise<Array<ItemCategory>> {
            return DefaultApiFp(configuration).getItemCategories(template, contractAmount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} itemCategory
         * @param {string} manufacturer
         * @param {string} [search]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems(template: string, contractAmount: number, itemCategory: string, manufacturer: string, search?: string, options?: any): AxiosPromise<Array<Item>> {
            return DefaultApiFp(configuration).getItems(template, contractAmount, itemCategory, manufacturer, search, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} itemCategory
         * @param {string} [search]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturers(template: string, contractAmount: number, itemCategory: string, search?: string, options?: any): AxiosPromise<Array<Manufacturer>> {
            return DefaultApiFp(configuration).getManufacturers(template, contractAmount, itemCategory, search, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} template
         * @param {number} contractAmount
         * @param {string} item
         * @param {number} retailPrice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(template: string, contractAmount: number, item: string, retailPrice: number, options?: any): AxiosPromise<Array<Template>> {
            return DefaultApiFp(configuration).getTemplates(template, contractAmount, item, retailPrice, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Account} body
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(body: Account, id: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).updateAccount(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {Account} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createAccount(body: Account, options?: any) {
        return DefaultApiFp(this.configuration).createAccount(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ContractRequest} body Optional description in *Markdown*
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createContract(body: ContractRequest, options?: any) {
        return DefaultApiFp(this.configuration).createContract(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccount(id: string, options?: any) {
        return DefaultApiFp(this.configuration).getAccount(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccounts(options?: any) {
        return DefaultApiFp(this.configuration).getAccounts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} template
     * @param {number} contractAmount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getItemCategories(template: string, contractAmount: number, options?: any) {
        return DefaultApiFp(this.configuration).getItemCategories(template, contractAmount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} template
     * @param {number} contractAmount
     * @param {string} itemCategory
     * @param {string} manufacturer
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getItems(template: string, contractAmount: number, itemCategory: string, manufacturer: string, search?: string, options?: any) {
        return DefaultApiFp(this.configuration).getItems(template, contractAmount, itemCategory, manufacturer, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} template
     * @param {number} contractAmount
     * @param {string} itemCategory
     * @param {string} [search]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getManufacturers(template: string, contractAmount: number, itemCategory: string, search?: string, options?: any) {
        return DefaultApiFp(this.configuration).getManufacturers(template, contractAmount, itemCategory, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} template
     * @param {number} contractAmount
     * @param {string} item
     * @param {number} retailPrice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTemplates(template: string, contractAmount: number, item: string, retailPrice: number, options?: any) {
        return DefaultApiFp(this.configuration).getTemplates(template, contractAmount, item, retailPrice, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {Account} body
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAccount(body: Account, id: string, options?: any) {
        return DefaultApiFp(this.configuration).updateAccount(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
