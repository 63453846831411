import React from "react";
import { ContractDataOptions, CustomerDataOptions } from "./CustomerDetail";
import Modal from "../../components/Modal";
import Select from "../../components/Form/Select";
import Button, { Variant } from "../../components/Button";
import Input from "../../components/Form/Input";
import ContractBanner from "../../components/ContractBanner";
import { middleware, contractAxios } from "../../plugins/axios";
import { toastError, toastSuccess } from "../../plugins/toast";
import DateInput from "../../components/Form/DateInput";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

interface ContractPayload {
  itemDescription: string;
  itemCategoryCode: string;
  collectionItemNo: string;
  manufacturer: string;
  description: string;
  purchaseDate: string;
  objectValue: string;
  iMEI: string;
  serialNo: string;
  storeOfPurchase: string;
  organisation: string;
  organisationEmail: string;
}

type contractModalProps = {
  isOpen: boolean;
  setIsOpen: any;
  contractToEdit: ContractDataOptions;
  organisation: any;
  organisationEmail: string;
  customer: CustomerDataOptions;
};

type contractModalState = {
  contract: ContractDataOptions;
  selectedBrand: {
    label: string;
    value: any;
  };
  manufactures: Array<{ label: string; value: string }>;
  payload: ContractPayload;
  manufacturesLoading: boolean;
  manufacturesEnabled: boolean;
};

export class ContractModal extends React.Component<
  contractModalProps,
  contractModalState
> {
  state = {
    contract: this.props.contractToEdit,
    selectedBrand: {
      label: "",
      value: "",
    },
    manufactures: [
      {
        label: "",
        value: "",
      },
    ],
    payload: {
      itemDescription: "",
      itemCategoryCode: this.props.contractToEdit.category,
      collectionItemNo: "",
      manufacturer: "",
      description: "",
      purchaseDate: "",
      objectValue: "",
      iMEI: "",
      serialNo: "",
      storeOfPurchase: "",
      organisation: this.props.organisation.name,
      organisationEmail: this.props.organisationEmail,
    },
    manufacturesLoading: false,
    manufacturesEnabled: false,
  };

  setSelectedBrand(e: { label: string; value: any }) {
    var newPayload = { ...this.state.payload };
    newPayload.manufacturer = e.value;
    this.setState({
      selectedBrand: e,
      payload: newPayload,
    });
  }

  async componentDidMount() {
    this.setState({
      manufacturesLoading: true,
      manufacturesEnabled: false,
    });
    contractAxios.get(`manufacturers?template=${this.props.customer.template}&contract_amount=1&itemCategory=${this.props.contractToEdit.category}&search=`)
      .then((res) => {
        var manufactures = res.data.map((el: any) => {
          return { label: el.name, value: el.code };
        });
        // var selectedBrand = manufactures.find(
        //   (e: any) => e.label === this.props.contractToEdit.manufacturer
        // );
        this.setState({
          manufactures: manufactures,
          manufacturesLoading: false,
          manufacturesEnabled: true,
        });
      });
    // middleware
    //   .get(
    //     ` http://localhost:9000/manufacturers?template=null&contract_amount=1&itemCategory=${this.props.contractToEdit.category}&search=`
    //   )
    //   .then((res) => {
    //     var manufactures = res.data.map((el: any) => {
    //       return { label: el.name, value: el.code };
    //     });
    //     // var selectedBrand = manufactures.find(
    //     //   (e: any) => e.label === this.props.contractToEdit.manufacturer
    //     // );
    //     this.setState({
    //       manufactures: manufactures,
    //       manufacturesLoading: false,
    //       manufacturesEnabled: true,
    //     });
    //   });
  }

  saveContract() {
    middleware
      .post(
        `contracts/service/change/${this.props.customer.id}/${this.props.contractToEdit.contract_id}`,
        { data: this.state.payload }
      )
      .then((res) => {
        if (res.status === 200) {
          toastSuccess("Contract mutation requested successfully");
          this.props.setIsOpen(false, true);
        }
      })
      .catch((e) => toastError("Something went wrong", e.message));
  }

  handleDateChangePayloadValue(date: Date) {
    var newPayload: any = {
      ...this.state.payload,
      purchaseDate: date.toISOString().split("T")[0],
    }
    this.setState({
      payload: newPayload
    })
  }

  handleChangePayloadValue(e: any) {
    var newPayload: any = { ...this.state.payload };
    newPayload[e.target.name] = e.target.value;
    this.setState({
      payload: newPayload,
    });
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} setIsOpen={this.props.setIsOpen}>
        <Modal.Header>
          <div className="text-2xl text-gray-800 font-semibold">
            <Translate name={"detail.edit_contract.title"} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <ContractBanner
            category={this.props.contractToEdit.category}
            itemDescription={this.props.contractToEdit.item_description}
            contractDescription={this.props.contractToEdit.contract_description}
          />
          <Select
            placeholder=""
            selected={this.state.selectedBrand}
            setSelected={(e) => this.setSelectedBrand(e)}
            loading={this.state.manufacturesLoading}
            disabled={!this.state.manufacturesEnabled}
            id="brand"
            name="brand"
            options={this.state.manufactures}
            label=""
            translationKey="detail.edit_contract.brand_label"
          />
          <Input
            label=""
            translationKey="detail.edit_contract.description_label"
            value={this.state.payload.description}
            onChange={this.handleChangePayloadValue.bind(this)}
            name="description"
            placeholder={i18n.t(
              "detail.edit_contract.description_placeholder"
            )}
          />
          <Input
            label=""
            translationKey="detail.edit_contract.price_label"
            value={this.state.payload.objectValue}
            onChange={this.handleChangePayloadValue.bind(this)}
            name="objectValue"
            placeholder={i18n.t(
              "detail.edit_contract.price_placeholder"
            )}
          />
          <Input
            label=""
            translationKey="detail.edit_contract.collection_no_label"
            value={this.state.payload.collectionItemNo}
            onChange={this.handleChangePayloadValue.bind(this)}
            name="collectionItemNo"
            placeholder={i18n.t(
              "detail.edit_contract.collection_no_placeholder"
            )}
          />
          <DateInput
            label=""
            translationKey="detail.edit_contract.purchase_date_label"
            value={this.state.payload.purchaseDate}
            onChange={this.handleDateChangePayloadValue.bind(this)}
          />
          {this.props.contractToEdit.category.toLowerCase() === "phone" ? (
            <Input
              label=""
              translationKey="detail.edit_contract.imei_label"
              value={this.state.payload.iMEI}
              onChange={this.handleChangePayloadValue.bind(this)}
              name="iMEI"
              placeholder={i18n.t(
                "detail.edit_contract.imei_placeholder"
              )}
            />
          ) : (
            <Input
              label=""
              translationKey="detail.edit_contract.serial_no_label"
              value={this.state.payload.serialNo}
              onChange={this.handleChangePayloadValue.bind(this)}
              name="serialNo"
              placeholder={i18n.t(
                "detail.edit_contract.serial_no_placeholder"
              )}
            />
          )}
          <Input
            label=""
            translationKey="detail.edit_contract.store_label"
            value={this.state.payload.storeOfPurchase}
            onChange={this.handleChangePayloadValue.bind(this)}
            name="storeOfPurchase"
            placeholder={i18n.t(
              "detail.edit_contract.store_placeholder"
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              onClick={() => this.props.setIsOpen(false)}
              variant={Variant.TRANSPARENT}
            >
              <Translate name={"detail.common.back_to_detail"} />
            </Button>
          </div>
          <div>
            <Button onClick={() => this.saveContract()}>
              <Translate name={"detail.common.save"} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
