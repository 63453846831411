import React from "react";
import Translate from "./Translate";

interface IAlertProps {
  heading: string
  translationKey?: string
}

const Alert: React.FC<IAlertProps> = (props) => {
  return (
    <div className="rounded-md bg-purple-200 text-purple-700 text-sm leading-5 pt-3 pb-6 px-3 border border-purple-700 rounded-3px">
      <div className="flex flex-col">
        <div className="flex flex-wrap">
          <span className="rounded-full bg-purple-700 text-white w-5 h-5 flex items-center justify-center mr-2">
              !
          </span>

          <h3 className="font-bold leading-5 text-purple-700">
            {!props.translationKey ? props.heading : <Translate name={props.translationKey} />}
          </h3>
        </div>
        <div className="mt-4">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Alert;
