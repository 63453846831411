import i18next from "i18next";

import { middleware } from "./axios";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const i18n = i18next.createInstance();

const loadLocales = async (userLanguage: string) => {
  const resources: any = {};
  const response = await middleware.get("/translations/?type=SERVICE");
  await response.data.forEach((apiLanguage: any) => {
    resources[apiLanguage.language.replace("_", "-")] = {
      "": apiLanguage.data,
    };
    if (apiLanguage.language === "nl_NL") {
      resources["nl"] = {
        "": apiLanguage.data,
      };
    }
    if (apiLanguage.language === "en_US") {
      resources["en"] = {
        "": apiLanguage.data,
      };
    }
    if (apiLanguage.language === "fr_FR") {
      resources["fr"] = {
        "": apiLanguage.data,
      };
    }
  });
  await i18n.use(I18nextBrowserLanguageDetector).use(initReactI18next).init({
    resources: resources,
    debug: false,
    fallbackLng: "en",
    fallbackNS: "",
    supportedLngs: ["en", "nl", "fr"]
  });

  if (userLanguage !== "") {
    await i18n.changeLanguage(userLanguage)
  }
};

export default i18n;
export { loadLocales };
