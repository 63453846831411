export default  {
    control: (provided: any, state: any) => ({
        ...provided,
        border: '1px solid'
    }),
    container: (provided: any, state: any) => ({
        ...provided,
        padding: '0'
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        border: 'none',
        display: 'none'
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        padding: '12px 16px',
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        padding: '12px 16px',
    }),
    input: (provided: any, state: any) => ({
        ...provided,
        padding: '12px 16px',
        border: 'none',
        outline: 'none',
        'outline-offest': 'none'
    }),
}