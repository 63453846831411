import React from "react";

interface IAppHeaderProps {
  children?: React.ReactNode;
}

const AppHeader: React.FC<IAppHeaderProps> = (props) => {
  return (
    <div className="py-2 px-8 h-16 border-b border-gray-300 flex flex-wrap justify-between items-center w-full">
      {props.children}
    </div>
  );
};

export default AppHeader;
