import axios, { AxiosInstance } from "axios";
import auth from "./firebase";
import { DefaultApi } from "./contract-api-client";
import firebase from "firebase";
import { env } from "../env";

const addInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(async function (config) {
    const token = await firebase.auth().currentUser?.getIdToken();

    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        auth.signOut();
      }
      return Promise.reject(error);
    }
  );
};

export const middleware = axios.create({
  baseURL: env.REACT_APP_MIDDLEWARE_BASE_URL,
});
addInterceptors(middleware);

export const organisationService = axios.create({
  baseURL: env.REACT_APP_ORGANISATION_SERVICE_URL,
});
addInterceptors(organisationService);

export const addressService = axios.create({
  baseURL: env.REACT_APP_ADDRESS_SERVICE_BASE_URL
})

export const contractAxios = axios.create({
  baseURL: env.REACT_APP_CONTRACT_SERVICE_URL,
});
addInterceptors(contractAxios);

export const contractService = new DefaultApi(
  {},
  env.REACT_APP_CONTRACT_SERVICE_URL,
  contractAxios
);
