import React from "react";
import { classNames } from "../utils/classNames";

export enum Variant {
  PURPLE,
  LIGHT_PURPLE,
  TRANSPARENT,
}

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  children: React.ReactNode;
  classList?: string;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

const VARIANT_MAPS: Record<Variant, string> = {
  [Variant.PURPLE]: "text-white bg-gradient-to-b from-purple-500 to-purple-600",
  [Variant.LIGHT_PURPLE]:
    "text-purple-700 bg-purple-300 border border-purple-700",
  [Variant.TRANSPARENT]: "text-purple-700 bg-transparent",
};

const Button: React.FC<IButtonProps> = ({
  variant,
  children,
  classList,
  onClick,
  ...rest
}) => {
  const disabled: boolean = rest.disabled !== undefined ? rest.disabled : false;
  const id = rest.id || "";
  if (!variant) {
    variant = Variant.PURPLE;
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      id={id}
      className={classNames(
        "inline-block py-2 px-4 text-sm leading-6 text-center font-bold rounded cursor-pointer  disabled:opacity-50 disabled:cursor-text",
        classList,
        VARIANT_MAPS[variant]
      )}
    >
      {children}
    </button>
  );
};

export default Button;
