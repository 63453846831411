import React, {useContext, useEffect, useState} from "react";
import DatePicker, {utils, DayValue} from 'react-modern-calendar-datepicker';
import Label from "./Label";
import {LanguageContext} from "../../providers/LanguageProvider";
import Translate from "../Translate";

interface DateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (e: any) => void;
  label: string;
  translationKey?: string;
  error?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({ value, onChange, label, translationKey, id, error = false, ...rest }) => {
  const languageContext = useContext(LanguageContext);

  const [selectedDay, setSelectedDay] = useState<DayValue>(null);

  useEffect(() => {
    if(selectedDay) {
      let date = new Date();
      date.setFullYear(selectedDay!.year, selectedDay!.month - 1, selectedDay!.day);
      onChange(date);
    }
  }, [selectedDay])

  // @ts-ignore
  const renderCustomInput = ({ ref }) => (
    <input
      id={id}
      ref={ref} // necessary
      readOnly
      value={formatInputValue()}
      className={`block py-3 px-4 w-full rounded-3px border ${
        error ? "border-red-800" : "border-purple-800"
      } appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 placeholder-gray-700 placeholder-opacity-40`}
    />
  )

  const formatInputValue = () => {
    const date = value !== "" ? new Date(value) : new Date()
    return date.toLocaleDateString();
  };

  const getDayValue = (value: string) => {
    if(value === "") {
      return utils("en").getToday()
    }
    const date = new Date(value);
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
  }

  return (
    <div>
      <Label id={id}>
        {!translationKey ? label : <Translate name={translationKey}/>}
      </Label>
      <div className="flex flex-col mt-2">
        <DatePicker
          value={getDayValue(value)}
          onChange={setSelectedDay}
          renderInput={renderCustomInput}
          formatInputText={formatInputValue}
          maximumDate={utils("en").getToday()}
          calendarPopperPosition="top"
        />
      </div>
    </div>
  );
};

export default DateInput;
