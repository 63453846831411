import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { classNames } from "../../utils/classNames";
import Label from "./Label";
import { Translation } from "react-i18next";
import Translate from "../Translate";

interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  id: string;
  label?: string;
  translationKey?: string;
  options: Array<any>;
  loading?: boolean;
  error?: boolean;
  selected: {
    label: any;
    value: string | number;
  };
  setSelected: React.Dispatch<React.SetStateAction<any>>;
}

const Select: React.FC<SelectProps> = ({
  label,
  id,
  options,
  selected,
  translationKey,
  loading = false,
  error = false,
  setSelected,
  ...rest
}) => {
  const disabled: boolean = rest.disabled !== undefined ? rest.disabled : false;
  return (
    <Listbox value={selected} disabled={disabled} onChange={setSelected}>
      {({ open }) => (
        <>
          <div id={id} className="relative">
            {label && (
              <Label>
                {!translationKey ? label : <Translate name={translationKey} />}
              </Label>
            )}
            <Listbox.Button
              className={`block py-3 px-4 w-full rounded-3px relative border ${
                error ? "border-red-800" : "border-purple-800"
              } appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 placeholder-gray-700 placeholder-opacity-40 truncate  min-h-12.25 ${
                disabled || loading ? "bg-gray-200" : ""
              }`}
            >
              <span
                className={`block text-left ${
                  disabled || loading ? "text-gray-700" : ""
                }`}
              >
                {loading ? "Loading..." : selected.label}
              </span>

              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 w-full bg-white shadow-lg max-h-64 text-sm -mt-1 overflow-auto focus:outline-none sm:text-sm  border border-purple-800 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 placeholder-gray-700 placeholder-opacity-40"
              >
                {options.map((option) => (
                  <Listbox.Option
                    disabled={
                      option.hasOwnProperty("disabled")
                        ? option.disabled
                        : false
                    }
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-purple-300" : "bg-white",
                        option.hasOwnProperty("disabled") && option.disabled
                          ? "bg-gray-50"
                          : "bg-white",
                        "cursor-default select-none relative  py-3 px-4"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            option.hasOwnProperty("disabled") && option.disabled
                              ? "text-gray-700"
                              : "",
                            "block truncate"
                          )}
                        >
                          {option.label}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Select;
