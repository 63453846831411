import React from "react";
import Modal from "./Modal";
import Button from "./Button";
import Translate from "./Translate";

type IntroVideoProps = {
  isOpen: boolean;
  setIsOpen: any;
  loggedInUser: any;
  organisationUser: any;
  organisationUserContext: any;
};

type IntroVideoState = {};

export class IntroVideo extends React.Component<
  IntroVideoProps,
  IntroVideoState
> {
  state = {};

  confirmIntro(organisationUser: any) {
    if (organisationUser) {
      this.props.organisationUserContext
        .markOrganisationUserAsOnboarded(organisationUser.id)
        .then(() => {
          this.props.setIsOpen(false);
        });
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        setIsOpen={this.props.setIsOpen}
        allowCloseOnBody={false}
        maxWidth={"max-w-3xl md:min-w-256"}
      >
        <Modal.Header>
          <div className="text-2xl text-gray-800 font-semibold">
            <Translate name={"intro.title"} />
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-4">
            <div className="text-gray-800 mb-5">
              <Translate name={"intro.text"} />
            </div>
            <div>
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                <iframe
                  style={{ position: "absolute", top: 0, left: 0 }}
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/IaJdVi_SSqQ"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Button
              className="align-right"
              onClick={() => this.confirmIntro(this.props.organisationUser)}
            >
              <Translate name={"intro.confirm_intro"} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
