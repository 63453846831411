import React from "react";
import Translate from "../Translate";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  translationKey?: string
}

const Checkbox: React.FC<CheckboxProps> = ({label, translationKey, id, ...rest}) => {
  return (
    <label htmlFor={id} className="inline-flex items-start">
      <input
        id={id}
        type="checkbox"
        className="w-4 h-4 my-1 text-purple-300 border border-purple-800 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800"
        {...rest}
      />
      <span className="ml-2 block font-base text-gray-800 leading-5">{
        !translationKey ?
          label :
          <Translate name={translationKey} />
      }</span>
    </label>
  )
};

export default Checkbox;