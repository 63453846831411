import logo from "../../assets/img/logo-harmony.png";
import React, {useContext, useState} from "react";
import {NavLink} from "react-router-dom";
import {UserContext} from "../../providers/UserProvider";
import Icon from "../../components/Icon";
import {OrganisationContext} from "../../providers/OrganisationProvider";
import {IntroVideo} from "../../components/IntroVideo";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import Translate from "../../components/Translate";

const AppLayout: React.FC = (props) => {
  const userContext = useContext(UserContext);
  const organisationContext = useContext(OrganisationContext);

  const organisation = organisationContext.organisation;
  const organisationUser = organisationContext.organisationUser;
  const organisationUserInfo = organisationContext.organisationUserInfo;

  const [showIntro, setShowIntro] = useState<boolean>(true);

  const openJubileeLink = async (): Promise<void> => {
    const jubileeUrl = await organisationContext.getJubileeLink()
    if (jubileeUrl == "") return;
    window.open(jubileeUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className="flex flex-row min-h-screen text-gray-800">
      <div className="flex fixed flex-col w-64 h-screen bg-gray-200">
        <div className="p-4 px-14 text-center border-b border-gray-300">
          <img className="" src={logo} alt="Harmony logo"/>
        </div>
        <div className="flex flex-row p-8 text-center border-b border-gray-300">
          {/* <div className="p-2 w-10 h-10 text-center bg-white rounded-full">
            { organisationUser != null
                ? `${organisationUser!.email[0]}${organisationUser!.email[1]}`
                : ''
              }
          </div> */}
          <div className="flex-grow ml-4 text-left">
            <div className="text-sm text-gray-800">
              {organisationUser != null
                ? `${organisationUser!.email.split("@")[0]}`
                : ""}
            </div>
            <div className="text-sm tracking-wide text-gray-600 text-uppercase">
              {organisation != null ? organisation!.name : ""}
            </div>
          </div>
        </div>
        <div className="flex-grow p-4">
          {organisationUser &&
          (organisationUser.role === "supervisor" ||
            organisationUser.role === "admin") ? (
            <NavLink
              to="/team"
              className="py-3 px-2.5 mt-4 text-sm font-light rounded-md cursor-pointer flex flex-row items-center"
              activeClassName="text-white bg-purple-700"
            >
              <Icon name="icon_teammanagement" classList="mr-2.5"/>

              <span><Translate name={"menu.team_management"}/></span>
            </NavLink>
          ) : null}

          <NavLink
            to="/"
            exact
            className="py-3 px-2.5 mt-4 text-sm font-light rounded-md cursor-pointer flex flex-row items-center"
            activeClassName="text-white bg-purple-700"
          >
            <Icon name="icon_customer" classList="mr-2.5"/>

            <span><Translate name={"menu.customers"}/></span>
          </NavLink>

          {organisationUserInfo?.connected_jubilee && (
            <div
              className="py-3 px-2.5 mt-4 text-sm font-light rounded-md cursor-pointer flex flex-row items-center"
              onClick={openJubileeLink}
            >
              <Icon name="external-link" classList="mr-2.5 w-5"/>

              <span><Translate name={"menu.jubilee"}/></span>
            </div>
          )}
        </div>

        <div className="p-4">
          <div className="w-full px-2.5 mt-4 text-sm font-light rounded-md flex flex-row items-center">
            <Icon name="icon_language" classList="mr-2.5 w-5"/>

            <div className="w-full">
              <LanguageSwitcher/>
            </div>
          </div>

          <div className="py-3 px-2.5 mt-4 text-sm font-light rounded-md cursor-pointer flex flex-row items-center">
            <Icon name="icon_chat" classList="mr-2.5 w-5"/>

            <span><Translate name={"menu.help"}/></span>
          </div>

          <div
            className="py-3 px-2.5 mt-4 text-sm font-light rounded-md cursor-pointer flex flex-row items-center"
            onClick={userContext.doLogout}
          >
            <Icon name="icon_logout" classList="mr-2.5 w-5"/>

            <span><Translate name={"menu.logout"}/></span>
          </div>
        </div>
      </div>
      <div className="flex-grow ml-64 h-full ">
        {props.children}
      </div>
      {!organisationUserInfo?.viewed_onboarding ?
        <IntroVideo
          isOpen={showIntro}
          setIsOpen={setShowIntro}
          loggedInUser={userContext}
          organisationUser={organisationUserInfo}
          organisationUserContext={organisationContext}
        />
        : null}
    </div>
  );
};

export default AppLayout;
