import React, {createContext, useContext, useEffect, useState} from "react";
import {middleware, organisationService} from "../plugins/axios";
import {UserContext} from "./UserProvider";
import firebase from "firebase";

interface IOrganisationContextProps {
  organisation: { [key: string]: any } | null;
  organisationUser: { [key: string]: any } | null;
  organisationUserInfo: { [key: string]: any } | null;
  organisationUsers: { [key: string]: any } | null;
  getOrganisationInfo: (id: string) => Promise<void>;
  getOrganisationUserInfo: (
    organisationId: string,
    firebaseUid: string
  ) => Promise<void>;
  getOrganisationUsers: (organisationId: string) => Promise<void>;
  patchOrganisationUser: (
    organisationId: string,
    firebaseUid: string
  ) => Promise<boolean>;
  markOrganisationUserAsOnboarded: (firebaseUid: string) => Promise<boolean>;
  updateUserLanguageSetting: (
    firebaseUid: string,
    language: string
  ) => Promise<boolean>;
  getJubileeLink: () => Promise<string>;
}

export const OrganisationContext = createContext(
  {} as IOrganisationContextProps
);

const OrganisationProvider: React.FC = (props) => {
  let [organisation, setOrganisation] = useState<{ [key: string]: any } | null>(
    null
  );
  let [organisationUser, setOrganisationUser] = useState<{
    [key: string]: any;
  } | null>(null);
  let [organisationUserInfo, setOrganisationUserInfo] = useState<{
    [key: string]: any;
  } | null>(null);
  let [organisationUsers, setOrganisationUsers] = useState<{
    [key: string]: any;
  } | null>(null);

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.userClaims != null && !organisation) {
      getOrganisationInfo(userContext.userClaims.organisation).then();
      getOrganisationUserInfo().then();
    }
  }, [userContext.userClaims?.organisation]);

  const getOrganisationInfo = async (id: string) => {
    middleware.get(`service/organisation/${id}`).then((res) => {
      setOrganisation(res.data);
      getOrganisationUsers(res.data.id);
    });
  };

  const getOrganisationUsers = async (id: string) => {
    organisationService
      .get(`/organisation_users?organisation=${id}&uid=`)
      .then((res) => {
        setOrganisationUsers(res.data);
      });

    // middleware.get(`service/organisation_users/${id}`).then((res) => {
    //   setOrganisationUsers(res.data);
    // });
  };

  const parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const getOrganisationUserInfo = async () => {
    const token = await firebase.auth().currentUser?.getIdToken();

    if (token) {
      let jwtToken = parseJwt(token);

      // Fetch logged in organisationUser for its permissions
      organisationService
        .get(
          `/organisation_users?organisation=${jwtToken.organisation}&uid=${jwtToken.user_id}`
        )
        .then((res) => {
          if (!res.data.length) return;

          setOrganisationUserInfo(res.data[0]);
        });

      await setOrganisationUser(jwtToken);
      if (organisationUser) {
        const updatedOrganisation = {...organisation};
        updatedOrganisation.email = organisationUser?.email;
        setOrganisation(updatedOrganisation);
      }
    }
  };

  const patchOrganisationUser = async (
    organisationId: string,
    userId: string
  ): Promise<boolean> => {
    await organisationService.patch(`/organisation_users/${userId}/onboarded`);
    return true;
    // middleware.get(`service/organisation_users/${id}`).then((res) => {
    //   setOrganisationUsers(res.data);
    // });
  };

  const markOrganisationUserAsOnboarded = async (
    userId: string
  ): Promise<boolean> => {
    await organisationService.patch(`/organisation_users/${userId}/onboarded`);
    return true;
  };

  const updateUserLanguageSetting = async (
    userId: string,
    language: string
  ): Promise<boolean> => {
    await organisationService.patch(
      `/organisation_users/${userId}/language/${language}`
    );
    return true;
  };

  const getJubileeLink = async (): Promise<string> => {
    if (organisationUserInfo && organisationUserInfo.connected_jubilee && organisationUserInfo.jubilee_url) {
      if (organisationUserInfo.jubilee_url == "") return "";

      let jubileeUrl = organisationUserInfo.jubilee_url;

      if (!jubileeUrl.startsWith('http://') && !jubileeUrl.startsWith('https://')) {
        jubileeUrl = `https://${jubileeUrl}`;
      }

      return jubileeUrl;
    }

    return "";
  }

  return (
    <OrganisationContext.Provider
      value={{
        organisation,
        organisationUser,
        organisationUserInfo,
        organisationUsers,
        getOrganisationInfo,
        getOrganisationUserInfo,
        getOrganisationUsers,
        patchOrganisationUser,
        markOrganisationUserAsOnboarded,
        updateUserLanguageSetting,
        getJubileeLink
      }}
    >
      {props.children}
    </OrganisationContext.Provider>
  );
};

export default OrganisationProvider;
