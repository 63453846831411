import React, { FormEvent, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "../../providers/UserProvider";
import firebase from "firebase";
import EnrollPhoneMFA from "../../components/2fa/EnrollPhoneMFA";
import Checkbox from "../../components/Form/Checkbox";
import { Trans } from "react-i18next";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";
import { useValidationContext } from "../../providers/ValidationProvider";
import ValidationError from "../../components/Form/ValidationError";
import Button from "../../components/Button";
import { env } from "../,,/../../env";
import Modal from "../../components/Modal";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const Login: React.FC = () => {
  const userContext = useContext(UserContext);
  const validationContext = useValidationContext();

  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  let [verificationCode, setVerificationCode] = useState<string>("");
  let [invalidLogin, setInvalidLogin] = useState(false);
  let [invalidLoginMessage, setInvalidLoginMessage] = useState("");
  let [multiFactorAuthNeeded, setMultiFactorAuthNeeded] =
    useState<boolean>(false);
  let [registerMultiFactorAuthNeeded, setRegisterMultiFactorAuthNeeded] =
    useState<boolean>(false);
  let [multiFactorResolver, setMultiFactorResolver] = useState(null);
  let [recaptchaVerifier, setRecaptchaVerifier] =
    useState<firebase.auth.RecaptchaVerifier | null>(null);
  let [recaptchaWidgetId, setRecaptchaWidgetId] = useState<number | null>(null);
  let [verificationId, setVerificationId] = useState<string>("");
  let [invalidVerification, setInvalidVerification] = useState(false);
  let [verificationError, setVerificationError] = useState<string>("");
  let [showPasswordlessModal, setShowPasswordlessModal] = useState(false);
  let [loggedinPasswordless, setLoggedinPasswordless] = useState(false);

  useEffect(() => {
    const isEmailSignIn = userContext.isSignInWithEmailLink();
    if (isEmailSignIn) {
      const email = window.localStorage.getItem("emailForSignIn");
      window.localStorage.removeItem("emailForSignIn");
      if (!email) {
        setInvalidLogin(true);
        setInvalidLoginMessage(i18n.t("login.invalid_login"));
        return;
      }
      setLoggedinPasswordless(true);
      userContext.doSignInWithEmailLink(email);
    }
  }, []);

  useEffect(() => {
    if (userContext.isValidLogin !== null) {
      setInvalidLogin(!userContext.isValidLogin);
      setInvalidLoginMessage(i18n.t("login.inactive_login"));
    }
  }, [userContext.isValidLogin]);

  useEffect(() => {
    if (!recaptchaVerifier && multiFactorAuthNeeded) {
      const verifier = new firebase.auth.RecaptchaVerifier(
        "multi-factor-button",
        {
          size: "invisible",
        }
      );

      verifier.render().then((widgetId) => {
        setRecaptchaVerifier(verifier);
        setRecaptchaWidgetId(widgetId);
      });

      setRecaptchaVerifier(verifier);
    }
  }, [multiFactorAuthNeeded]);

  useEffect(() => {
    if (recaptchaVerifier && multiFactorAuthNeeded && multiFactorResolver) {
      userContext
        .getLoginPhoneVerificationId(multiFactorResolver!, recaptchaVerifier)
        .then((id) => {
          setVerificationId(id);
        });
    }
  }, [multiFactorAuthNeeded, multiFactorResolver, recaptchaVerifier]);

  useEffect(() => {
    if (userContext.hasEnrolled === null) {
      setRegisterMultiFactorAuthNeeded(false);
    } else {
      setRegisterMultiFactorAuthNeeded(!userContext.hasEnrolled);
    }
  }, [userContext.hasEnrolled]);

  const resetLogin = async () => {
    recaptchaVerifier?.clear();

    setUsername("");
    setPassword("");
    setMultiFactorAuthNeeded(false);
    setMultiFactorResolver(null);
    setRecaptchaWidgetId(null);
    setRecaptchaVerifier(null);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInvalidLogin(false);
    try {
      const error = await userContext.doLogin(username, password);
      if (error) {
        if (error.code === "auth/multi-factor-auth-required") {
          const resolver = error.resolver;
          setMultiFactorAuthNeeded(true);
          setMultiFactorResolver(resolver);
        } else if (error.code === "auth/wrong-password") {
          setInvalidLogin(true);
          setInvalidLoginMessage(i18n.t("login.invalid_login"));
        } else if (error.code === "auth/too-many-requests") {
          setInvalidLogin(true);
          setInvalidLoginMessage(i18n.t("login.too-many-attempts"));
        }
      }
    } catch (e) {
      setInvalidLogin(true);
      setInvalidLoginMessage(i18n.t("login.invalid_login"));
    }
  };

  const handleVerificationCodeSubmit = async () => {
    if (multiFactorResolver) {
      const loggedIn = await userContext.finishMultiFactorLogin(
        verificationId,
        verificationCode,
        multiFactorResolver!
      );
      if (!loggedIn) {
        setInvalidVerification(true);
        setVerificationError(i18n.t("login.multi_factor_error"));
        window.grecaptcha.reset(recaptchaWidgetId);
      }
    }
  };

  const handleAzureLogin = async () => {
    setInvalidLogin(false);
    try {
      await userContext.doAzureLogin();
    } catch (e) {
      console.error(e);
      setInvalidLogin(true);
      setInvalidLoginMessage(i18n.t("login.invalid_login"));
    }
  };

  const openPasswordlessModal = () => {
    setShowPasswordlessModal(true);
  };

  const handlePasswordlessLogin = async () => {
    setInvalidLogin(false);
    try {
      window.localStorage.setItem("emailForSignIn", username);
      const doPasswordlessLogin = await userContext.doPasswordlessLogin(
        username
      );
      if (doPasswordlessLogin) {
        setLoggedinPasswordless(true);
        console.log("logged in passwordless");
      } else {
        setInvalidLogin(true);
        setInvalidLoginMessage(i18n.t("login.invalid_login"));
      }
    } catch (e) {
      console.error(e);
      setInvalidLogin(true);
      setInvalidLoginMessage(i18n.t("login.invalid_login"));
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setUsername(newValue);
    if (!validationContext.validateEmail(newValue)) {
      setUserNameError(i18n.t("login.errors.email_not_valid"));
    } else {
      setUserNameError("");
    }
  };

  return (
    <div>
      <div className="mb-8 text-3xl font-medium">
        <Translate name={"login.title"} />
      </div>
      <div className="mb-8">
        <Translate name={"login.description"} />
      </div>
      <form onSubmit={handleSubmit}>
        {env.REACT_APP_DISABLE_NON_AZURE_LOGIN !== "true" && (
          <div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block font-medium text-gray-700"
              >
                <Translate name={"login.input.email"} />
              </label>
              <div className="mt-2">
                <input
                  disabled={
                    multiFactorAuthNeeded || registerMultiFactorAuthNeeded
                  }
                  onChange={handleEmailChange}
                  value={username}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  placeholder={i18n.t("login.input.email_placeholder")}
                  className={`block py-3 px-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 ${
                    multiFactorAuthNeeded || registerMultiFactorAuthNeeded
                      ? "bg-gray-200"
                      : ""
                  }`}
                />

                {userNameError && (
                  <ValidationError classList={"mt-1.5"}>
                    {userNameError}
                  </ValidationError>
                )}
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block font-medium text-gray-700"
              >
                <Translate name={"login.input.password"} />
              </label>
              <div className="mt-2">
                <input
                  disabled={
                    multiFactorAuthNeeded || registerMultiFactorAuthNeeded
                  }
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  required
                  placeholder={i18n.t("login.input.password_placeholder")}
                  className={`block py-3 px-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 ${
                    multiFactorAuthNeeded || registerMultiFactorAuthNeeded
                      ? "bg-gray-200"
                      : ""
                  }`}
                />
              </div>
            </div>
          </div>
        )}

        {!(multiFactorAuthNeeded || registerMultiFactorAuthNeeded) &&
          env.REACT_APP_DISABLE_NON_AZURE_LOGIN !== "true" && (
            <div>
              <button
                type="submit"
                className="p-4 mb-4 w-full text-center text-white bg-purple-700 rounded cursor-pointer"
              >
                <Translate name={"login.button_login"} />
              </button>
            </div>
          )}

        {invalidLogin && (
          <div className="text-red-500 mb-4">{invalidLoginMessage}</div>
        )}

        {!loggedinPasswordless && registerMultiFactorAuthNeeded && (
          <EnrollPhoneMFA email={username} password={password} />
        )}
        {multiFactorAuthNeeded && (
          <div>
            <div className="flex items-end justify-end text-sm text-center text-purple-700 underline">
              <a href="#" onClick={resetLogin}>
                <Translate name={"login.login_switch_account"} />
              </a>
            </div>
            <div className="mb-4">
              <label className="block font-medium text-gray-700">
                <Translate name={"login.input.verification_code"} />
              </label>
              <div className="mt-2">
                <input
                  onChange={(e) => setVerificationCode(e.target.value)}
                  value={verificationCode}
                  id="verification_code"
                  name="verification_code"
                  required
                  placeholder={i18n.t("login.input.verification_code")}
                  className="block py-3 px-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800"
                />
              </div>
            </div>

            {invalidVerification && (
              <div className="text-red-500 mb-4">{verificationError}</div>
            )}
            {env.REACT_APP_DISABLE_NON_AZURE_LOGIN !== "true" && (
              <Button
                id="multi-factor-button"
                onClick={handleVerificationCodeSubmit}
                className="p-4 mb-4 w-full text-center text-white bg-purple-700 rounded cursor-pointer"
              >
                <Translate name={"login.button_login"} />
              </Button>
            )}
          </div>
        )}

        {/* Forgot Password */}
        {env.REACT_APP_DISABLE_NON_AZURE_LOGIN !== "true" && (
          <div className="p-4 mb-4 w-full text-sm text-center text-purple-700 underline">
            <NavLink to="/forgot-password">
              <Translate name={"login.button_forgot_password"} />
            </NavLink>
          </div>
        )}
        {/* Azure Login */}
        <div
          onClick={() => handleAzureLogin()}
          className="p-4 w-full text-sm text-center text-purple-700 underline bg-purple-300 border border-purple-700 cursor-pointer"
        >
          <Translate name={"login.button_login_azure"} />
        </div>
        {env.REACT_APP_DISABLE_NON_AZURE_LOGIN !== "true" && (
          <div>
            <div
              onClick={() => openPasswordlessModal()}
              className="mt-4 p-4 w-full text-sm text-center text-purple-700 underline bg-purple-300 border border-purple-700 cursor-pointer"
            >
              <Translate name={"login.passwordless.button_open"} />
            </div>
            <Modal
              isOpen={showPasswordlessModal}
              setIsOpen={setShowPasswordlessModal}
              allowCloseOnBody={true}
              maxWidth="sm:mx-auto sm:w-full sm:max-w-lg"
            >
              <Modal.Header>
                <div className="text-2xl text-gray-800 font-semibold">
                  <Translate name={"login.paswordless.title"} />
                </div>
              </Modal.Header>
              <Modal.Body>
                {!loggedinPasswordless && (
                  <>
                    <input
                      disabled={
                        multiFactorAuthNeeded || registerMultiFactorAuthNeeded
                      }
                      onChange={handleEmailChange}
                      value={username}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      placeholder={i18n.t("login.input.email_placeholder")}
                      className={`block py-3 px-4 w-full placeholder-gray-400 rounded border border-gray-300 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 ${
                        multiFactorAuthNeeded || registerMultiFactorAuthNeeded
                          ? "bg-gray-200"
                          : ""
                      }`}
                    />
                    {userNameError && (
                      <ValidationError classList={"mt-1.5"}>
                        {userNameError}
                      </ValidationError>
                    )}
                    <div>
                      <button
                        type="submit"
                        className="mt-4 p-4 mb-4 w-full text-center text-white bg-purple-700 rounded cursor-pointer"
                        onClick={handlePasswordlessLogin}
                      >
                        <Translate name={"login.passwordless.button_submit"} />
                      </button>
                    </div>
                  </>
                )}
                {loggedinPasswordless && (
                  <>
                    <Translate name={"login.passwordless.success_text"} />
                    <button
                      type="submit"
                      className="mt-4 p-4 mb-4 w-full text-center text-white bg-purple-700 rounded cursor-pointer"
                      onClick={() => setLoggedinPasswordless(false)}
                    >
                      <Translate name={"login.passwordless.retry"} />
                    </button>
                  </>
                )}
              </Modal.Body>
            </Modal>
          </div>
        )}
      </form>
    </div>
  );
};

export default Login;
