import React from "react";
import {CustomerDataOptions} from "../CustomerDetail";
import DataList from "../../../components/DataList";
import Heading, {Level} from "../../../components/Typography/Heading";
import i18n from "../../../plugins/i18n";
import {formatDate} from "../../../utils/formatDate";
import Button, {Variant} from "../../../components/Button";

interface NewClaimModalPersonalInformationTabProps {
    customer: CustomerDataOptions;
    iban: string;
    setIsOpen: any;
    openUserEditModal: () => void;
    openPaymentEditModal: () => void;
}

const NewClaimModalPersonalInformationTab: React.FC<NewClaimModalPersonalInformationTabProps> = (
    {
        customer,
        iban,
        setIsOpen,
        openUserEditModal,
        openPaymentEditModal,
    }
) => {
    return (
        <div className="space-y-6">
            <div className="space-y-4">
                <Heading tag="h2" level={Level.HEADING_3}>
                    {i18n.t("detail.customer.header")}
                </Heading>

                <DataList data={[
                    {
                        label: "detail.customer.initials",
                        value: customer.initials,
                        key: "initials",
                    },
                    {
                        label: "detail.customer.first_name",
                        value: customer.first_name || "-",
                        key: "first_name",
                    },
                    {
                        label: "detail.customer.middle_name",
                        value: customer.middle_name || "",
                        key: "middle_name",
                    },
                    {
                        label: "detail.customer.last_name",
                        value: customer.surname,
                        key: "surname",
                    },
                    {
                        label: "detail.customer.birthdate",
                        value: formatDate(customer.birth_date),
                        key: "birth_date",
                    },
                    {
                        label: "detail.customer.phone",
                        value: customer.phone,
                        key: "phone",
                    },
                    {
                        label: "detail.customer.email",
                        value: customer.email,
                        key: "email",
                    },
                    {
                        label: "detail.customer.streetName",
                        value: customer.streetName,
                        key: "streetName",
                    },
                    {
                        label: "detail.customer.houseNumber",
                        value: customer.houseNo + ' ' + customer.houseNoAddition,
                        key: "houseNumber",
                    },
                    {
                        label: "detail.customer.postalCode",
                        value: customer.postCode,
                        key: "postalCode",
                    },
                    {
                        label: "detail.customer.city",
                        value: customer.city,
                        key: "city",
                    },
                ]}/>

                <div className="flex justify-end w-full">
                    <Button
                        onClick={() => {
                            setIsOpen(false)
                            setTimeout(() => {
                                openUserEditModal()
                            }, 500)
                        }}
                        variant={Variant.TRANSPARENT}
                    >
                        {i18n.t("detail.common.edit")}
                    </Button>
                </div>
            </div>

            <div className="space-y-4">
                <Heading tag="h2" level={Level.HEADING_3}>
                    {i18n.t("detail.payment.header")}
                </Heading>

                <DataList data={[
                    {
                        label: "detail.payment.iban",
                        value: iban,
                        key: "iban",
                    },
                ]}/>

                <div className="flex justify-end w-full">
                    <Button
                        onClick={async () => {
                            setIsOpen(false)
                            setTimeout(() => {
                                openPaymentEditModal()
                            }, 500)
                        }}
                        variant={Variant.TRANSPARENT}
                    >
                        {i18n.t("detail.payment.edit")}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NewClaimModalPersonalInformationTab;