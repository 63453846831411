import i18n from "../plugins/i18n";

export type ProblemArea = {
    code: string;
    text: string;
}

export type ClaimConfigurationCause = {
    code: string;
    text: string;
    problemArea: ProblemArea[]
}

export type ClaimConfigurationSubgroups = {
    code: string;
    text: string;
    theftCause: ClaimConfigurationCause[],
    damageCause: ClaimConfigurationCause[],
}

export type ObjectCategory = {
    name: string;
    subGroups: ClaimConfigurationSubgroups[],
    theftCause: ClaimConfigurationCause[],
    damageCause: ClaimConfigurationCause[]
}

export type ClaimConfiguration = {
    objectCategories: ObjectCategory[]
}


export enum ClaimType {
    DAMAGE = "1",
    THEFT = "2",
}

export const claimTypeOptions = [
    {
        value: ClaimType.DAMAGE,
        label: () => "DAMAGE",
    },
    {
        value: ClaimType.THEFT,
        label: "THEFT",
    },
];

export enum DamageType {
    FALL_DAMAGE = "VALSCHADE",
    MOISTURE_DAMAGE = "VOCHTSCHADE",
    TECHNICAL_DEFECT = "TECHNISCH DEFECT",
}

export const damageTypeOptions = [
    {
        value: DamageType.FALL_DAMAGE,
        label: "FALL DAMAGE",
    },
    {
        value: DamageType.MOISTURE_DAMAGE,
        label: "MOISTURE DAMAGE",
    },
    {
        value: DamageType.TECHNICAL_DEFECT,
        label: "TECHNICAL DEFECT",
    },
];

export enum AreaFallDamage {
    SCREEN = "SCHERM",
    CASE = "BEHUIZING",
}

export const areaFallDamageOptions = [
    {
        value: AreaFallDamage.SCREEN,
        label: i18n.t("service.claim.fall_damage_options.screen"),
    },
    {
        value: AreaFallDamage.CASE,
        label: i18n.t("service.claim.fall_damage_options.case"),
    },
];

export enum AreaMoistureDamage {
    MOISTURE = "VOCHTSCHADE",
}

export const areaMoistureDamageOptions = [
    {
        value: AreaMoistureDamage.MOISTURE,
        label: i18n.t("service.claim.moisture_damage_options.moisture"),
    },
];

export enum AreaTechnicalDefect {
    AUDIO = "AUDIO PROBLEEM",
    SOFTWARE = "SOFTWARE",
    CAMERA = "CAMERA PROBLEEM",
    SCREEN = "SCHERM PROBLEEM",
    CHARGING_PROBLEM = "LAAD PROBLEEM",
    STARTING_PROBLEM = "GAAT NIET AAN",
}

export const areaTechnicalDefectOptions = [
    {
        value: AreaTechnicalDefect.AUDIO,
        label: i18n.t("service.claim.moisture_damage_options.audio"),
    },
    {
        value: AreaTechnicalDefect.SOFTWARE,
        label: i18n.t("service.claim.moisture_damage_options.software"),
    },
    {
        value: AreaTechnicalDefect.CAMERA,
        label: i18n.t("service.claim.moisture_damage_options.camera"),
    },
    {
        value: AreaTechnicalDefect.SCREEN,
        label: i18n.t("service.claim.moisture_damage_options.screen"),
    },
    {
        value: AreaTechnicalDefect.CHARGING_PROBLEM,
        label: i18n.t("service.claim.moisture_damage_options.charging_problem"),
    },
    {
        value: AreaTechnicalDefect.STARTING_PROBLEM,
        label: i18n.t("service.claim.moisture_damage_options.starting_problem"),
    },
];

export enum TheftType {
    ROBBERY = "BEROVING",
    MISSING = "VERMISSING",
    BREAK_IN = "INBRAAK",
}

export const theftTypeOptions = [
    {
        value: TheftType.ROBBERY,
        label: i18n.t("service.claim.theft_type_options.robbery"),
    },
    {
        value: TheftType.MISSING,
        label: i18n.t("service.claim.theft_type_options.missing"),
    },
    {
        value: TheftType.BREAK_IN,
        label: i18n.t("service.claim.theft_type_options.break_in"),
    },
];

export enum ExplanationRobbery {
    VIOLENCE = "BEROVING MET GEWELD",
    NO_VIOLENCE = "BEROVING GEEN GEWELD",
}

export const explanationRobberyOptions = [
    {
        value: ExplanationRobbery.VIOLENCE,
        label: i18n.t("service.claim.robbery_options.violence"),
    },
    {
        value: ExplanationRobbery.NO_VIOLENCE,
        label: i18n.t("service.claim.robbery_options.no_violence"),
    },
];

export enum ExplanationMissing {
    LOSS = "VERLIES",
    PICKPOCKET = "ZAKKENROLLERIJ",
    OTHER = "ANDERS",
}

export const explanationMissingOptions = [
    {
        value: ExplanationMissing.LOSS,
        label: i18n.t("service.claim.missing_options.loss"),
    },
    {
        value: ExplanationMissing.PICKPOCKET,
        label: i18n.t("service.claim.missing_options.pickpocket"),
    },
    {
        value: ExplanationMissing.OTHER,
        label: i18n.t("service.claim.missing_options.other"),
    },
];

export enum ExplanationBreakIn {
    BUILDING = "UIT GEBOUW",
    VEHICLE = "UIT VOERTUIG",
    OTHER = "UIT OVERIG",
}

export const explanationBreakInOptions = [
    {
        value: ExplanationBreakIn.BUILDING,
        label: i18n.t("service.claim.break_in_options.building"),
    },
    {
        value: ExplanationBreakIn.VEHICLE,
        label: i18n.t("service.claim.break_in_options.vehicle"),
    },
    {
        value: ExplanationBreakIn.OTHER,
        label: i18n.t("service.claim.break_in_options.other"),
    },
];

export enum DamageQuestions {
    QUESTION_ONE = "Who caused the damage?*",
    QUESTION_TWO = "What is the cause of the damage?*",
    QUESTION_THREE = "Where is the damage caused?*",
    QUESTION_FOUR = "When was the damage discovered?*",
    QUESTION_FIVE = "How did the damage happen?*",
    QUESTION_SIX = "Has the product been repaired for the same defect in the past 3 months?*",
}

export enum TheftQuestions {
    QUESTION_ONE = "When has the theft taken place?*",
    QUESTION_TWO = "Where has the theft taken place?*",
    QUESTION_THREE = "What was the cause of the theft?*",
    QUESTION_FOUR = "What does the perpetrator look like?",
    QUESTION_FIVE = "Who are the witnesses and are they available?",
}
