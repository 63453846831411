export const env = {
  REACT_APP_FIREBASE_API_KEY:
    process.env.REACT_APP_FIREBASE_API_KEY || "__REACT_APP_FIREBASE_API_KEY__",
  REACT_APP_FIREBASE_DOMAIN:
    process.env.REACT_APP_FIREBASE_DOMAIN || "__REACT_APP_FIREBASE_DOMAIN__",
  REACT_APP_MIDDLEWARE_BASE_URL:
    process.env.REACT_APP_MIDDLEWARE_BASE_URL ||
    "__REACT_APP_MIDDLEWARE_BASE_URL__",
  REACT_APP_ORGANISATION_SERVICE_URL:
    process.env.REACT_APP_ORGANISATION_SERVICE_URL ||
    "__REACT_APP_ORGANISATION_SERVICE_URL__",
  REACT_APP_CONTRACT_SERVICE_URL:
    process.env.REACT_APP_CONTRACT_SERVICE_URL ||
    "__REACT_APP_CONTRACT_SERVICE_URL__",
  REACT_APP_ROUTING_SERVICE_URL:
    process.env.REACT_APP_ROUTING_SERVICE_URL ||
    "__REACT_APP_ROUTING_SERVICE_URL__",
  REACT_APP_ADDRESS_SERVICE_BASE_URL:
    process.env.REACT_APP_ADDRESS_SERVICE_BASE_URL ||
    "__REACT_APP_ADDRESS_SERVICE_BASE_URL__",
  REACT_APP_DISABLE_CAPTCHA:
    process.env.REACT_APP_DISABLE_CAPTCHA || "__REACT_APP_DISABLE_CAPTCHA__",
  REACT_APP_MICROSOFT_PROVIDER_TENANT:
    process.env.REACT_APP_MICROSOFT_PROVIDER_TENANT ||
    "__REACT_APP_MICROSOFT_PROVIDER_TENANT__",
  REACT_APP_DISABLE_NON_AZURE_LOGIN:
    process.env.REACT_APP_DISABLE_NON_AZURE_LOGIN ||
    "__REACT_APP_DISABLE_NON_AZURE_LOGIN__",
};
