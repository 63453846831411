import React, { createContext, useEffect, useState } from "react";
import auth from "../plugins/firebase";
import firebase from "firebase";
import microsoftProvider from "../plugins/microsoftProvider";

interface IUserContextProps {
  user: firebase.User | null;
  hasEnrolled: boolean | null;
  registerUser: firebase.User | null;
  userClaims: { [key: string]: any } | null;
  isLoaded: boolean;
  isValidLogin: boolean | null;
  doLogin: (username: string, password: string) => Promise<any | null>;
  getLoginPhoneVerificationId: (
    resolver: firebase.auth.MultiFactorResolver,
    verifier: firebase.auth.RecaptchaVerifier | null
  ) => Promise<string>;
  getRegisterPhoneVerificationId: (
    username: string,
    password: string,
    phoneNumber: string,
    verifier: firebase.auth.RecaptchaVerifier | null
  ) => Promise<string>;
  finishMultiFactorLogin: (
    verificationId: string,
    verificationCode: string,
    resolver: firebase.auth.MultiFactorResolver
  ) => Promise<boolean>;
  doAzureLogin: () => Promise<boolean>;
  doPasswordlessLogin: (email: string) => Promise<boolean>;
  isSignInWithEmailLink: () => boolean;
  doSignInWithEmailLink: (email: string) => Promise<boolean>;
  doLogout: () => Promise<void>;
  loginAndLinkWithOobCode: (link: string) => Promise<boolean>;
  forceLogin: (userAuth: firebase.User) => Promise<void>;
  authStateChange: () => Promise<void>;
}

export const UserContext = createContext({} as IUserContextProps);

const UserProvider: React.FunctionComponent = (props) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [registerUser, setRegisterUser] = useState<firebase.User | null>(null);
  const [userClaims, setUserClaims] = useState<{ [key: string]: any } | null>(
    null
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValidLogin, setIsValidLogin] = useState<boolean | null>(null);
  const [isLogin, setIsLogin] = useState<boolean | null>(null);
  const [isRegister, setIsRegister] = useState<boolean | null>(null);
  const [hasEnrolled, setHasEnrolled] = useState<boolean | null>(null);
  const [fireBaseUserAuth, setFireBaseUserAuth] =
    useState<firebase.User | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      setFireBaseUserAuth(userAuth);
    });
  }, []);

  useEffect(() => {
    authStateChange().then();
  }, [fireBaseUserAuth, isLogin]);

  useEffect(() => {
    if (isRegister) {
      setRegisterUser(fireBaseUserAuth);
    }
  }, [isRegister]);

  const needsMFAAuth = (user: firebase.User): boolean => {
    if (window.localStorage.getItem("passwordLessLogin") === "true") {
      return false;
    }
    return (
      user.providerData.findIndex((p) => p?.providerId === "microsoft.com") ===
      -1
    );
  };

  const authStateChange = async () => {
    setIsValidLogin(null);
    if (userClaims == null && fireBaseUserAuth !== null) {
      if (isRegister) {
        setRegisterUser(fireBaseUserAuth);
      } else if (fireBaseUserAuth === null) {
        window.localStorage.removeItem("passwordLessLogin");
        setUser(null);
      } else {
        auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
          setUserClaims(idTokenResult.claims);
        });
        // middleware.get(`service/user/${userAuth?.uid}/active`).then(response => {
        //   if (response.data["active"]) {
        if (
          !needsMFAAuth(fireBaseUserAuth) ||
          fireBaseUserAuth.multiFactor.enrolledFactors.length
        ) {
          setHasEnrolled(true);
          setIsValidLogin(true);
          setUser(fireBaseUserAuth);
          auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
            setUserClaims(idTokenResult.claims);
          });
        } else {
          window.localStorage.removeItem("passwordLessLogin");
          setHasEnrolled(false);
        }
        //
        // } else {
        //   auth.signOut().then(() => {
        //     setUserClaims(null)
        //     setUser(null)
        //     setIsValidLogin(false)
        //   })
        // }
        // })
      }
    }

    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  };

  const forceLogin = async (userAuth: firebase.User) => {
    setHasEnrolled(true);
    setIsValidLogin(true);
    setUser(userAuth);
    userAuth.getIdTokenResult().then((idTokenResult) => {
      setUserClaims(idTokenResult.claims);
    });
  };

  const doLogin = async (
    username: string,
    password: string
  ): Promise<any | null> => {
    try {
      setIsLogin(true);
      await auth.signInWithEmailAndPassword(username, password);
    } catch (e) {
      return e;
    }
    return null;
  };

  const getLoginPhoneVerificationId = async (
    resolver: firebase.auth.MultiFactorResolver,
    verifier: firebase.auth.RecaptchaVerifier | null
  ): Promise<string> => {
    if (!verifier) {
      return "";
    }

    try {
      if (
        resolver.hints[0].factorId ===
        firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID
      ) {
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };

        return await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          verifier
        );
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  };

  const getRegisterPhoneVerificationId = async (
    username: string,
    password: string,
    phoneNumber: string,
    verifier: firebase.auth.RecaptchaVerifier | null
  ): Promise<string> => {
    if (!verifier) {
      return "";
    }

    try {
      setIsRegister(true);
      const userCred = await auth.signInWithEmailAndPassword(
        username,
        password
      );
      const multiFactorSession = await userCred.user?.multiFactor.getSession();

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession,
      };

      return await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        verifier
      );
    } catch (e) {
      setIsRegister(false);
      return "";
    }
  };

  const finishMultiFactorLogin = async (
    verificationId: string,
    verificationCode: string,
    resolver: firebase.auth.MultiFactorResolver
  ): Promise<boolean> => {
    if (!resolver) {
      return false;
    }

    try {
      const cred = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

      const response = await resolver.resolveSignIn(multiFactorAssertion);
      setUser(response.user);
      setHasEnrolled(true);
      setIsValidLogin(true);
      response.user?.getIdTokenResult().then((idTokenResult) => {
        setUserClaims(idTokenResult.claims);
      });
    } catch (e) {
      return false;
    }

    return true;
  };

  const doAzureLogin = async (): Promise<boolean> => {
    try {
      const userCredential = await auth.signInWithPopup(microsoftProvider);
      setUser(userCredential.user);
      setHasEnrolled(true);
      setIsValidLogin(true);
    } catch (e: any) {
      let email = e.email;
      if (email === "notive@harmony.nl") {
        email = "rocco.langeweg+harmonyorg3@notive.nl";
      }
      if (e.code === "auth/account-exists-with-different-credential") {
        localStorage.setItem("azureEmail", email);
        localStorage.setItem(
          "azureCredential",
          JSON.stringify(e.credential.toJSON())
        );
        await auth.sendSignInLinkToEmail(email, {
          url: `${window.location.origin}/link`,
          handleCodeInApp: true,
        });
      }
      return false;
    }
    return true;
  };

  const doPasswordlessLogin = async (email: string): Promise<boolean> => {
    try {
      // Check if the email address exists in the user database
      const providers = await auth.fetchSignInMethodsForEmail(email);
      if (providers.length === 0) {
        return false;
      }
      window.localStorage.setItem("emailForSignIn", email);
      await auth.sendSignInLinkToEmail(email, {
        url: `${window.location.origin}/login`,
        handleCodeInApp: true,
      });
    } catch (e) {
      return false;
    }
    return true;
  };

  const isSignInWithEmailLink = (): boolean => {
    return auth.isSignInWithEmailLink(window.location.href);
  };

  const doSignInWithEmailLink = async (email: string): Promise<boolean> => {
    if (!auth.isSignInWithEmailLink(window.location.href)) {
      return false;
    }

    try {
      // Check if the email address exists in the user database
      const providers = await auth.fetchSignInMethodsForEmail(email);
      if (providers.length === 0) {
        return false;
      }

      const response = await auth.signInWithEmailLink(
        email,
        window.location.href
      );

      if (!response.user) {
        return false;
      }

      forceLogin(response.user);
      window.localStorage.setItem("passwordLessLogin", "true");
    } catch (e) {
      console.log(e);
      return false;
    }
    return true;
  };

  const loginAndLinkWithOobCode = async (link: string) => {
    const email = localStorage.getItem("azureEmail");
    if (!email) {
      return false;
    }
    try {
      await auth.signInWithEmailLink(email, link);
    } catch (e) {
      console.error(e);
      return false;
    }

    const credentialString = localStorage.getItem("azureCredential");
    if (!credentialString) {
      console.log("no credential string");
      return false;
    }

    const credential = firebase.auth.AuthCredential.fromJSON(credentialString);
    if (!credential) {
      console.log("no credential found");
      return false;
    }
    try {
      auth.currentUser?.linkWithCredential(credential);
    } catch (e) {
      console.log(e);
      return false;
    }
    console.log(auth.currentUser);
    return true;
  };

  const doLogout = async () => {
    await auth.signOut();
    setHasEnrolled(null);
    setUserClaims(null);
    setUser(null);
    setIsValidLogin(null);
    window.localStorage.removeItem("passwordLessLogin");
  };

  return (
    <UserContext.Provider
      value={{
        user,
        registerUser,
        userClaims,
        isLoaded,
        isValidLogin,
        hasEnrolled,
        doLogin,
        getLoginPhoneVerificationId,
        getRegisterPhoneVerificationId,
        finishMultiFactorLogin,
        doAzureLogin,
        doPasswordlessLogin,
        isSignInWithEmailLink,
        doSignInWithEmailLink,
        doLogout,
        loginAndLinkWithOobCode,
        forceLogin,
        authStateChange,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export default UserProvider;
