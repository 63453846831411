import React from "react";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";
import Button, { Variant } from "../../components/Button";
import Select from "../../components/Form/Select";
import Textarea from "../../components/Form/Textarea";
import { middleware } from "../../plugins/axios";
import { ContractDataOptions } from "./CustomerDetail";
import ContractBanner from "../../components/ContractBanner";
import DataList from "../../components/DataList";
import Checkbox from "../../components/Form/Checkbox";
import { toastError, toastSuccess } from "../../plugins/toast";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

interface contractDeleteRequestData {
  closeCode: string;
  requestVia: string;
  reasonCode: string;
  description: string;
  organisation: string;
  organisationEmail: string;
}

type ContractDeleteModalProps = {
  isOpen: boolean;
  setIsOpen: any;
  organisationEmail: string;
  organisation: any;
  contractToDelete: ContractDataOptions;
  customerId: string;
};

type ContractDeleteModalState = {
  step: Number;
  lastStep: Number;
  description: string;
  loadingReasonCodes: boolean;
  reasonCodesEnabled: boolean;
  loadingCloseCodes: boolean;
  closeCodesEnabled: boolean;
  hasConfirmed: boolean;
  selectedReasonCode: {
    label: string;
    value: any;
  };
  reasonCodeOptions: Array<{
    value: any;
    label: string;
  }>;
  selectedCloseCode: {
    label: string;
    value: any;
  };
  closeCodeOptions: Array<{
    value: any;
    label: string;
  }>;
  selectedRequestVia: {
    label: string;
    value: any;
  };
  requestViaOptions: Array<{
    value: any;
    label: string;
  }>;
};

const Step0 = ({ contract }: any) => (
  <div>
    <div className="text-lg py-3">
      <Translate name={"detail.cancel.step_0.title"} />
    </div>
    <ContractBanner
      category={contract.category}
      itemDescription={contract.item_description}
      contractDescription={contract.contract_description}
    />
    <div className="block w-100 h-8" />
    <Alert heading="" translationKey="detail.cancel.step_0.alert_heading">
      <p>
        <Translate name={"detail.cancel.step_0.alert_message"} />
      </p>
    </Alert>
  </div>
);

const Step1 = ({
  selectedReasonCode,
  setSelectedReasonCode,
  reasonCodeOptions,
  selectedCloseCode,
  setSelectedCloseCode,
  closeCodeOptions,
  description,
  setDescription,
  contract,
  loadingReasonCodes,
  reasonCodesEnabled,
  loadingCloseCodes,
  closeCodesEnabled,
  requestViaOptions,
  setSelectedRequestVia,
  selectedRequestVia,
  hasConfirmed,
  setHasConfirmed,
}: any) => (
  <div>
    <ContractBanner
      category={contract.category}
      itemDescription={contract.item_description}
      contractDescription={contract.contract_description}
    />
    <div className="text-purple-700 text-lg mt-8 mb-3">
      <Translate name={"detail.cancel.step_1.title"} />
    </div>
    <hr className="mb-3 border-gray-400" />
    <div className="space-y-6">
      <Select
        className="mt-3"
        label=""
        translationKey="detail.cancel.step_1.close_code_label"
        selected={selectedCloseCode}
        setSelected={setSelectedCloseCode}
        disabled={!closeCodesEnabled}
        loading={loadingCloseCodes}
        id="closeCode"
        name="closeCode"
        options={closeCodeOptions}
      />
      <Select
        label=""
        translationKey="detail.cancel.step_1.reason_code_label"
        selected={selectedReasonCode}
        setSelected={setSelectedReasonCode}
        disabled={!reasonCodesEnabled}
        loading={loadingReasonCodes}
        id="reasonCode"
        name="reasonCode"
        options={reasonCodeOptions}
      />
      <Select
        label=""
        translationKey="detail.cancel.step_1.closed_via_label"
        selected={selectedRequestVia}
        setSelected={setSelectedRequestVia}
        disabled={!reasonCodesEnabled}
        loading={loadingReasonCodes}
        id="requestVia"
        name="requestVia"
        options={requestViaOptions}
      />
      <Textarea
        className="pt-3"
        label=""
        translationKey="detail.cancel.step_1.explanation_label"
        value={description}
        onChange={setDescription}
        limit={249}
      />
    </div>
    <div className="mt-4">
      <Checkbox
        onChange={setHasConfirmed}
        checked={hasConfirmed}
        label=""
        translationKey="detail.cancel.step_1.customer_confirmation_label"
      />
    </div>
  </div>
);

export class ContractDeleteModal extends React.Component<
  ContractDeleteModalProps,
  ContractDeleteModalState
> {
  state = {
    step: 0,
    lastStep: 1,
    description: "",
    hasConfirmed: false,
    selectedReasonCode: {
      value: "default",
      label: "",
      description: "",
    },
    selectedCloseCode: {
      value: "default",
      label: "",
    },
    reasonCodeOptions: [],
    closeCodeOptions: [],
    requestViaOptions: [
      {
        value: "3",
        label: i18n.t(`detail.common.request_via_code.phone`),
      },
      {
        value: "1",
        label: i18n.t(`detail.common.request_via_code.email`),
      },
      {
        value: "2",
        label: i18n.t(`detail.common.request_via_code.letter`),
      },
    ],
    loadingReasonCodes: true,
    reasonCodesEnabled: false,
    loadingCloseCodes: true,
    closeCodesEnabled: false,
    selectedRequestVia: {
      value: "default",
      label: "",
    },
  };

  async componentDidMount() {
    middleware
      .get(
        `contracts/service/${this.props.customerId}/${this.props.contractToDelete.contract_no}/close_codes/`
      )
      .then((res) => {
        if (res.data) {
          var closeCodes = res.data.map((el: any) => {
            return {
              value: el.closeCode,
              label: i18n.t(
                `service.detail.common.close_codes.${el.closeCode
                  .toLowerCase()
                  .replace(" ", "_")}`
              ),
            };
          });
          this.setState({
            closeCodeOptions: closeCodes,
            closeCodesEnabled: true,
            loadingCloseCodes: false,
          });
        }
      });
  }

  getClosingReasons(e: { label: string; value: any }) {
    this.setState({
      loadingReasonCodes: true,
      reasonCodesEnabled: false,
    });
    middleware
      .get(`contracts/close_reason/${this.props.customerId}/${e.value}`)
      .then((res) => {
        if (res.data) {
          var reasonCodes = res.data.value.map((el: any) => {
            return {
              label: i18n.t(
                `service.detail.common.reason_codes.${el.reasonCode
                  .toLowerCase()
                  .replace(" ", "_")}`
              ),
              value: el.reasonCode,
              description: el.description,
            };
          });
          this.setState({
            reasonCodeOptions: reasonCodes,
            reasonCodesEnabled: true,
            loadingReasonCodes: false,
          });
        }
      });
  }

  nextStep() {
    this.setState({
      step: this.state.step + 1,
    });
  }

  changeReasonCode(e: any) {
    this.setState({
      selectedReasonCode: e,
    });
  }

  changeRequestVia(e: any) {
    this.setState({
      selectedRequestVia: e,
    });
  }

  changeCloseCode(e: { value: any; label: string }) {
    this.setState({
      selectedCloseCode: e,
    });
    this.getClosingReasons(e);
  }

  changeDescription(e: any) {
    this.setState({
      description: e.target.value,
    });
  }

  setPreviousStep() {
    if (this.state.step <= 0) return;
    this.setState({
      step: this.state.step - 1,
    });
  }

  setHasConfirmed(e: any) {
    this.setState({
      hasConfirmed: e.target.checked,
    });
  }

  closeModal() {
    this.setState({
      step: 0,
    });
    this.props.setIsOpen(false, true);
  }

  sendCloseContractRequest() {
    var contractId: string = this.props.contractToDelete.contract_id;
    var data: contractDeleteRequestData = {
      closeCode: this.state.selectedCloseCode.value,
      reasonCode: this.state.selectedReasonCode.value,
      requestVia: this.state.selectedRequestVia.value,
      description: this.state.description,
      organisation: this.props.organisation.name,
      organisationEmail: this.props.organisationEmail,
    };
    middleware
      .post(
        `contracts/service/cancel/${this.props.customerId}/${contractId}`,
        data
      )
      .then((res) => {
        toastSuccess(i18n.t("detail.cancel.submit_success"));
        this.closeModal();
      })
      .catch((e) => {
        toastError(i18n.t("detail.cancel.submit_error"), e.message);
        console.error(e);
      });
  }

  getDatalist() {
    return [
      {
        label: "detail.cancel.contract_description",
        value: this.props.contractToDelete.contract_description,
      },
      {
        label: "detail.cancel.item_description",
        value: this.props.contractToDelete.item_description,
      },
      {
        label: "detail.cancel.reason_code",
        value: this.state.selectedReasonCode.label,
      },
      {
        label: "detail.cancel.close_code",
        value: this.state.selectedCloseCode.label,
      },
      {
        label: "detail.cancel.explanation",
        value: this.state.description,
      },
    ];
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} setIsOpen={() => this.closeModal()}>
        <Modal.Header>
          <div className="text-2xl text-gray-800 font-semibold">
            <Translate name={"detail.cancel.title"} />
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.state.step === 0 ? (
            <Step0 contract={this.props.contractToDelete} />
          ) : null}
          {this.state.step === 1 ? (
            <Step1
              contract={this.props.contractToDelete}
              reasonCodeOptions={this.state.reasonCodeOptions}
              reasonCodesEnabled={this.state.reasonCodesEnabled}
              reasonCodesLoading={this.state.loadingReasonCodes}
              selectedReasonCode={this.state.selectedReasonCode}
              setSelectedReasonCode={(e: any) => this.changeReasonCode(e)}
              setSelectedRequestVia={(e: any) => this.changeRequestVia(e)}
              selectedRequestVia={this.state.selectedRequestVia}
              closeCodeOptions={this.state.closeCodeOptions}
              requestViaOptions={this.state.requestViaOptions}
              closeCodesEnabled={this.state.closeCodesEnabled}
              closeCodesLoading={this.state.loadingCloseCodes}
              selectedCloseCode={this.state.selectedCloseCode}
              setSelectedCloseCode={(e: any) => this.changeCloseCode(e)}
              description={this.state.description}
              setDescription={(e: any) => this.changeDescription(e)}
              hasConfirmed={this.state.hasConfirmed}
              setHasConfirmed={(e: any) => this.setHasConfirmed(e)}
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button
              onClick={() => this.closeModal()}
              variant={Variant.TRANSPARENT}
            >
              <Translate name={"detail.common.back_to_detail"} />
            </Button>
          </div>
          {this.state.step > 0 ? (
            <div>
              <Button
                onClick={() => this.setPreviousStep()}
                variant={Variant.LIGHT_PURPLE}
              >
                <Translate name={"detail.common.previous"} />
              </Button>
            </div>
          ) : null}
          <div>
            {this.state.step < this.state.lastStep ? (
              <Button onClick={() => this.nextStep()}>
                <Translate name={"detail.common.next"} />
              </Button>
            ) : (
              <Button
                disabled={!this.state.hasConfirmed}
                onClick={() => this.sendCloseContractRequest()}
              >
                <Translate name={"detail.common.complete"} />
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
