import React from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { CustomerDataOptions, LedgerEntries } from "./CustomerDetail";
import Table from "../../components/Table";
import {createFile, downloadFile, openFileInTab} from "../../plugins/fileSaver";
import { middleware } from "../../plugins/axios";
import {formatDate} from "../../utils/formatDate";
import {toastSuccess} from "../../plugins/toast";
import Icon from "../../components/Icon";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

type LedgerEntriesModalProps = {
  customer: CustomerDataOptions;
  ledgerEntries: LedgerEntries[];
  isOpen: boolean;
  setIsOpen: any;
  customerId: string;
};
export const setReminderStatusTranslation = (status: string) => {
  switch (status.toLowerCase()) {
    case "collection service":
      return i18n.t("detail.payment.reminder_collection_service");
    case "to close":
      return i18n.t("detail.payment.to_close_service");
    case "grace period":
      return i18n.t("detail.payment.grace_period_service");
    default:
      return "-";
  }
}

const LedgerEntriesModal: React.FC<LedgerEntriesModalProps> = (props) => {
  const downloadInvoice = (ledgerEntryID: string) => {
    middleware
      .get(`payments/service/${props.customer.id}/${ledgerEntryID}/download`)
      .then((response) => {
        openFileInTab(response.data.dataUri);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const mailInvoice = (ledgerEntryID: string) => {
    middleware
        .get(`payments/service/${props.customer.id}/${ledgerEntryID}/mail`)
        .then((response) => {
          toastSuccess(i18n.t("detail.invoices.send_mail_success"));
        })
        .catch((e) => {
          console.error(e);
        });
  };

  const getCellLabel = (label: string) => {
    if (!label) return "";
    
    const isDate = label.toString().includes("-") && Date.parse(label);

    if (isDate) {
      if (formatDate(label) === "01-01-0001") {
        return "";
      }
      return formatDate(label);
    }

    return label;
  };
  const setCollectionServiceStatusTranslation = (status: string) => {
    switch (status) {
      case "Closed":
        return i18n.t("payments.collection_status.closed");
      case "Exported":
        return i18n.t("payments.collection_status.exported");
      case "Cancelled":
        return i18n.t("payments.collection_status.cancelled");
      default:
        return "-";
    }
  }


  const columns = [
    {
      label: i18n.t("detail.invoices.contract_no"),
      sortable: true,
    },
    {
      label: i18n.t("detail.invoices.description"),
      sortable: true,
    },
    {
      label: i18n.t("detail.invoices.invoicing_period"),
      sortable: false,
    },
    { label: i18n.t("detail.invoices.is_open"), sortable: true },
    { label: i18n.t("detail.invoices.amount"), sortable: true },
    {
      label: i18n.t("detail.invoices.payment_date"),
      sortable: true,
    },
    {
      label: i18n.t("detail.invoices.send_invoice_header"),
      sortable: false,
    },
    {
      label: i18n.t("detail.invoices.download_header"),
      sortable: false,
    },
  ];

  const cells = [
    { label: "contract_id" },
    { label: "description" },
    { label: "invoicing_period" },
    { label: "is_open" },
    { label: "amount" },
    { label: "payment_date" },
  ];

  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      maxWidth="max-w-8xl"
    >
      <Modal.Header>
        <div className="text-2xl text-purple font-semibold">Facturen</div>
      </Modal.Header>

      <Modal.Body>
        <Table data={[]}>
          <Table.Header>
            {columns.map((column, i) => {
              return (
                <Table.HeaderColumn
                  key={i}
                  label={column.label}
                  sortable={column.sortable}
                />
              );
            })}
          </Table.Header>
          <Table.Body>
            {props.ledgerEntries
              .sort((a, b) => {
                const dateA = new Date(a.posting_date);
                const dateB = new Date(b.posting_date);
                return dateB > dateA ? 1 : -1;
              })
              .map((row: any, index: any) => {
                return [
                  <Table.Row key={index} index={index}>
                    {cells.map((cell, i) => {
                      return <Table.Cell key={i} label={getCellLabel(row[cell.label])} />;
                    })}
                    <Table.Cell>
                      <button
                          className="text-purple flex flex-row items-center"
                          onClick={() => mailInvoice(row.document_no)}
                      >
                        <Icon name="mail" classList="mr-2.5 w-5" /> <Translate name={"detail.invoices.send_invoice"} />
                      </button>
                    </Table.Cell>
                    <Table.Cell>
                      <button
                        className="text-purple flex flex-row items-center"
                        onClick={() => downloadInvoice(row.document_no)}
                      >
                        <Icon name="pdf" classList="mr-2.5 w-5" /> <Translate name={"detail.invoices.download"} />
                      </button>
                    </Table.Cell>
                  </Table.Row>,
                  row["reversal_description"] !== "" && (<Table.Row key={`${index}-expanded`} index={index}>
                    <Table.ExpandedCell>
                      <div className="border-t border-gray-300">
                        <div className="flex flex-row py-3">
                          <div className="pr-12 font-bold text-purple w-48">
                            <Translate name={"detail.invoices.reversal_description"} />
                          </div>
                          <div className="col-span-2">
                            {row["reversal_description"]}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="pr-12 font-bold text-purple w-48">
                            <Translate name={"detail.invoices.collection_service_status"} />
                          </div>
                          <div className="col-span-2">
                            {setReminderStatusTranslation(row["reminder_status"])}
                          </div>
                        </div>
                      </div>
                    </Table.ExpandedCell>
                  </Table.Row>)
                ];
              })}
          </Table.Body>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <div className="ml-auto">
          <Button onClick={() => props.setIsOpen(false)}>
            <Translate name={"detail.common.close"} />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LedgerEntriesModal;
