import React from "react";
import Translate from "./Translate";

interface ITableProps {
  data: Array<any>;
}

interface IHeaderColumnProps {
  label: string;
  translationKey?: string;
  sortable: boolean;
}

interface IRowProps {
  index: number;
  onClick?: () => void;
}

interface ICellProps {
  label?: string;
  translationKey?: string;
}

const HeaderColumn: React.FC<IHeaderColumnProps> = (props) => {
  return (
    <th scope="col" className="px-6 py-3 text-left font-medium">
      {!props.translationKey ? props.label : <Translate name={props.translationKey} />}
      {/* {!props.sortable && <FontAwesomeIcon icon={"sort"} />} */}
    </th>
  );
};

const Header: React.FC = (props) => {
  return (
    <thead className="bg-purple-200 text-purple-700">
      <tr>{props.children}</tr>
    </thead>
  );
};

const Body: React.FC = (props) => {
  return <tbody>{props.children}</tbody>;
};

const Row: React.FC<IRowProps> = (props) => {
  return (
    <tr onClick={props.onClick} className={(props.index % 2 === 0 ? "bg-white" : "bg-gray-200") + (props.onClick ? " cursor-pointer" : "")}>
      {props.children}
    </tr>
  );
};

const Cell: React.FC<ICellProps> = (props) => {
  return (
    <td className="px-6 py-3 text-left">
      {props.children ? props.children : !props.translationKey ? props.label : <Translate name={props.translationKey} />}
    </td>
  );
};

const ExpandedCell: React.FC<ICellProps> = (props) => {
  return (
    <td className="px-6 py-3 " colSpan={8}>
      {props.children}
    </td>
  );
}

const Table: React.FC<ITableProps> = (props) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden rounded">
            <table className="min-w-full divide-y divide-gray-200 table-auto">
              {props.children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Object.assign(Table, { HeaderColumn, Header, Body, Row, Cell, ExpandedCell});
