import React from 'react';
import Translate from '../Translate';
interface CheckButtonProps {
  checked: boolean,
  label: string,
  translationKey?: string
  onClick: () => void
}
const CheckButton: React.FC<CheckButtonProps> = ({checked, label, translationKey, onClick}) => {
  return (
    <div onClick={()=>onClick()} className={`w-full py-3 my-1 flex justify-center rounded-md ${checked ? 'bg-purple-300' : 'bg-white'} border border-purple-700 text-purple-700`}>
      <div>{!translationKey ? label : <Translate name={translationKey} />}</div>
    </div>
  )

}

export default CheckButton;