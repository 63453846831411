import React from "react";
import Icon from "./Icon";

interface ContractBannerProps {
  itemDescription: string,
  contractDescription: string,
  category: string
}

const ContractBanner: React.FC<ContractBannerProps> = ({ itemDescription, contractDescription , category }) => {
  return (
    <div className="flex flex-row px-2 mb-4 rounded border border-gray-400 ">
     
        <Icon name={category}/>
    
      <div className="p-2">
          <div className="font-bold text-sm text-gray-800 py-2">{contractDescription}</div>
          <p className="text-sm text-gray-800 ">{itemDescription}</p>
      </div>
    </div>
  );
};

export default ContractBanner;
