import firebase from "firebase"
import { env } from "../env";

const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');

microsoftProvider.setCustomParameters({
  tenant: env.REACT_APP_MICROSOFT_PROVIDER_TENANT || 'harmonynl.onmicrosoft.com'
});

export default microsoftProvider;
