import { toast, ToastOptions } from 'react-toastify'

const toastConfig: ToastOptions = {
  position: "bottom-center",
  hideProgressBar: true,
  autoClose: 15000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const toastSuccess = (text: string) => {
  toast.success(text, toastConfig)
}

export const toastError = (text: string, statusCode?: string) => {
  toast.error(`${text}${statusCode ? ` (Statuscode: ${statusCode.replaceAll(/[^\d]./g, "")})` : ''}`, toastConfig)
}