import moment from "moment";

export function formatDate(date: string, format?: string): string {
  if (!format) format = "DD-MM-YYYY";
  return moment(date).format(format);
}

export function formatDateTime(date: string, format?: string): string {
  if (!format) format = "DD-MM-YYYY HH:MM:SS";
  return moment(date).format(format);
}
