import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Translate from "../../components/Translate";
import { middleware } from "../../plugins/axios";
import { toastError, toastSuccess } from "../../plugins/toast";
import Select from "../../components/Form/Select";
import i18n from "../../plugins/i18n";
import Input from "../../components/Form/Input";
import Button, { Variant } from "../../components/Button";
import Label from "../../components/Form/Label";
import { useValidationContext } from "../../providers/ValidationProvider";

interface CreateTaskModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  contractNumber: string;
  customer_id: string;
}

interface TaskTemplate {
  Code: string;
  Subject: string;
  Department: string;
  DepartmentDescription: string;
}

interface TaskOption {
  label: string;
  value: string;
}

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
  isOpen,
  setIsOpen,
  contractNumber,
  customer_id,
}) => {
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);

  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<TaskOption>({
    label: "",
    value: "",
  });
  const [taskBody, setTaskBody] = useState("");

  const [taskSubject, setTaskSubject] = useState("");

  const [canSubmitForm, setCanSubmitForm] = useState(true);
  const [displayErrors, setDisplayErrors] = useState(false);

  const validationContext = useValidationContext();

  useEffect(() => {
    if (!selectedTaskTemplate.value || !taskTemplates.length) return;

    const template = taskTemplates.find(
      (template) => template.Code === selectedTaskTemplate.value
    );

    console.log(template);

    setTaskSubject(template!.Subject);
  }, [selectedTaskTemplate]);

  useEffect(() => {
    if (!validateForm() && canSubmitForm) return;

    setCanSubmitForm(validateForm());
  }, [taskBody, selectedTaskTemplate, taskSubject]);

  const createTask = () => {
    const template = taskTemplates.find(
      (template) => template.Code === selectedTaskTemplate.value
    );

    if (!template) {
      toastError(i18n.t("detail.create_task.error"));
      return;
    }

    const payload = {
      templateCode: template.Code,
      subject: taskSubject,
      body: taskBody,
      contractNo: contractNumber,
      customerId: customer_id,
    };

    return middleware.post(`tasks/create`, payload);
  };

  const validateForm = () => {
    return (
      validationContext.validateNotEmptyString(selectedTaskTemplate.value) &&
      validationContext.validateNotEmptyString(taskBody) &&
      validationContext.validateNotEmptyString(taskSubject)
    );
  };
  const resetForm = () => {
    setSelectedTaskTemplate({
      label: "",
      value: "",
    });
    setTaskSubject("");
    setTaskBody("");
    setDisplayErrors(false);
    setCanSubmitForm(true);
  };
  const submitForm = () => {
    if (validateForm()) {
      createTask()
        ?.then(() => {
          resetForm();
          setIsOpen(false);
          toastSuccess(i18n.t("detail.create_task.success"));
        })
        .catch((e) => {
          // toastError(i18n.t("detail.create_task.error"));
          toastError(`"${e.response.data}" - Error code: ${e.response.status}`);
        });
    } else {
      setDisplayErrors(true);
      setCanSubmitForm(false);
    }
  };

  const fetchTaskTemplates = () => {
    // TODO: use useQuery for request
    setTemplatesLoading(true);

    const language = i18n.language;
    const languageMap: { [key: string]: string } = {
      en: "ENU",
      nl: "NLD",
      fr: "FRB",
      // Add other language mappings as needed
    };

    const language_code = languageMap[language] || "ENU"; // Default to "ENU" if language is not found
    
    console.log(language)
    console.log(language_code)

    middleware
      .get(`tasks/templates/${customer_id}?language_code=${language_code}`)
      .then((res) => {
        if (res.status === 200) {
          setTaskTemplates(res.data);
        }
        setTemplatesLoading(false);
      })
      .catch((e) => {
        setTemplatesLoading(false);
        toastError(i18n.t("detail.create_task.error"), e.message);
      });
  };
  useEffect(() => fetchTaskTemplates(), [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Modal.Header>
        <div className="text-2xl text-gray-800 font-semibold">
          <Translate name={"detail.contracts.new_task"} />
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className={"grid gap-y-5"}>
          <div>
            <Label id="template">
              <Translate name={"detail.create_task.task_label"} />
            </Label>
            <Select
              selected={selectedTaskTemplate}
              setSelected={setSelectedTaskTemplate}
              loading={templatesLoading}
              id="taskTemplates"
              name="taskTemplates"
              options={taskTemplates.map((template: TaskTemplate) => {
                return {
                  label: template.Subject,
                  value: template.Code,
                };
              })}
              label=""
              translationKey="detail.create_task.task_label"
              error={displayErrors && selectedTaskTemplate.value === ""}
            />
          </div>

          <Input
            label=""
            translationKey="detail.create_task.subject_label"
            value={taskSubject}
            onChange={(e) => setTaskSubject(e.target.value)}
            name="description"
            placeholder={""}
            error={displayErrors && taskSubject === ""}
          />

          <Input
            label=""
            translationKey="detail.create_task.body_label"
            value={taskBody}
            onChange={(e) => setTaskBody(e.target.value)}
            name="description"
            placeholder={""}
            error={displayErrors && taskBody === ""}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => {
            resetForm();
            setIsOpen(false);
          }}
          variant={Variant.TRANSPARENT}
        >
          <Translate name={"detail.common.back_to_detail"} />
        </Button>
        <Button
          disabled={!canSubmitForm}
          onClick={submitForm}
          classList={"ml-auto"}
        >
          <Translate name={"detail.create_task.submit_form"} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CreateTaskModal;
