import React, {useEffect, useState} from "react";
import {CustomerDataOptions} from "../../CustomerDetail";
import {isValidImei} from "../../../../utils/imei";
import Modal from "../../../../components/Modal";
import {contractService} from "../../../../plugins/axios";
import Select from "../../../../components/Form/Select";
import Input from "../../../../components/Form/Input";
import Button from "../../../../components/Button";
import {
  Template,
  TemplateComponents,
} from "../../../../plugins/contract-api-client/models";
import Icon from "../../../../components/Icon";
import {toastError, toastSuccess} from "../../../../plugins/toast";
import ReactSelect from 'react-select';
import {classNames} from "../../../../utils/classNames";
import Label from "../../../../components/Form/Label";

import {ContractResponse} from "../../../../plugins/contract-api-client/models/contract-response";
import Translate from "../../../../components/Translate";
import i18n from "../../../../plugins/i18n";


type contractModalProps = {
  isOpen: boolean;
  setIsOpen: any;
  organisation: any;
  organisationEmail: string;
  customer: CustomerDataOptions;
};

type SelectOption = {
  label: string;
  value: string;
};
type SelectSearchOption = {
  name: string;
  value: string;
};

const NewInsuranceModal: React.FC<contractModalProps> = ({
                                                           customer,
                                                           isOpen,
                                                           setIsOpen,
                                                           organisationEmail,
                                                         }) => {
  const [itemCategories, setItemCategories] = useState<SelectOption[]>([]);
  const [manufacturers, setManufacturers] = useState<SelectOption[]>([]);
  const [templates, setTemplates] = useState<SelectOption[]>([]);
  const [templatesData, setTemplatesData] = useState<Template[]>([]);

  const [formItemCategory, setFormItemCategory] = useState<string>("");
  const [validItemCategory, setValidItemCategory] = useState(false);
  const [formManufacturer, setFormManufacturer] = useState<string>("");
  const [validManufacturer, setValidManufacturer] = useState(false);
  const [formRetailPrice, setFormRetailPrice] = useState<number>(0);
  const [validRetailPrice, setValidRetailPrice] = useState(false);
  const [formTemplate, setFormTemplate] = useState<string>("");
  const [validTemplate, setValidTemplate] = useState(false);
  const [formItemNo, setFormItemNo] = useState<string>("");
  const [validItemNo, setValidItemNo] = useState(false);
  const [formModel, setFormModel] = useState<string>("");
  const [validModel, setValidModel] = useState(false);
  const [formPurchaseDate, setFormPurchaseDate] = useState<string>("");
  const [validPurchaseDate, setValidPurchaseDate] = useState(false);
  const [formSerialNo, setFormSerialNo] = useState<string>("");
  const [validSerialNo, setValidSerialNo] = useState(false);
  const [formImeiNo, setFormImeiNo] = useState<string>("");
  const [validImeiNo, setValidImeiNo] = useState(false);

  const [createResponse, setCreateResponse] = useState<ContractResponse | null>(null);
  const [canSubmitForm, setCanSubmitForm] = useState<boolean>(true);

  useEffect(() => {
    const handleEffect = async () => {
      const response = await contractService.getItemCategories(
        customer.template,
        1
      );

      const itemCategories = response.data.map((category) => {
        return {
          label: category.name,
          value: category.code,
        };
      });

      setItemCategories(itemCategories);
    };
    handleEffect().then();
  }, [customer.template]);

  useEffect(() => {
    const handleEffect = async () => {
      if (formItemCategory === "") {
        return;
      }
      const response = await contractService.getManufacturers(
        customer.template,
        1,
        formItemCategory,
        ""
      );
      const manufacturers = response.data.map((manufacturer) => {
        return {
          label: manufacturer.name,
          value: manufacturer.code,
        };
      });

      setManufacturers(manufacturers);
    };
    void handleEffect();
  }, [customer.template, formItemCategory]);

  useEffect(() => {
    const handleEffect = async () => {
      if (formManufacturer === "") {
        return;
      }
      const itemResponse = await contractService.getItems(
        customer.template,
        1,
        formItemCategory,
        formManufacturer,
        ""
      );

      if (!itemResponse.data.length) {
        return;
      }

      const item = itemResponse.data[0];
      setFormItemNo(item.code);
    };
    void handleEffect();
  }, [customer.template, formItemCategory, formManufacturer]);

  useEffect(() => {
    const handleEffect = async () => {
      if (formItemNo === "") {
        return;
      }
      const response = await contractService.getTemplates(
        customer.template,
        1,
        formItemNo,
        formRetailPrice
      );

      setTemplatesData(response.data);

      const templates = response.data.map((template) => {
        return {
          label: `${template.name} - €${template.monthly_amount}`,
          value: template.code,
        };
      });

      setTemplates(templates);
    };
    void handleEffect();
  }, [customer.template, formItemNo, formRetailPrice]);

  const validateForm = () => {
    let formIsValid = false;

    if (
      validItemCategory &&
      validManufacturer &&
      validModel &&
      validPurchaseDate &&
      validTemplate &&
      validRetailPrice
    ) {
      if (formItemCategory === "PHONE") {
        formIsValid = validImeiNo;
      } else {
        formIsValid = validSerialNo;
      }
    }

    if (!formIsValid) {
      toastError(i18n.t("detail.new_insurance.submit_failure"));
    } else {
      requestInsurance();
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    resetForm();
    setCreateResponse(null);
  }

  const requestInsurance = async () => {
    setCanSubmitForm(false);

    try {
      const response = await contractService.createContract({
        employee: organisationEmail,
        contract_amount: 1,
        customer: {
          address_line_1: `${customer.streetName}`.trim(),
          address_line_2:
            `${customer.houseNo} ${customer.houseNoAddition}`.trim(),
          city: customer.city,
          country_code: "NL",
          date_of_birth: customer.birth_date,
          email_address: customer.email,
          first_name: customer.first_name,
          gender: customer.gender,
          iban_code: customer.iban,
          initials: customer.initials,
          last_name: customer.surname,
          phone_number: customer.phoneNo,
          zip_code: customer.postCode,
        },
        device: {
          device_images: [],
          imei_no: formImeiNo,
          item_category_code: formItemCategory,
          item_no: formItemNo,
          manufacturer: formManufacturer,
          model: formModel,
          proof_of_purchase: "",
          purchase_date: formPurchaseDate,
          retail_price: formRetailPrice,
          serial_no: formSerialNo,
        },
        insurance: {
          start_date: new Date().toISOString().split("T")[0],
          template_code: formTemplate,
        },
        template: customer.template,
      });
      setCreateResponse(response.data);
      toastSuccess(
        i18n.t("detail.new_insurance.submit_success")
      );
      setCanSubmitForm(false);
      setIsOpen(false);
      resetForm();
    } catch (e) {
      toastError(i18n.t("detail.new_insurance.submit_failure"));
      setCanSubmitForm(true);
    }

    setTimeout(() => {
      setCanSubmitForm(true);
    }, 3000);
  };

  const resetForm = () => {
    setFormImeiNo("");
    setValidImeiNo(false);
    setFormSerialNo("");
    setValidSerialNo(false);
    setFormItemNo("");
    setValidItemNo(false);
    setFormModel("");
    setValidModel(false);
    setFormTemplate("");
    setValidTemplate(false);
    setFormPurchaseDate("");
    setValidPurchaseDate(false);
    setFormRetailPrice(0);
    setValidRetailPrice(false);
    setFormManufacturer("");
    setValidManufacturer(false);
    setFormItemCategory("");
    setValidItemCategory(false);
    setManufacturers([]);
    setTemplates([]);
    setTemplatesData([]);
  };

  let template = templatesData.find((t) => t.code === formTemplate);
  let components = {} as TemplateComponents;
  if (template && template.components) {
    components = template.components;
  }
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid'
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      padding: '0'
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      border: 'none',
      display: 'none'
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      padding: '12px 16px',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      padding: '12px 16px',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      padding: '12px 16px',
      border: 'none',
      outline: 'none',
      'outline-offest': 'none'
    }),
  }
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Modal.Header>
        <div className="text-2xl text-gray-800 font-semibold">
          <Translate name={"detail.new_insurance.title"} />
        </div>
      </Modal.Header>
      <Modal.Body>
        {createResponse == null ?
            <div>
              <div className="mb-4 font-bold">
                <Translate name={"detail.new_insurance.step_1"} />
              </div>
              <div className="mb-4">
                <Select
                    placeholder=""
                    selected={{
                      label: itemCategories.find(
                          (cat) => cat.value === formItemCategory
                      )?.label,
                      value: formItemCategory,
                    }}
                    setSelected={(e) => {
                      setFormItemCategory(e.value);
                      setValidItemCategory(e.value !== "");
                    }}
                    loading={false}
                    disabled={false}
                    error={!validItemCategory}
                    id="itemCategory"
                    name="itemCategory"
                    options={itemCategories}
                    label="" translationKey="detail.new_insurance.category_label"
                />
              </div>
              <div className="mb-4">
                <Label id="manufacturer">
                  <Translate name={"detail.new_insurance.manufacturer_label"} />
                </Label>
                <ReactSelect
                    onChange={(e: any) => {
                        setFormManufacturer(e.value);
                        setValidManufacturer(e.value !== "");
                    }}
                    className={classNames(
                        "basic-single",
                        !validManufacturer ? "border-red-800" : "border-purple-800"
                    )}
                    styles={customStyles}
                    classNamePrefix="select"
                    isLoading={manufacturers.length === 0}
                    isClearable={false}
                    isSearchable={true}
                    name="manufacturer"
                    options={manufacturers}
                    isDisabled={!manufacturers.length}
                    id={'manufacturer'}
                />

                {/*<Select*/}
                {/*    placeholder=""*/}
                {/*    selected={{*/}
                {/*      label: manufacturers.find((cat) => cat.value === formManufacturer)*/}
                {/*          ?.label,*/}
                {/*      value: formManufacturer,*/}
                {/*    }}*/}
                {/*    setSelected={(e) => {*/}
                {/*      setFormManufacturer(e.value);*/}
                {/*      setValidManufacturer(e.value !== "");*/}
                {/*    }}*/}
                {/*    loading={manufacturers.length === 0}*/}
                {/*    disabled={false}*/}
                {/*    error={!validManufacturer}*/}
                {/*    id="manufacturers"*/}
                {/*    name="manufacturers"*/}
                {/*    options={manufacturers}*/}
                {/*    label={i18n.t(*/}
                {/*        "detail.new_insurance.manufacturer_label"*/}
                {/*    )}*/}
                {/*/>*/}
              </div>
              <div className="mb-4">
                <Input
                    label="" translationKey="detail.new_insurance.model_label"
                    value={formModel}
                    onChange={(e) => {
                      setFormModel(e.target.value);
                      setValidModel(e.target.value !== "");
                    }}
                    error={!validModel}
                />
              </div>
              <div className="mb-4">
                <Input
                    type="number"
                    label="" translationKey="detail.new_insurance.price_label"
                    value={formRetailPrice}
                    onChange={(e) => {
                      setFormRetailPrice(parseFloat(e.target.value));
                      setValidRetailPrice(parseFloat(e.target.value) !== 0);
                    }}
                    error={!validRetailPrice}
                />
              </div>
              <div className="mb-4 font-bold">
                <Translate name={"detail.new_insurance.step_2"} />
              </div>
              <div className="mb-4">
                <Select
                    placeholder=""
                    selected={{
                      label: templates.find((cat) => cat.value === formTemplate)?.label,
                      value: formTemplate,
                    }}
                    setSelected={(e) => {
                      setFormTemplate(e.value);
                      setValidTemplate(e.value !== "");
                    }}
                    loading={templates.length === 0}
                    error={!validTemplate}
                    disabled={false}
                    id="templates"
                    name="templates"
                    options={templates}
                    label="" translationKey="detail.new_insurance.template_label"
                />

                {templatesData.find((t) => t.code === formTemplate) && (
                    <div className="my-6 text-sm text-opacity-80">
                      {templatesData.find((t) => t.code === formTemplate)
                          ?.excess_fee_theft > 0 ? (
                          <div className="mt-2 flex">
                            <Translate name={"service.detail.new_insurance.components.excess_fee_theft"} />
                            : €
                            {
                              templatesData.find((t) => t.code === formTemplate)
                                  ?.excess_fee_theft
                            }
                          </div>
                      ) : null}

                      {templatesData.find((t) => t.code === formTemplate)
                          ?.excess_fee_damage > 0 ? (
                          <div className="mt-2 flex">
                            <Translate name="service.detail.new_insurance.components.excess_fee_damage"></Translate>
                            : €
                            {
                              templatesData.find((t) => t.code === formTemplate)
                                  ?.excess_fee_damage
                            }
                          </div>
                      ) : null}
                    </div>
                )}

                {Object.entries(components).map((key) => {
                  if (key[1]) {
                    return (
                        <div className="mt-2 flex">
                          <div className="mr-2">
                            <Icon name="Check" />
                          </div>
                          <Translate name={`service.detail.new_insurance.components.${key[0]}`} />
                        </div>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="mb-4 font-bold">
                <Translate name={"detail.new_insurance.step_3"} />
              </div>
              <div className="mb-4">
                <Input
                    label="" translationKey="detail.new_insurance.purchase_date_label"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    value={formPurchaseDate}
                    onChange={(e) => {
                      setFormPurchaseDate(e.target.value);
                      setValidPurchaseDate(e.target.value !== "");
                    }}
                    error={!validPurchaseDate}
                />
              </div>
              {formItemCategory === "PHONE" ? (
                  <div className="mb-4">
                    <Input
                        label="" translationKey="detail.new_insurance.imei_label"
                        value={formImeiNo}
                        onChange={(e) => {
                          setFormImeiNo(e.target.value);
                          setValidImeiNo(isValidImei(Number(e.target.value)));
                        }}
                        error={!validImeiNo}
                    />
                  </div>
              ) : (
                  <div className="mb-4">
                    <Input
                        label="" translationKey="detail.new_insurance.serial_number_label"
                        value={formSerialNo}
                        onChange={(e) => {
                          setFormSerialNo(e.target.value);
                          setValidSerialNo(e.target.value !== "");
                        }}
                        error={!validSerialNo}
                    />
                  </div>
              )}
              <div className="mb-4 font-bold">
                <Translate name={"detail.new_insurance.step_4"} />
              </div>
              <div className="mb-4">
                <Input
                    label="" translationKey="detail.new_insurance.iban_label"
                    value={customer.iban}
                    disabled
                />
              </div>
              <div className="flex flex-row-reverse">
                <Button disabled={!canSubmitForm} onClick={validateForm}>
                  <Translate name={"detail.new_insurance.submit_form"} />
                </Button>
              </div>
            </div>
            :
            <div>
              {createResponse.type === 'automatic' ?
                  <div className="mb-4">
                    {
                        createResponse.errors?.length === 0 && <div className="mb-4">
                          <Translate name={"detail.new_insurance.submit_response.created_automatic"} />
                        </div>
                    }
                    {createResponse.number &&
                        <div className="mb-4">
                          <Translate name={"detail.new_insurance.submit_response.created_number"} /> <b>{createResponse.number}</b>
                        </div>
                    }
                    {createResponse.errors && createResponse.errors.length > 0 && <div>
                      <Translate name={"detail.new_insurance.submit_response.created_errors"} />
                      <ul>
                        {
                          createResponse.errors.map((item, index) => {
                            return <li key={index}>- {item}</li>
                          })
                        }
                      </ul>
                    </div>}
                  </div>
                  :
                  <div>
                    <div className="mb-4">
                      <Translate name={"detail.new_insurance.submit_response.created_mail"} />
                    </div>
                  </div>
              }
              <div className="flex flex-row-reverse">
                <Button onClick={closeModal}>
                  Sluiten
                </Button>
              </div>
            </div>
        }
      </Modal.Body>
    </Modal>
  );
};

export default NewInsuranceModal;
