import React from "react";
import {ContractDataOptions, CustomerDataOptions} from "./CustomerDetail";
import Modal from "../../components/Modal";
import Select from "../../components/Form/Select";
import Button, {Variant} from "../../components/Button";
import Input from "../../components/Form/Input";
import ContractBanner from "../../components/ContractBanner";
import {middleware, contractAxios} from "../../plugins/axios";
import {toastError, toastSuccess} from "../../plugins/toast";
import DateInput from "../../components/Form/DateInput";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

interface AddProductPayload {
    itemCategoryCode: string;
    manufacturer: string;
    description: string;
    objectValue: string;
    iMEI: string;
    serialNo: string;
    organisation: string;
    organisationEmail: string;
}

type contractModalProps = {
    isOpen: boolean;
    setIsOpen: any;
    contractToEdit: ContractDataOptions;
    organisation: any;
    organisationEmail: string;
    customer: CustomerDataOptions;
};

type contractModalState = {
    contract: ContractDataOptions;
    selectedBrand: {
        label: string;
        value: any;
    };

    selectedGroup: {
        label: string;
        value: any;
    }
    manufactures: Array<{ label: string; value: string }>;
    payload: AddProductPayload;
    manufacturesLoading: boolean;
    manufacturesEnabled: boolean;

    articleGroups: Array<{ label: string; value: string }>;
};

export class ContractAddProductModal extends React.Component<
    contractModalProps,
    contractModalState
> {
    state = {
        contract: this.props.contractToEdit,
        articleGroups: [],
        selectedBrand: {
            label: "",
            value: "",
        },
        selectedGroup: {
            label: "",
            value: "",
        },
        manufactures: [
            {
                label: "",
                value: "",
            },
        ],
        payload: {
            itemCategoryCode: "",
            manufacturer: "",
            description: "",
            objectValue: "",
            iMEI: "",
            serialNo: "",
            organisation: this.props.organisation.name,
            organisationEmail: this.props.organisationEmail,
        },
        manufacturesLoading: false,
        manufacturesEnabled: false,
    };

    setSelectedBrand(e: { label: string; value: any }) {
        var newPayload = {...this.state.payload};
        newPayload.manufacturer = e.value;
        this.setState({
            selectedBrand: e,
            payload: newPayload,
        });


    }

    setSelectedGroup(e: { label: string; value: any }) {
        var newPayload = {...this.state.payload};
        newPayload.itemCategoryCode = e.value;
        this.setState({
            selectedGroup: e,
            payload: newPayload,
        });
        if (e.value !== "") {
            this.getManufacturers(e.value);
        }
    }

    async getManufacturers(itemCategoryCode: string) {
        this.setState({
            manufacturesLoading: true,
            manufacturesEnabled: false,
        });
        return contractAxios.get(`manufacturers?template=${this.props.customer.template}&contract_amount=1&itemCategory=${itemCategoryCode}&search=`)
            .then((res) => {
                var manufactures = res.data.map((el: any) => {
                    return {label: el.name, value: el.code};
                });
                this.setState({
                    manufactures: manufactures,
                    manufacturesLoading: false,
                    manufacturesEnabled: true,
                });
            });
    }

    async componentDidMount() {


        if (!this.props.contractToEdit.contractarticlegroup_set) {
            console.error("Cannot render component without contractarticlegroup_set");
            return;
        }

        const articleGroupOptions = this.props.contractToEdit.contractarticlegroup_set.map((group) => ({
            label: group.description,
            value: group.category_code,
        }));

        this.setState({
            articleGroups: articleGroupOptions
        });

        if (this.state.selectedGroup.value !== "") {
            this.getManufacturers(this.state.selectedGroup.value);
        }
    }

    submitForm() {
        middleware
          .post(
            `contracts/service/add_product/${this.props.customer.id}/${this.props.contractToEdit.contract_id}`,
            { data: this.state.payload }
          )
          .then((res) => {
            if (res.status === 200) {
              toastSuccess("Product has been added to the contract");
              this.props.setIsOpen(false, true);
            }
          })
          .catch((e) => toastError("Something went wrong", e.message));
    }

    handleDateChangePayloadValue(date: Date) {
        var newPayload: any = {
            ...this.state.payload,
            purchaseDate: date.toISOString().split("T")[0],
        }
        this.setState({
            payload: newPayload
        })
    }

    handleChangePayloadValue(e: any) {
        var newPayload: any = {...this.state.payload};
        newPayload[e.target.name] = e.target.value;
        this.setState({
            payload: newPayload,
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} setIsOpen={this.props.setIsOpen}>
                <Modal.Header>
                    <div className="text-2xl text-gray-800 font-semibold">
                        <Translate name={"detail.edit_contract.title"}/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ContractBanner
                        category={this.props.contractToEdit.category}
                        itemDescription={this.props.contractToEdit.item_description}
                        contractDescription={this.props.contractToEdit.contract_description}
                    />
                    <Select
                        placeholder=""
                        selected={this.state.selectedGroup}
                        setSelected={(e) => this.setSelectedGroup(e)}
                        disabled={this.state.articleGroups.length === 1}
                        id="articleGroup"
                        name="articleGroup"
                        options={this.state.articleGroups}
                        label="Group"
                        translationKey="detail.edit_contract.group_label"
                    />
                    <Select
                        placeholder=""
                        selected={this.state.selectedBrand}
                        setSelected={(e) => this.setSelectedBrand(e)}
                        loading={this.state.manufacturesLoading}
                        disabled={!this.state.manufacturesEnabled}
                        id="brand"
                        name="brand"
                        options={this.state.manufactures}
                        label="Brand"
                        translationKey="detail.edit_contract.brand_label"
                    />
                    <Input
                        label=""
                        translationKey="detail.edit_contract.description_label"
                        value={this.state.payload.description}
                        onChange={this.handleChangePayloadValue.bind(this)}
                        name="description"
                        placeholder={i18n.t(
                            "detail.edit_contract.description_placeholder"
                        )}
                    />
                    <Input
                        label=""
                        translationKey="detail.edit_contract.price_label"
                        value={this.state.payload.objectValue}
                        onChange={this.handleChangePayloadValue.bind(this)}
                        name="objectValue"
                        placeholder={i18n.t(
                            "detail.edit_contract.price_placeholder"
                        )}
                    />

                    {this.props.contractToEdit.category.toLowerCase() === "phone" ? (
                        <Input
                            label=""
                            translationKey="detail.edit_contract.imei_label"
                            value={this.state.payload.iMEI}
                            onChange={this.handleChangePayloadValue.bind(this)}
                            name="iMEI"
                            placeholder={i18n.t(
                                "detail.edit_contract.imei_placeholder"
                            )}
                        />
                    ) : (
                        <Input
                            label=""
                            translationKey="detail.edit_contract.serial_no_label"
                            value={this.state.payload.serialNo}
                            onChange={this.handleChangePayloadValue.bind(this)}
                            name="serialNo"
                            placeholder={i18n.t(
                                "detail.edit_contract.serial_no_placeholder"
                            )}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button
                            onClick={() => this.props.setIsOpen(false)}
                            variant={Variant.TRANSPARENT}
                        >
                            <Translate name={"detail.common.back_to_detail"}/>
                        </Button>
                    </div>
                    <div>
                        <Button onClick={() => this.submitForm()}>
                            <Translate name={"detail.common.save"}/>
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
