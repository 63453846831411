import React, { createRef, useEffect, useRef } from "react";
import Translate from "../Translate";
import Label from "./Label";

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string,
  translationKey?: string,
  limit?: number,
  id?: string,
}

const Textarea: React.FC<TextareaProps> = ({label, translationKey, id, limit, ...rest}) => {
  const textRef = useRef<HTMLTextAreaElement>(null);
  let count = limit;

  const limitText = () => {
    if(!(limit !== undefined && limit !== null && limit > 0 && !!textRef.current)) return;

    if(textRef.current!.value.length > count!) {
      textRef.current!.value = textRef.current!.value.slice(0, count)
    }
  }

  return (
    <div>
      <Label id={id}>
        {!translationKey ? label : <Translate name={translationKey}/>}
      </Label>
      <div className="relative w-full mt-2">
        <textarea
          ref={textRef}
          id={id}
          onInput={limitText}
          {...rest}
          className="block py-3 px-4 w-full rounded-3px border border-purple-800 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 placeholder-gray-700 placeholder-opacity-40"
        />
        { limit !== undefined && limit !== null && limit > 0 && !!textRef.current &&
          <span className="absolute right-1 top-0 text-gray-500">{count! - textRef.current!.value.length}</span>
        }
      </div>
    </div>
  );
};

export default Textarea;
