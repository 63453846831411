import firebase from "firebase";
import { env } from "../env";

const config = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_DOMAIN,
};

firebase.initializeApp(config);
const auth = firebase.auth();

if (env.REACT_APP_DISABLE_CAPTCHA) {
  auth.settings.appVerificationDisabledForTesting = true;
}

export default auth;
