import React from "react";
import { ContractDataOptions } from "./CustomerDetail";
import Card from "../../components/Card";
import Heading, { Level } from "../../components/Typography/Heading";
import PermissionWrapper, {
  UserPermissions,
} from "../../components/UserPermission";
import Button from "../../components/Button";
import Translate from "../../components/Translate";
import Icon from "../../components/Icon";
import DataList from "../../components/DataList";

interface ContractDetailCardProps {
  contract: ContractDataOptions;
  setShowContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  setContractToEditData: React.Dispatch<
    React.SetStateAction<ContractDataOptions | null>
  >;
  setShowTasksModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTasksContractNumber: React.Dispatch<React.SetStateAction<string>>;
  setShowContractDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setContractToDeleteData: React.Dispatch<
    React.SetStateAction<ContractDataOptions | null>
  >;
  setShowContractRetainModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowClaimsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setContractToRetainData: React.Dispatch<
    React.SetStateAction<ContractDataOptions | null>
  >;

  sendCustomerContractMail(contract: ContractDataOptions, type: string) : any;

  handleClaimsSubmit(): void;

  contractHasClaims(contract: ContractDataOptions): boolean;

  downloadDocument(contract: string, type: string): void;
  emailDocument(contract: string, type: string): void;
}

const ContractDetailCard: React.FC<ContractDetailCardProps> = (props) => {

  const showEmailTriggerActive = (contract: ContractDataOptions) => {
    if (contract.status.toLowerCase() === 'active') {
      return (
          <>
            <div
                onClick={() => {
                  sendCustomerContractMail(contract, "active");
                }}
                className="text-sm text-right text-purple-700 underline cursor-pointer"
            >
              <Translate name={"detail.contracts.accept_contract_mail"}/>
            </div>
          </>
      )
    }
  }
  const showEmailTriggerCancellation = (contract: ContractDataOptions) => {
    if ((contract.status.toLowerCase() === 'active' || contract.status.toLowerCase() === 'closed') && (contract.ending_date !== "" && contract.ending_date !== null)) {
      return (
          <>
            <div
              onClick={() => {
                sendCustomerContractMail(contract,"ending");
              }}
              className="text-sm text-right text-purple-700 underline cursor-pointer"
          >
             <Translate name={"detail.contracts.cancellation_contract_mail"} />
            </div>
          </>
      )
    }
    return "";
  };

  const {
    contract,
    setShowContractModal,
    setContractToEditData,
    setShowTasksModal,
    setTasksContractNumber,
    setShowContractDeleteModal,
    setContractToDeleteData,
    setShowContractRetainModal,
    setShowClaimsModal,
    setContractToRetainData,
    sendCustomerContractMail,
    handleClaimsSubmit,
    contractHasClaims,
    downloadDocument,
    emailDocument,
  } = props;
  return (
    <Card classList="mt-8">
      <Card.Header cols="grid-cols-12" colSpan="col-span-full">
        <div className="col-span-full md:col-span-8">
          <Heading color="text-purple-700" tag="h3" level={Level.HEADING_3}>
            {contract.contract_description}
          </Heading>

          <Heading tag="h4" level={Level.HEADING_5}>
            {contract.item_description}
          </Heading>
        </div>

        <div className="col-span-full md:col-span-4 flex items-center justify-end">
          <PermissionWrapper
            permissionSlug={UserPermissions.EDIT_INSURANCE_POLICY}
          >
            {contract.status.toLowerCase() === "active" ? (
              <Button
                onClick={() => {
                  setShowContractModal(
                    (showContractModal: Boolean) => !showContractModal
                  );
                  setContractToEditData((contractToEditData) => contract);
                }}
              >
                <Translate name={"detail.contracts.edit"} />
              </Button>
            ) : null}
          </PermissionWrapper>
        </div>
      </Card.Header>

      <div className="flex flex-col">
        <div className="p-2 w-48">
          <Icon name={contract.category} />
        </div>
        <div className="flex-grow">
          <DataList perRow={2} data={contract.data} />
          <tr>
            <td className="pr-6 pt-3 whitespace-nowrap">
              <span className="text-sm font-base">
                <Translate name={"detail.contracts.premium_amount"} />
              </span>
              <span className="text-sm pl-2 font-base">
                {contract.premium_amount ? "€" + contract.premium_amount : "-"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="pr-6 whitespace-nowrap">
              <span className="text-sm font-base">
                <Translate name={"detail.contracts.own_risk_damage_amount"} />
              </span>
              <span className="text-sm pl-2 font-base">
                {contract.own_risk_damage_amount > 0
                  ? "€" + contract.own_risk_damage_amount
                  : "-"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="pr-6 whitespace-nowrap">
              <span className="text-sm font-base">
                <Translate name={"detail.contracts.own_risk_theft_amount"} />
              </span>
              <span className="text-sm pl-2 font-base">
                {contract.own_risk_theft_amount > 0
                  ? "€" + contract.own_risk_theft_amount
                  : "-"}
              </span>
            </td>
          </tr>
          <div className="flex flex-col mt-4 space-y-2">
            <PermissionWrapper permissionSlug={UserPermissions.CREATE_CLAIMS}>
              <div
                onClick={() => {
                  setContractToEditData(contract);
                  handleClaimsSubmit();
                }}
                className="text-sm text-right text-purple-700 underline cursor-pointer"
              >
                <Translate name={"detail.contracts.new_claim"} />
              </div>
            </PermissionWrapper>

            {/* create user task */}
            <div
              className="text-sm text-right text-purple-700 underline cursor-pointer"
              onClick={() => {
                setShowTasksModal(true);
                setTasksContractNumber(contract.contract_no);
              }}
            >
              <Translate name={"detail.contracts.new_task"} />
            </div>

            {contractHasClaims(contract) ? (
              <div
                onClick={() => {
                  setShowClaimsModal(
                    (showClaimsModal: boolean) => !showClaimsModal
                  );
                  setContractToEditData(contract);
                }}
                className="text-sm text-right text-purple-700 underline cursor-pointer"
              >
                <Translate name={"detail.contracts.view_claims"} />
              </div>
            ) : null}
            <PermissionWrapper
              permissionSlug={UserPermissions.CANCEL_INSURANCE_POLICY}
            >
              {contract.status.toLowerCase() === "active" ? (
                <>
                  <div
                    onClick={() => {
                      setShowContractDeleteModal(
                        (showContractDeleteModal) => !showContractDeleteModal
                      );
                      setContractToDeleteData(contract);
                    }}
                    className="text-sm text-right text-purple-700 underline cursor-pointer"
                  >
                    <Translate name={"detail.contracts.cancel"} />
                  </div>

                  <PermissionWrapper
                    permissionSlug={UserPermissions.CUSTOMER_RETENTION}
                  >
                    <div
                      onClick={() => {
                        setShowContractRetainModal(
                          (showContractRetainModal) => !showContractRetainModal
                        );
                        setContractToRetainData(contract);
                      }}
                      className="text-sm text-right text-purple-700 underline cursor-pointer"
                    >
                      <Translate name={"detail.contracts.retain"} />
                    </div>
                  </PermissionWrapper>
                </>
              ) : null}

              {showEmailTriggerActive(contract)}
              {showEmailTriggerCancellation(contract)}

              <div className="text-sm text-right text-purple-700 underline ml-auto ml-0 flex">
                <Translate name={"detail.contracts.download_ipid"} />
                {/*<div className="ml-2 cursor-pointer" onClick={() => emailDocument(contract.contract_id, "ipid")}>*/}
                {/*    <Icon name="mail"/>*/}
                {/*</div>*/}
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() => downloadDocument(contract.contract_id, "ipid")}
                >
                  <Icon name="download" />
                </div>
              </div>
              <div className="text-sm text-right text-purple-700 underline ml-auto ml-0 flex">
                <Translate name={"detail.contracts.download_policy"} />
                {/*<div className="ml-2 cursor-pointer" onClick={() => emailDocument(contract.contract_id, "policy_conditions")}>*/}
                {/*    <Icon name="mail"/>*/}
                {/*</div>*/}
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() =>
                    downloadDocument(contract.contract_id, "policy_conditions")
                  }
                >
                  <Icon name="download" />
                </div>
              </div>
              <div className="text-sm text-right text-purple-700 underline ml-auto ml-0 flex">
                <Translate name={"detail.contracts.download_sheet"} />
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() =>
                    emailDocument(contract.contract_id, "policy_sheet")
                  }
                >
                  <Icon name="mail" />
                </div>
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() =>
                    downloadDocument(contract.contract_id, "policy_sheet")
                  }
                >
                  <Icon name="download" />
                </div>
              </div>
            </PermissionWrapper>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ContractDetailCard;
