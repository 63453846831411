import React from "react";
import CheckButton from "./CheckButton";

interface Option {
  label: string;
  value: string | number;
}

interface CheckButtonListProps {
  TwoRows?: boolean;
  selectSingle?: boolean;
  translationKey?: string;
  label?: string;
  options: Option[];
  selected: Option[];
  setSelected: React.Dispatch<React.SetStateAction<any>>;
}

const CheckButtonList: React.FC<CheckButtonListProps> = ({
  TwoRows = false,
  selectSingle = false,
  label = "",
  options,
  selected,
  setSelected,
}) => {
  const getOptionChecked = (option: Option) => {
    return !!selected.find(
      (o) => o.label === option.label && o.value === option.value
    );
  };

  const handleOptionClick = (option: Option) => {
    if (selectSingle) {
      setSelected([option]);
      return;
    }
    var newSelected: Option[] = [...selected] as Option[];
    var index: number = newSelected.findIndex(
      (o) => o.label === option.label && o.value === option.value
    );
    if (index === -1) {
      newSelected.push(option);
    } else {
      newSelected.splice(index, 1);
    }
    setSelected(newSelected);
  };
  return (
    <>
      <div>
        <span>{label}</span>
        {options.map((option: Option) => {
          return (
            <CheckButton
              label={option.label}
              translationKey={option.label}
              checked={getOptionChecked(option)}
              onClick={() => handleOptionClick(option)}
            />
          );
        })}
      </div>
    </>
  );
};

export default CheckButtonList;
