import React from "react";
import DataList from "../../components/DataList";
import Modal from "../../components/Modal";
import { ClaimDataOptions, ContractDataOptions } from "./CustomerDetail";
import { Disclosure } from "@headlessui/react";
import Button, { Variant } from "../../components/Button";
import Icon from "../../components/Icon";
import { formatDate, formatDateTime } from "../../utils/formatDate";
import Translate from "../../components/Translate";
interface ClaimsModalProps {
  claims: ClaimDataOptions[];
  contract: ContractDataOptions;
  sendCustomerClaimMail(claim: ClaimDataOptions, type: string) : any;
  isOpen: boolean;
  setIsOpen: any;
}

const ClaimsModal: React.FC<ClaimsModalProps> = (props) => {
  const ONSITE_ROUTINGS = ["Onsite", 4, "4"];
  const getDataList = (claim: ClaimDataOptions, isMultidevice: boolean) => {
    const list = [
      {
        value: formatDate(claim.date),
        label: "detail.claims.date",
      },
      {
        value: claim.damageCause,
        label: "detail.claims.damage_cause",
      },
      {
        value: claim.problemArea,
        label: "detail.claims.problem_area",
      },
      {
        value: claim.claimNo,
        label: "detail.claims.claim_no",
      },
      {
        value: props.contract.iMEI,
        label: "detail.common.imei",
      },
      {
        value: props.contract.serialNo,
        label: "detail.common.serial_no",
      },
      {
        value: claim.description,
        label: "detail.claims.description",
      },
      {},
      {
        value: claim.repairStatus === "" ? "-" : claim.repairStatus,
        label: "detail.claims.repair_status",
      },
      {
        value:
          !claim.repairStatusLastUpdated ||
          claim.repairStatusLastUpdated.includes("0001-01-01") ||
          claim.repairStatusLastUpdated === ""
            ? "-"
            : formatDateTime(claim.repairStatusLastUpdated),
        label: "detail.claims.repair_status_last_updated",
      },
      {
        value: claim.onHoldReasonCodeDescription === "" ? "-" : claim.onHoldReasonCodeDescription,
        label: "detail.claims.on_hold_reason_code_description",
      },
    ];

    if (ONSITE_ROUTINGS.includes(claim.routing)) {
      const { shopName, shopPhoneNo } = claim;
      const itemsToAdd = [
        {},
        { value: shopName, label: `detail.claims.shop_name` },
        { value: shopPhoneNo, label: `detail.claims.shop_phone_no` },
      ];
      list.push(...itemsToAdd);
    }

    if (isMultidevice) {
      list.push({
        value: claim.productDescription,
        label: "detail.claims.product",
      });
    }

    return list;
  };

  const getFilteredClaims = () => {
    return props.claims.filter(
      (claim) => claim.contractId === props.contract.contract_id
    );
  };

  const {
    contract,
    sendCustomerClaimMail,
  } = props;
  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      maxWidth="max-w-4xl"
    >
      <Modal.Header>
        <div className="text-2xl text-gray-800 font-semibold">
          <Translate name={"detail.claims.title"} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          {getFilteredClaims().map((claim) => {
            const dataList = getDataList(claim, props.contract.multidevice);
            return (
              <div className="w-full mx-auto bg-white pb-6  ">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between shadow-lg w-full px-4 py-6 text-lg text-left text-purple-700 rounded-sm">
                        <span>{`${claim.status} - ${claim.claimNo}`}</span>
                        {open ? (
                          <Icon name="Chevron-up" />
                        ) : (
                          <Icon name="Chevron-down" />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className="shadow-lg rounded-sm -mt-4 pb-2 text-sm">
                        <div className="w-100 bg-white">
                          <div className="px-4 pt-2">
                            <DataList data={dataList}></DataList>
                            {claim.status.toLowerCase() === "accepted" && (<button
                                className="text-purple my-4"
                                onClick={() => {
                                  sendCustomerClaimMail(claim,"active");
                                }}
                            >
                              <Translate name={"detail.claims.trigger_email"} />
                            </button>)}
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            onClick={() => props.setIsOpen(false)}
            variant={Variant.TRANSPARENT}
          >
            <Translate name={"detail.common.back_to_detail"} />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ClaimsModal;
