export function isValidImei(n: number) {
    let s = n.toString();
    let len = s.length;

    if (len !== 15)
        return false;

    let sum = 0;
    for(let i = len; i >= 1; i--)
    {
    let d = (n % 10);

    // Doubling every alternate digit
    if (i % 2 === 0)
        d = 2 * d;

    // Finding sum of the digits
    sum += sumDig(d);
    n = parseInt((n / 10).toString(), 10);
    }

    return (sum % 10 === 0);
}

const sumDig = (n: number) => {
    let a = 0;
    while (n > 0)
    {
        a = a + n % 10;
        n = parseInt((n / 10).toString(), 10);
    }
    return a;
}
