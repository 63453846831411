import React from "react";
import i18n from "../plugins/i18n";
import DOMPurify from "dompurify";

interface ITranslationProps {
  name: string,
}

const Translation: React.FC<ITranslationProps> = ({name}, ...rest) => {

  const sanitizeConfig = {
    ALLOWED_TAGS: [
      'b', 
      'q', 
      'span', 
      'strong',
      'br',
      'u'
    ], 
    ALLOWED_ATTR: [
      'style'
    ]}

  return (
    <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(i18n.t(name), sanitizeConfig)}} />
  )
}

export default Translation;