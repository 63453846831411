import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import UserProvider, { UserContext } from "./providers/UserProvider";
import LoginLayout from "./layouts/LoginLayout/LoginLayout";
import AppLayout from "./layouts/AppLayout/AppLayout";
import CustomerDetail from "./pages/CustomerDetail/CustomerDetail";
import CustomerSearch from "./pages/CustomerSearch/CustomerSearch";
import Team from "./pages/Team/Team";
import SetPassword from "./pages/SetPassword/SetPassword";
import TeamDetail from "./pages/TeamDetail/TeamDetail";
import OrganisationProvider, {
  OrganisationContext,
} from "./providers/OrganisationProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinningLoader from "./components/loader";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Link from "./pages/Login/Link";
import { loadLocales } from "./plugins/i18n";
import LanguageProvider, {
  LanguageContext,
} from "./providers/LanguageProvider";

const ApplicationWrapper: React.FC = (props) => {
  const userContext = useContext(UserContext);

  if (userContext.isLoaded) {
    return <div>{props.children}</div>;
  } else {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-75">
        <SpinningLoader />
      </div>
    );
  }
};

const SetPasswordRoute: React.FC<RouteProps> = (props) => {
  const userContext = useContext(UserContext);
  if (userContext.user) {
    return <Redirect to="/" />;
  }
  const { ...rest } = props;
  return <Route {...rest}>{props.children}</Route>;
};

const ForgotPasswordRoute: React.FC<RouteProps> = (props) => {
  const { ...rest } = props;
  return <Route {...rest}>{props.children}</Route>;
};

const LoginRoute: React.FC<RouteProps> = (props) => {
  const userContext = useContext(UserContext);
  if (userContext.user) {
    return <Redirect to="/" />;
  }
  const { ...rest } = props;
  return <Route {...rest}>{props.children}</Route>;
};

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const userContext = useContext(UserContext);
  if (userContext.isLoaded && !userContext.user) {
    return <Redirect to="/login" />;
  }

  const { ...rest } = props;
  return <Route {...rest}>{props.children}</Route>;
};

toast.configure();

const App: React.FC = () => {
  const languageContext = useContext(LanguageContext);

  const organisationContext = useContext(OrganisationContext);
  const organisationUserInfo = organisationContext.organisationUserInfo;

  useEffect(() => {
    languageContext.loadLocaleResources(
      organisationUserInfo?.language ? organisationUserInfo.language : ""
    );
  }, [organisationUserInfo]);

  return (
    <>
      {languageContext.localeReady ? (
        <ApplicationWrapper>
          <BrowserRouter>
            <Switch>
              <Route path={["/set_password"]}>
                <LoginLayout>
                  <Switch>
                    <SetPasswordRoute
                      path="/set_password"
                      component={SetPassword}
                    />
                  </Switch>
                </LoginLayout>
              </Route>
              <Route path={["/login", "/link"]}>
                <LoginLayout>
                  <Switch>
                    <LoginRoute path="/login" component={Login} />
                    <LoginRoute path="/link" component={Link} />
                  </Switch>
                </LoginLayout>
              </Route>
              <Route path={["/forgot-password"]}>
                <LoginLayout>
                  <Switch>
                    <ForgotPasswordRoute
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                  </Switch>
                </LoginLayout>
              </Route>
              <Route>
                <AppLayout>
                  <Switch>
                    <ProtectedRoute path="/team/:id" component={TeamDetail} />
                    <ProtectedRoute path="/team" component={Team} />
                    <ProtectedRoute
                      exact={true}
                      path="/"
                      component={CustomerSearch}
                    />
                    <ProtectedRoute
                      path="/search"
                      component={CustomerSearch}
                    />
                    <ProtectedRoute
                      path="/customer/:id"
                      component={CustomerDetail}
                    />
                  </Switch>
                </AppLayout>
              </Route>
            </Switch>
          </BrowserRouter>
        </ApplicationWrapper>
      ) : null}
    </>
  );
};

export default App;
