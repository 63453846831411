import React, {useEffect, useState} from "react";
import { CustomerDataOptions } from "./CustomerDetail";
import Modal from "../../components/Modal";
import Button, { Variant } from "../../components/Button";
import { middleware } from "../../plugins/axios";
import { toastError, toastSuccess } from "../../plugins/toast";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";
import Input from "../../components/Form/Input";
import ValidationError from "../../components/Form/ValidationError";
import {useValidationContext} from "../../providers/ValidationProvider";

type AccountModalProps = {
  isOpen: boolean;
  organisation: any;
  organisationEmail: string;
  setIsOpen: any;
  customer: CustomerDataOptions;
  loggedInUser: any;
};

export const AccountModal: React.FC<AccountModalProps> = (props) =>{

  const [newEmailValidationError, setNewEmailValidationError] = useState("");
  const validationContext = useValidationContext();
  const [oldEmail, setOldEmail] = useState<string>(props.customer.account.email)
  const [newEmail, setNewEmail] = useState<string>("")

  useEffect(() => {
    setOldEmail(props.customer.account.email)
  }, [props.customer])

  const saveMail = () => {
    saveCustomerAccount()
      .catch(() => {
        // error in changing the account mail
        toastError(i18n.t("detail.customer_account_edit.error"))
      })
      .then(() => {
        toastSuccess(i18n.t("detail.customer_account_edit.success"));
      })

    closeModalAndResetMail();
  }

  const saveCustomerAccount = async () => {
    const payload = {
      old_email: oldEmail,
      new_email: newEmail,
      locale: props.customer.locale_code.slice(0, 1)
    }
    await middleware.post(`user/service/update_user_email`, payload)
  }

  const closeModalAndResetMail = () => {
    setNewEmail("")
    props.setIsOpen(false, true);
  }

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!validationContext.validateEmail(e.target.value)) {
      setNewEmailValidationError(i18n.t("login.errors.email_not_valid"));
    } else {
        setNewEmailValidationError("")
    }
  };

  const canProceed = () => {
    return validationContext.validateNotSame(oldEmail, newEmail) && validationContext.validateEmail(oldEmail) && validationContext.validateEmail(newEmail);
  };

  return (
    <Modal isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <Modal.Header>
        <div className="text-2xl text-gray-800 font-semibold">
          <Translate name={"detail.customer_account_edit.title"} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={() => {}}>
          <div className={"grid gap-y-3"}>
            <Input
              label=""
              translationKey="detail.customer_account_edit.old_email_label"
              value={oldEmail}
              name="email"
              disabled={true}
            />
            <Input
              label=""
              translationKey="detail.customer_account_edit.new_email_label"
              value={newEmail}
              name="email"
              onChange={(e) => {
                setNewEmail(e.target.value)
                onEmailChange(e)
              }}
            />
            {newEmailValidationError && (
              <ValidationError classList={"mt-1"}>
                {newEmailValidationError}
              </ValidationError>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            onClick={() => {
              setNewEmail("")
              props.setIsOpen(false)
            }}
            variant={Variant.TRANSPARENT}
          >
            <Translate name={"detail.common.back_to_detail"} />
          </Button>
        </div>
        <div>
          <Button disabled={!canProceed()} onClick={saveMail}>
            <Translate name={"detail.common.save"} />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
