import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import Alert from "../../components/Alert";
import Button, {Variant} from "../../components/Button";
import Card from "../../components/Card";
import DataList from "../../components/DataList";
import Checkbox from "../../components/Form/Checkbox";
import Icon from "../../components/Icon";
import AppHeader from "../../components/Layout/AppHeader";
import SpinningLoader, {SpinnerColor} from "../../components/loader";
import Modal from "../../components/Modal";
import Heading, {Level} from "../../components/Typography/Heading";
import {organisationService} from "../../plugins/axios";
import {toastError, toastSuccess} from "../../plugins/toast";
import PermissionWrapper, {HasUserPermission} from "../../components/UserPermission";
import {OrganisationContext} from "../../providers/OrganisationProvider";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";

interface UserDataOptions {
  active: boolean;
  email: string;
  firebaseUid: string;
  id: string;
  organisation: string;
  role: string;
  firstName: string;
  lastName: string;
  permissions: Array<string>;
  data: Array<{
    label: string;
    value: string;
  }>;
  viewed_onboarding: boolean;
  language: string;
  phoneNumber: string;
  connected_jubilee: boolean;
  jubilee_url: string;
  jubilee_username: string;
  jubilee_password: string;
}

const TeamMember: React.FC<RouteComponentProps<{ id: string }>> = (props) => {
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [userData, setUserData] = useState<UserDataOptions | null>(null);
  const [userLoading, setUserLoading] = useState(true);

  const history = useHistory();
  const organisationContext = useContext(OrganisationContext);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setUserLoading(true);
    organisationService
      .get(`/organisation_users/${props.match.params.id}`)
      .then((res) => {
        let userData: UserDataOptions = {
          active: res.data["active"],
          email: res.data["email"],
          firebaseUid: res.data["firebase_uid"],
          id: res.data["id"],
          organisation: res.data["organisation"],
          role: res.data["role"],
          permissions: res.data["permissions"] ? res.data["permissions"] : [],
          firstName: res.data["first_name"],
          lastName: res.data["last_name"],
          viewed_onboarding: res.data["viewed_onboarding"],
          language: res.data["language"],
          phoneNumber: res.data["phone_number"],
          connected_jubilee: res.data["connected_jubilee"],
          jubilee_url: res.data["jubilee_url"],
          jubilee_username: res.data["jubilee_username"],
          jubilee_password: res.data["jubilee_password"],
          data: [
            {
              label: "team.detail.first_name",
              value: res.data["first_name"],
            },
            {
              label: "team.detail.last_name",
              value: res.data["last_name"],
            },
            {
              label: "team.detail.email",
              value: res.data["email"],
            },
            {
              label: "team.detail.user_role",
              value: res.data["role"],
            },
          ],
        };
        setUserData(userData);
        setUserLoading(false);
      })
      .catch((e) => {
        toastError(i18n.t("team.detail.load_error"), e.message);
        setUserLoading(false);
      });
  };

  const submitDeleteUser = () => {
    organisationService.delete(`/organisation_users/${props.match.params.id}`).then(r => {
      toastSuccess(i18n.t("team.detail.delete.success"));

      const organisationId = organisationContext.organisation
        ? organisationContext.organisation.id
        : "";
      organisationContext.getOrganisationUsers(organisationId).then(r => {
        history.goBack();
      });
    })
  };

  const SetPermission = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!userData) return;

    let index: number = userData.permissions.findIndex(
      (p) => e.target.id === p
    );

    if (index === -1) {
      userData.permissions.push(e.target.id);
    } else {
      userData.permissions.splice(index, 1);
    }

    setUserData({...userData});
  };

  const submitPermissions = () => {
    if (!userData) return;
    organisationService
      .patch(`organisation_users/${props.match.params.id}`, {
        active: userData.active,
        email: userData.email,
        firebase_uid: userData.firebaseUid,
        id: userData.id,
        organisation: userData.organisation,
        role: userData.role,
        first_name: userData.firstName,
        last_name: userData.lastName,
        permissions: userData.permissions,
        viewed_onboarding: userData.viewed_onboarding,
        language: userData.language,
        phone_number: userData.phoneNumber,
        connected_jubilee: userData.connected_jubilee,
        jubilee_url: userData.jubilee_url,
        jubilee_username: userData.jubilee_username,
        jubilee_password: userData.jubilee_password,
      })
      .then((res) => {
        toastSuccess(i18n.t("team.detail.success"));
        getUserData();
      }).catch((error) => {
      toastError(i18n.t("team.detail.load_error"), error.message);
    });
  };

  const setActive = () => {
    let data: UserDataOptions = {...userData} as UserDataOptions;
    data.active = !data.active;
    setUserData(data);
  };
  return (
    <>
      <AppHeader>
        <Heading tag="h1" level={Level.HEADING_2}>
          {userLoading ? (
            <SpinningLoader color={SpinnerColor.PURPLE}/>
          ) : userData ? (
            `${userData.firstName} ${userData.lastName}`
          ) : null}
        </Heading>
        {/*<div>*/}
        {/*  {userLoading ? (*/}
        {/*    <SpinningLoader color={SpinnerColor.PURPLE} />*/}
        {/*  ) : userData ? (*/}
        {/*    <Checkbox*/}
        {/*      label="Inactief"*/}
        {/*      name="roles"*/}
        {/*      checked={userData.active}*/}
        {/*      onChange={() => {*/}
        {/*        setActive();*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  ) : null}*/}
        {/*</div>*/}
        <div>
          <Button
            onClick={() =>
              setShowRemoveUserModal(
                (showRemoveUserModal) => !showRemoveUserModal
              )
            }
          >
            <Translate name={"team.detail.delete_user"}/>
          </Button>
        </div>
      </AppHeader>

      <div className="grid grid-cols-2 gap-8 mb-8 p-8">
        <div className="flex flex-col">
          <Card>
            <Card.Header cols="grid-cols-2" colSpan="col-span-full">
              <div className="col-span-full md:col-span-1 flex flex-row items-center">
                <Icon name="icon_userinformation" classList="mr-2"/>
                <Heading tag="h2" level={Level.HEADING_3}>
                  <Translate name={"team.detail.header"}/>
                </Heading>
              </div>
            </Card.Header>
            {userLoading ? (
              <SpinningLoader color={SpinnerColor.PURPLE}/>
            ) : (
              <DataList data={userData ? userData.data : []}></DataList>
            )}
          </Card>
        </div>
        <div className="flex flex-col">
          <Card>
            <Card.Header cols="grid-cols-2" colSpan="col-span-full">
              <div className="col-span-full md:col-span-1 flex flex-row items-center">
                <Icon name="icon_userinformation" classList="mr-2"/>
                <Heading tag="h2" level={Level.HEADING_3}>
                  <Translate name={"team.detail.permissions"}/>
                </Heading>
              </div>
            </Card.Header>
            {userLoading ? (
              <SpinningLoader color={SpinnerColor.PURPLE}/>
            ) : (
              <div className="flex w-full">
                <form action="">
                  {PermissionWrapper.Permissions.filter(
                    (permission) =>
                      organisationContext.organisation?.permissions?.indexOf(
                        permission.slug
                      ) >= 0
                  ).map((permission) => {
                    return (
                      <div key={permission.slug} className="w-full pt-2">
                        <Checkbox
                          id={permission.slug}
                          checked={HasUserPermission(
                            permission.slug,
                            userData?.permissions
                          )}
                          onChange={SetPermission.bind(this)}
                          name="role"
                          label="" translationKey={`permissions.${permission.slug}`}
                        />
                      </div>
                    );

                  })}
                </form>
              </div>
            )}
            <div className="flex justify-end">
              <Button
                onClick={() => {
                  submitPermissions();
                }}
              >
                <Translate name={"team.detail.save_permissions"}/>
              </Button>
            </div>
          </Card>
        </div>
      </div>

      <Modal isOpen={showRemoveUserModal} setIsOpen={setShowRemoveUserModal}>
        <Modal.Header>
          <h2><Translate name={"team.detail.delete.header"}/></h2>
        </Modal.Header>

        <Modal.Body>
          <Alert heading="Warning">
            <p><Translate name={"team.detail.delete.alert"}/></p>
          </Alert>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Button
              onClick={() =>
                setShowRemoveUserModal(
                  (showRemoveUserModal) => !showRemoveUserModal
                )
              }
              variant={Variant.LIGHT_PURPLE}
            >
              <Translate name={"team.detail.delete.back"}/>
            </Button>
          </div>

          <div>
            <Button onClick={() => submitDeleteUser()}>
              <Translate name={"team.detail.delete.confirm"}/>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TeamMember;
