import React, {Fragment, useContext, useEffect, useState} from "react";
import Select from "./Form/Select";
import {LanguageContext} from "../providers/LanguageProvider";
import Label from "./Form/Label";
import {Listbox, Transition} from "@headlessui/react";
import {classNames} from "../utils/classNames";
import {OrganisationContext} from "../providers/OrganisationProvider";
import i18n from "../plugins/i18n";

interface ILanguageSwitcherProps {
  textColor?: string
}

interface Option {
  value: string | number;
  label: string;
}

const languageOptions = [
  {
    value: "nl",
    label: "Nederlands",
  },
  {
    value: "en",
    label: "English",
  },
  {
    value: "fr",
    label: "Français",
  },
];

const LanguageSwitcher: React.FC<ILanguageSwitcherProps> = ({textColor}) => {
  const languageContext = useContext(LanguageContext);
  const organisationContext = useContext(OrganisationContext);

  const [language, setLanguage] = useState<Option | null>(null);

  useEffect(() => {
    let defaultLanguage = languageOptions.find(option => option.value === i18n.language)
    if(defaultLanguage) {
      setLanguage(defaultLanguage)
    }
  }, [])

  useEffect(() => {
    if(organisationContext.organisationUserInfo != null) {
      let userLanguage = languageOptions.find(option => option.value === organisationContext.organisationUserInfo!.language.toLowerCase())
      if(userLanguage != null && language?.value !== userLanguage.value) {
        setLanguage(userLanguage)
      }
    }
  }, [organisationContext.organisationUserInfo])

  useEffect(() => {
    if(language !== null) {
      languageContext.changeLanguage(language.value.toString())
    }
  }, [language])

  return (
    <div>
      <Listbox value={language} disabled={false} onChange={setLanguage}>
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button
                className={`block py-3 px-0 w-full rounded-3px relative border-none appearance-none focus:outline-none focus:ring-purple-800 placeholder-gray-700 placeholder-opacity-40 truncate  min-h-12.25 ${
                  textColor ? textColor : "text-black"
                }`}
              >
                <span
                  className={`block text-left `}
                >
                  {language?.label}
                </span>

                <span className={`absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute z-10 w-full bg-white shadow-lg bottom-full rounded max-h-64 text-sm -mt-1 overflow-auto focus:outline-none sm:text-sm border border-purple-800 appearance-none focus:outline-none focus:ring-purple-800 focus:border-purple-800 placeholder-gray-700 placeholder-opacity-40"
                >
                  {languageOptions.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-purple-300" : "bg-white",
                          "cursor-default select-none relative  py-3 px-4"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.label}
                        </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

export default LanguageSwitcher;
