import React, {useContext, useEffect, useRef, useState} from "react";
import Input from "../../components/Form/Input";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Heading, { Level } from "../../components/Typography/Heading";
import AppHeader from "../../components/Layout/AppHeader";
import { middleware } from "../../plugins/axios";
import { OrganisationContext } from "../../providers/OrganisationProvider";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Translate from "../../components/Translate";
import i18n from "../../plugins/i18n";
import * as queryString from "querystring";

const CustomerSearch: React.FC = () => {
  const doSearch = useRef(false)
  const organisation = useContext(OrganisationContext).organisation;
  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emptyFilter, setEmptyFilter] = useState(true);

  const [contractNo, setContractNo] = useState("");
  const [customerNo, setCustomerNo] = useState("");
  const [claimNo, setClaimNo] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [serialNo, setSerialNo] = useState("");

  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    const parsedQuery = queryString.parse(location.search.replace("?", ""));
    handleSearchParams(parsedQuery)
  }, [location])

  useEffect(() => {
    if (phoneNo !== "" && doSearch.current) {
      setFilter()
      doSearch.current = false;
    }
  }, [phoneNo])

  const columns = [
    { label: i18n.t("search.customer_label"), sortable: true },
    { label: i18n.t("search.contract_label"), sortable: true },
    { label: i18n.t("search.contract_status"), sortable: false },
    { label: i18n.t("search.zip_label"), sortable: true },
    { label: i18n.t("search.name_label"), sortable: true },
    { label: i18n.t("search.actions_label"), sortable: false },
  ];
  const cells = [
    { label: "customerNo" },
    { label: "contractNo" },
    { label: "contractStatus" },
    { label: "postCode" },
    { label: "customerName" },
  ];

  const handleSearchParams = (params: queryString.ParsedUrlQuery) => {
    if (params["phoneNo"] && typeof params["phoneNo"] === "string") {
      if (params["phoneNo"].length <= 8) {
        setPhoneNo(params["phoneNo"])
      } else {
        setPhoneNo(params["phoneNo"].slice(-8))
      }
      doSearch.current = true;
    }
  }

  const getCustomersForOrganisation = async () => {
    if (organisation != null) {
      setIsLoading(true);
      middleware
        .post(`service/customers/${organisation.id}`, {
          contract_no: contractNo,
          customer_no: customerNo,
          claim_no: claimNo,
          zipcode: zipCode,
          house_no: houseNo,
          email: email,
          phone_no: phoneNo,
          serial_no: serialNo,
        })
        .then((res) => {
          setCustomerData(res.data ? res.data : []);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  const handleSubmit = (evt: { preventDefault: () => void }) => {
    evt.preventDefault();
    setFilter();
  };

  const setFilter = () => {
    if (
      contractNo === "" &&
      customerNo === "" &&
      claimNo === "" &&
      zipCode === "" &&
      houseNo === "" &&
      email === "" &&
      phoneNo === "" &&
      serialNo === ""
    ) {
      setEmptyFilter(true);
      setCustomerData([]);
    } else {
      setEmptyFilter(false);
      void getCustomersForOrganisation();
    }
  }

  return (
    <>
      <AppHeader>
        <div>
          <Heading tag="h1" level={Level.HEADING_2}>
            <Translate name={"search.heading"} />
          </Heading>
        </div>
      </AppHeader>

      <div className="p-8">
        <Card classList="mb-4" cols="grid-cols-1">
          <form className="flex-grow" onSubmit={handleSubmit}>
            <div className="grid grid-cols-5 gap-8">
              <Input
                label=""
                translationKey="search.contract_label"
                id="contractNumber"
                name="contractNumber"
                placeholder="CT0000001"
                value={contractNo}
                onChange={(e) => setContractNo(e.target.value)}
              />

              <Input
                label=""
                translationKey="search.customer_label"
                id="customerNumber"
                name="customerNumber"
                placeholder="KL0012323"
                value={customerNo}
                onChange={(e) => setCustomerNo(e.target.value)}
              />

              <Input
                label=""
                translationKey="search.claim_label"
                id="claimNumber"
                name="claimNumber"
                placeholder="SH001234"
                value={claimNo}
                onChange={(e) => setClaimNo(e.target.value)}
              />

              <Input
                label=""
                translationKey="search.zip_label"
                id="zipCode"
                name="zipCode"
                type="zipCode"
                placeholder="1111 AA"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />

              <Input
                label=""
                translationKey="search.house_label"
                id="houseNumber"
                name="houseNumber"
                placeholder="1"
                value={houseNo}
                onChange={(e) => setHouseNo(e.target.value)}
              />

              <Input
                label=""
                translationKey="search.email_label"
                id="email"
                name="email"
                placeholder="test@harmonyfs.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Input
                label=""
                translationKey="search.phone_label"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="010-12345678"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />

              <Input
                label=""
                translationKey="search.serialnumber_label"
                id="serialNumber"
                name="serialNumber"
                placeholder={i18n.t("search.serialnumber_hint")}
                value={serialNo}
                onChange={(e) => setSerialNo(e.target.value)}
              />

              <div className="flex items-end">
                <Button classList="py-3 border border-purple w-full">
                  <Translate name={"search.button_search"} />
                </Button>
              </div>
            </div>
          </form>
        </Card>

        {emptyFilter ? (
          <div className="text-purple text-center py-3">
            <Translate name={"search.result_message"} />
          </div>
        ) : (
          <Card cols="grid-cols-1">
            {isLoading ? (
              <div>
                <Translate name={"common.loading"} />
              </div>
            ) : (
              <Table data={customerData ? customerData : []}>
                <Table.Header>
                  {columns.map((column, i) => {
                    return (
                      <Table.HeaderColumn
                        key={i}
                        label={column.label}
                        sortable={column.sortable}
                      />
                    );
                  })}
                </Table.Header>
                <Table.Body>
                  {customerData && customerData.length > 0 ? (
                    customerData.map((row, index) => {
                      return (
                        <Table.Row
                          key={index}
                          index={index}
                          onClick={() => {
                            history.push(`/customer/${row["customerNo"]}`);
                          }}
                        >
                          {cells.map((cell, i) => {
                            let value: string = row[cell.label];
                            if (cell.label === "contractStatus") {
                              value = !!value
                                ? i18n.t(
                                    "search.contract_value." +
                                      value.toLowerCase()
                                  )
                                : "-";
                            }
                            return <Table.Cell key={i} label={value} />;
                          })}
                          <Table.Cell>
                            <NavLink to={`/customer/${row["customerNo"]}`}>
                              <Translate name={"common.view"} />
                            </NavLink>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })
                  ) : (
                    <Table.Row index={0}>
                      <Table.Cell>
                        <p>No results</p>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            )}
          </Card>
        )}
      </div>
    </>
  );
};

export default CustomerSearch;
