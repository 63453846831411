import React from "react";
import {ReactSVG} from "react-svg";
import {classNames} from "../utils/classNames";

interface IIconProps {
  name: string,
  classList?: string
}

const Icon: React.FC<IIconProps> = ({name, classList}, ...rest) => {
  return (
    <ReactSVG
      src={`/icons/${name.toLowerCase()}.svg`}
      className={classNames(
        'stroke-current fill-current',
        classList
      )}
    />
  )
}

export default Icon;
