import React from "react";

interface ILabelProps {
  id?: string;
}

const Label: React.FC<ILabelProps> = ({children, id}) => {
  return (
    <label
      className="block font-medium text-gray-800 leading-6 mb-2"
      htmlFor={id}
    >
      {children}
    </label>
  )
}

export default Label;
